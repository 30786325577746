import { getApiUrl } from "./request";

export { default as request } from "utils/request";

type Props = {
  action: string;
  service: TalentTickerServices;
  query?: string;
};

export const requestUrl = ({ service, action, query }: Props): string => {
  const url = `${getApiUrl()}/${service}${action ? `/${action}` : ""}${
    query ? `?${query}` : ""
  }`;

  return url;
};

export function mapFiltersToServerFormat(filters: Partial<SearchFilters>) {
  if (!filters) {
    return {};
  }

  return {
    contract_types: filters.contractTypes || [],
    company_sizes: filters.companySizes || [],
    event_types: filters.eventTypes || [],
    range: filters.range,
    trend: filters.trend,
    radius: filters.radius,
  };
}
