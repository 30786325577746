import { API_BASE_URL, isDev } from "env";
import { getAccessToken } from "lib/auth";
import * as Sentry from "@sentry/browser";

type RequestV2Config = {
  url: string;
  method: RequestInit["method"];
  body?: RequestInit["body"];
  type?: "FETCH" | "DELETE";
  withAuth?: boolean;
};

export type FetchData<T, K> = T & {
  errors?: K | Array<string>;
};

export type FetchResponse<T, K = Record<string, unknown>> = {
  status: number;
  data: FetchData<T, K>;
};

/**
 * function to retrieve the API url to use for communicating with the backend
 */
export const getApiUrl = () => {
  if (isDev && typeof window !== "undefined") {
    const url = window.localStorage.getItem("TT_API_URL") || API_BASE_URL;
    return url.startsWith("http") ? url : `https://${url}`;
  }
  return `https://${API_BASE_URL}`;
};

export const requestV2 = async <T, K = Record<string, unknown>>({
  url,
  body,
  method,
  type = "FETCH",
  withAuth = true,
}: RequestV2Config): Promise<FetchResponse<T, K>> => {
  // store the request config on the Sentry trace for in case an error occurs
  Sentry.setContext("requestV2.config", {
    url,
    method,
    body,
    type,
  });

  const accessToken = withAuth ? getAccessToken() : null;
  Sentry.setExtra("accessTokenRetrieved", Boolean(accessToken));
  const options = {
    body,
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  withAuth
    ? (options["headers"]["Authorization"] = `Bearer ${accessToken}`)
    : null;
  const response = await fetch(`${getApiUrl()}${url}`, options);
  Sentry.setContext("requestV2.response.metadata", {
    data: {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
    },
  });

  // This is to handle DELETE requests of 204.. no JSON is returned from server and this handles it
  if (type === "DELETE") {
    if (response.ok) {
      return { status: response.status, data: {} } as FetchResponse<T, K>;
    } else {
      // unexpected result not being "ok", but allow flow to continue
      Sentry.captureException(`DELETE request without response.ok`, {
        extra: {
          status: response.status,
        },
      });
    }
  }
  const data: FetchResponse<T, K> = await response.json();
  Sentry.setContext("requestV2.response.data", {
    data,
  });

  return data;
};
