import { ACTION } from "./constants";

export const initialState = {
  vincere: {
    loading: true,
    authorised: false,
    subdomain: null,
    companies: {
      loading: false,
      data: [],
    },
    showModal: false,
    currentEventId: null,
    currentCompanyId: null,
  },
  bullHorn: {
    loading: true,
    authorised: false,
    clientId: null,
    companies: {
      loading: false,
      data: [],
    },
    showModal: false,
    currentEventId: null,
    currentCompanyId: null,
  },
  errors: {
    bullHorn: null,
    vincere: null,
  },
};

export default function integrationsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.SHOW_BULLHORN_MODAL:
      return {
        ...state,
        bullHorn: {
          ...state.bullHorn,
          showModal: true,
          currentEventId: action.payload.eventId,
          currentCompanyId: action.payload.companyId,
        },
        errors: {
          ...state.errors,
          bullHorn: null,
        },
      };
    case ACTION.HIDE_BULLHORN_MODAL:
      return {
        ...state,
        bullHorn: {
          ...state.bullHorn,
          showModal: false,
        },
      };
    case ACTION.SHOW_VINCERE_MODAL:
      return {
        ...state,
        vincere: {
          ...state.vincere,
          showModal: true,
          currentEventId: action.payload.eventId,
          currentCompanyId: action.payload.companyId,
        },
        errors: {
          ...state.errors,
          vincere: null,
        },
      };
    case ACTION.HIDE_VINCERE_MODAL:
      return {
        ...state,
        vincere: {
          ...state.vincere,
          showModal: false,
        },
      };
    case ACTION.GET_VINCERE_COMPANIES_REQUEST:
      return {
        ...state,
        vincere: {
          ...state.vincere,
          companies: {
            data: [],
            loading: true,
          },
        },
      };
    case ACTION.GET_VINCERE_COMPANIES_SUCCESS:
      return {
        ...state,
        vincere: {
          ...state.vincere,
          companies: {
            data: action.payload || [],
            loading: false,
          },
        },
      };
    case ACTION.SET_BH_ACCOUNT_REQUEST:
      return {
        ...state,
        bullHorn: {
          ...initialState.bullHorn,
        },
        errors: {
          ...state.errors,
          bullHorn: null,
        },
      };
    case ACTION.AUTH_BH_ACCOUNT_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          bullHorn: null,
        },
      };
    case ACTION.SET_BH_ACCOUNT_SUCCESS:
      return {
        ...state,
        bullHorn: {
          ...state.bullHorn,
          loading: false,
          clientId: action.payload.clientId,
        },
      };
    case ACTION.SET_VINCERE_ACCOUNT_SUCCESS:
      return {
        ...state,
        vincere: {
          authorised: true,
          clientId: action.payload && action.payload.client_id,
          loading: false,
        },
      };
    case ACTION.AUTH_BH_ACCOUNT_SUCCESS:
      return {
        ...state,
        bullHorn: {
          ...state.bullHorn,
          authorised: true,
        },
      };
    case ACTION.AUTH_BH_ACCOUNT_FAILURE:
      return {
        ...state,
        bullHorn: {
          ...state.bullHorn,
          authorised: false,
        },
        errors: {
          bullHorn: action.errors,
        },
      };
    case ACTION.GET_VINCERE_COMPANIES_FAILURE:
      return {
        ...state,
        vincere: {
          ...state.vincere,
          companies: {
            loading: false,
            data: [],
          },
        },
        errors: {
          vincere: action.errors,
        },
      };
    default:
      return { ...state };
  }
}
