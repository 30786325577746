import {
  createTheme,
  Theme as MuiTheme,
  ThemeOptions,
} from "@mui/material/styles";
import { buttonClasses } from "@mui/material/Button";
import { filledInputClasses } from "@mui/material/FilledInput";
import type { Theme } from "theme";
import { stepConnectorClasses } from "@mui/material/StepConnector";

/**
 * Shared theme properties
 */
export const sharedTheme = {
  fontSize: {},
  fontWeight: {},
  lineHeights: {},
  breakpoints: {
    mobile: "only screen and (max-width: 600px)",
    tablet: "only screen and (max-width: 768px)",
    laptop: "only screen and (max-width: 1024px)",
  },
};

const sharedOverrides = (theme: Theme): ThemeOptions["components"] => ({
  MuiStepConnector: {
    styleOverrides: {
      alternativeLabel: {
        top: 10,
        left: "-50%",
        right: "50%",
      },
      active: {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.colors.primary,
        },
      },
      completed: {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.colors.primary,
        },
      },
      line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        whiteSpace: "nowrap",
      },
    },
  },
});

export const globalDark = (theme: Theme): MuiTheme =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: theme.colors.primary,
      },
      secondary: {
        main: "#ff7c00",
      },
      error: {
        main: "#ff7c00",
      },
      background: {
        default: "#c9d2da",
      },
    },
    components: {
      ...sharedOverrides(theme),
      MuiButton: {
        styleOverrides: {
          root: {
            letterSpacing: "1.4px",
            "&:hover": {
              color: "var(--white)",
            },
          },
          contained: {
            textTransform: "none",
            letterSpacing: "normal",
            fontSize: 18,
            borderRadius: "31px",
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: "rgba(201, 210, 218, 0.2)",
              color: "rgba(112, 125, 143, 0.61)",
              letterSpacing: 1.35,
            },
          },
          outlined: {
            padding: "5px 56px",
            margin: "0 0 0 20px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: "var(--text-primary)",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          filled: {
            color: "var(--text-primary)",
            transform: "translate(12px, 10px) scale(1)",
          },
        },
      },
    },
  });

export const globalLight = (theme: Theme): MuiTheme =>
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: theme.colors.primary,
      },
      secondary: { main: "#3e4150" },
      error: {
        main: "#ff7c00",
      },
      background: {
        default: "#c9d2da",
      },
    },
    components: {
      ...sharedOverrides(theme),
      MuiButton: {
        styleOverrides: {
          contained: {
            minWidth: 144,
            minHeight: 35,
            boxShadow: "none",
            borderRadius: "500px",
            textTransform: "unset",
            fontSize: 18,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: "rgba(62, 65, 80, 0.2)",
              color: "rgba(112, 125, 143, 0.61)",
              fontWeight: 400,
            },
            "&:hover": {
              color: "#fff",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(201, 210, 218, 0.2)",
            "&:hover": {
              backgroundColor: "rgba(201, 210, 218, 0.33)",
            },
            [`&.${filledInputClasses.focused}`]: {
              backgroundColor: "rgba(201, 210, 218, 0.33)",
            },
          },
        },
      },
    },
  });

export const cards = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ff4900",
    },
    secondary: {
      main: "#ff7c00",
    },
    error: {
      main: "#ff7c00",
    },
    background: {
      default: "#c9d2da",
    },
    text: {
      primary: "#354658",
      secondary: "#707d8f",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          fontSize: "14px",
          color: "#0084ff",
          textTransform: "none",
        },
        outlined: {
          padding: "5px 56px",
          margin: "0 0 0 20px",
        },
        contained: {
          boxShadow: "none",
          textTransform: "none",
          minHeight: "55px",
          borderRadius: "31px",
          fontSize: "18px",
          whiteSpace: "nowrap",
          padding: "0 40px",
        },
        containedSecondary: {
          color: "var(--white)",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: "none",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 16,
          "@media (min-width: 600px)": {
            padding: 16,
          },
        },
        title: {
          fontSize: "20px",
          lineHeight: "1.5",
        },
        subheader: {
          fontSize: "14px",
          lineHeight: "1.5",
        },
        action: {
          alignSelf: "center",
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        media: {
          objectFit: "cover",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          minHeight: "72px",
          padding: "11px 16px",
          "@media (min-width: 600px)": {
            padding: "11px 16px",
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          height: "100%",
        },
        focusHighlight: {},
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: "#0084ff",
        },
      },
    },
  },
});
