// To determine if user's input macthes boolean search rules
export const BOOLEAN_SEARCH =
  /(\s?(\+|-){1}(\s?)("[\w|\s]+"|\w+)|([\w|\s])|("\\{1}("[\w|\s]+"|\w+)|[\w|\s])"\\{1})+/g;

export const BOOLEAN_KEYWORDS = /(AND|OR|NOT)/g;

export const BOOLEAN_SYMBOL = /[+-]/g;

export const BOOLEAN_OPERATOR_REGEX = /^\s?(\+|-|"|AND\s|OR\s|NOT\s)?/;

/**
 *  Match letters, numbers and space only
 */
export const LETTERS_AND_NUMBERS = /[A-Za-z0-9\s*]+/g;
