/**
 * Uses the api response for get /saved
 * */
export function savedSearchApiToSavedSearchMapper(
  savedSearchApi: SavedSearchApi,
  snake_case?: boolean
): SavedSearch {
  return {
    chips: savedSearchApi[snake_case ? "chip_set" : "chips"],
    chipsExclusion:
      savedSearchApi[snake_case ? "chip_set_exclusion" : "chips_exclusion"],
    name: savedSearchApi.name,
    notifications: savedSearchApi.notifications,
    notificationEventTypes: savedSearchApi.notification_event_types,
    savedSearchUuid: savedSearchApi.saved_search_uuid,
    range: savedSearchApi.range,
    radius: savedSearchApi.radius,
    companySizes: savedSearchApi.company_sizes
      ? savedSearchApi.company_sizes.map(
          (company_size) =>
            `${company_size[0]}-${company_size[1]}` as CompanySizeFilter
        )
      : [],
    contractTypes: savedSearchApi.contract_types,
    trend: savedSearchApi.trend,
    eventTypes: savedSearchApi.event_types,
  };
}

/**
 * Uses snake_cased get-notifications api result
 * */
export function savedSearchSavedApiMapper(
  savedSearchApiResponse: any,
  key: string
): SavedSearch {
  return {
    chips: savedSearchApiResponse.chip_set,
    chipsExclusion: savedSearchApiResponse.chip_set_exclusion,
    name: savedSearchApiResponse.name,
    notifications: savedSearchApiResponse.notifications,
    savedSearchUuid: key,
    notificationEventTypes: savedSearchApiResponse.notification_event_types,
    range: savedSearchApiResponse.filters.range
      ? savedSearchApiResponse.filters.range
      : undefined,
    companySizes: savedSearchApiResponse.filters.company_sizes
      ? savedSearchApiResponse.filters.company_sizes.map(
          (company_size: any) =>
            `${company_size[0]}-${company_size[1]}` as CompanySizeFilter
        )
      : [],
    contractTypes: savedSearchApiResponse.filters.contract_types,
    trend: savedSearchApiResponse.filters.trend,
    radius: savedSearchApiResponse.radius,
    eventTypes: savedSearchApiResponse.filters.event_types,
  };
}
