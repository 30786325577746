import { buildCompanyChip } from "components/Company/fetchers";
import { REQUEST_METHOD } from "../constants";
import { requestUrl } from "utils/api-request";
import { ContactUsValues } from "components/Contentful/ContactUsForm";
import { RevokeInvitationPayload } from "./types";
import { Organisation } from "organisation-context";

type RequestConfig = {
  url: string;
  options: {
    method: "GET" | "POST" | "PATCH";
    body?: string;
    signal?: AbortSignal;
  };
  auth?: boolean;
  contentType?: boolean;
};

export const requestConfig = {
  getCompanyDetails: (companyId: string): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-company",
        action: `${companyId}`,
      }),
      options: {
        method: "GET",
      },
      auth: true,
    };
  },
  getCompanyHiringTrends: (companyId: string): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-company",
        action: `${companyId}/hiring-trend`,
      }),
      options: {
        method: "POST",
        body: JSON.stringify({
          interval: "1w",
        }),
      },
      auth: true,
    };
  },
  getCompanySpotlight: (
    companyId: number,
    companyDisplayName: string
  ): RequestConfig => {
    const companyChip = buildCompanyChip(companyId, companyDisplayName);
    const body = {
      desc: "timestamp_published",
      limit: 5,
      skip: 0,
      tab: "news",
      save_history: false,
      input: companyChip ? [companyChip] : [],
    };
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "search",
      }),
      options: {
        method: "POST",
        body: JSON.stringify(body),
      },
      auth: true,
    };
  },

  modifySavedSearch: (payload: {
    id: string;
    notificationType: SavedSearchNotificationOption;
    name: string;
    action?: "on" | "off" | "del";
  }): RequestConfig => {
    const body = {
      rec_id: payload.id,
      action: payload.action,
      notification_event_types: payload.notificationType,
      name: payload.name,
    };
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "save",
      }),
      options: {
        body: JSON.stringify(body),
        method: "PATCH",
      },
      auth: true,
    };
  },
  getTickerData: (): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-events",
        action: "ticker",
      }),
      options: {
        method: "GET",
      },
      auth: true,
      contentType: false,
    };
  },
  login: (payload: unknown): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-user",
        action: "signin",
      }),
      options: {
        method: "POST",
        body: JSON.stringify(payload),
      },
      contentType: false,
    };
  },
  signup: (payload: unknown): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-user",
        action: "signup",
      }),
      options: {
        method: "POST",
        body: JSON.stringify(payload),
      },
      contentType: false,
    };
  },
  convertArticleIdToEventId: (articleId: string): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-events",
        action: `convert/${articleId}`,
      }),
      options: {
        method: "GET",
      },
      contentType: false,
    };
  },
  getEvent: (eventId: string): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-events",
        action: eventId.toString(),
      }),
      options: {
        method: "GET",
      },
      auth: true,
      contentType: false,
    };
  },
  getRelatedEvents: (eventId: number): RequestConfig => {
    return {
      url: requestUrl({
        service: "v2-personalisation",
        action: `events/${eventId}/related`,
      }),
      options: {
        method: "POST",
        signal: AbortController["signal"],
      },
      auth: true,
      contentType: false,
    };
  },
  subscribeToPushNotifications: ({ userId, endpoint, p256dh, auth }) => ({
    url: requestUrl({
      service: "v2-user",
      action: `${userId.replace("|", "%7C")}/push-notifications/subscribe`,
    }),
    options: {
      method: REQUEST_METHOD.POST,
      body: JSON.stringify({
        endpoint,
        keys: { p256dh, auth },
      }),
    },
    auth: true,
    contentType: false,
  }),
  unsubscribeFromPushNotifications: ({ userId, endpoint }) => ({
    url: requestUrl({
      service: "v2-user",
      action: `${userId.replace("|", "%7C")}/push-notifications/unsubscribe`,
    }),
    options: {
      method: REQUEST_METHOD.POST,
      body: JSON.stringify({
        endpoint,
      }),
    },
    auth: true,
    contentType: false,
  }),
  updateMetadata: (metadata) => ({
    url: requestUrl({
      service: "v2-user",
      action: "metadata",
    }),
    options: {
      method: REQUEST_METHOD.PUT,
      body: JSON.stringify({ metadata }),
    },
    auth: true,
  }),
  uploadProfilePicture: (data) => ({
    url: requestUrl({
      service: "v2-user",
      action: "image",
    }),
    options: {
      method: REQUEST_METHOD.PUT,
      body: JSON.stringify({ data }),
    },
    auth: true,
  }),
  setPreferences: (userId, prefs) => ({
    url: requestUrl({
      service: "v2-user",
      action: `${encodeURIComponent(userId)}/preferences`,
    }),
    options: {
      method: REQUEST_METHOD.PATCH,
      body: JSON.stringify(prefs),
    },
    auth: true,
  }),
  getPreferences: (userId) => ({
    url: requestUrl({
      service: "v2-user",
      action: `${encodeURIComponent(userId)}/preferences`,
    }),
    options: {
      method: REQUEST_METHOD.GET,
    },
    auth: true,
  }),
  verifyEmail: (userId, token) => ({
    url: requestUrl({
      service: "v2-user",
      action: `${encodeURIComponent(userId)}/confirm-email`,
    }),
    options: {
      method: REQUEST_METHOD.POST,
      body: JSON.stringify({ token }),
    },
    auth: true,
  }),
  uploadSignaturePicture: (data) => ({
    url: requestUrl({
      service: "v2-user",
      action: "signature",
    }),
    options: {
      method: REQUEST_METHOD.PUT,
      body: JSON.stringify({ data }),
    },
    auth: true,
  }),
  createPortalSession: ({ userId }) => {
    return {
      url: requestUrl({
        service: "v2-chargebee",
        action: `${userId}/portal_session`,
      }),
      options: {
        method: REQUEST_METHOD.GET,
      },
      auth: true,
    };
  },
  getUserPipeline: (userId, version: 3 | 4) => ({
    url: requestUrl({
      service: `v${version}-pipeline`,
      action: userId && `user/${encodeURIComponent(userId)}`,
    }),
    options: {
      method: REQUEST_METHOD.GET,
    },
    auth: true,
  }),
  addTaskToPipeline: (payload, version: 3 | 4) => {
    const body = JSON.stringify({
      event_id: payload.eventId || undefined,
      deal_value: payload.dealValue || undefined,
      record_text: payload.recordText || undefined,
      record_title: payload.recordTitle || undefined,
      assigned_user_id: payload.assignedUserId || undefined,
      contacts: payload.contacts || undefined,
    });
    return {
      url: requestUrl({
        service: `v${version}-pipeline`,
        action: payload.userId
          ? `user/${encodeURIComponent(payload.userId)}/record`
          : "record",
      }),
      options: {
        method: REQUEST_METHOD.PUT,
        body,
      },
      auth: true,
    };
  },
  addContactsToRecord: (payload, version: 3 | 4) => {
    const body = JSON.stringify({
      contacts: payload.contacts,
    });
    return {
      url: requestUrl({
        service: `v${version}-pipeline`,
        action: `record/${encodeURIComponent(payload.recordId)}/contact`,
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body,
      },
      auth: true,
    };
  },
  addNoteToRecord: (payload, version: 3 | 4) => ({
    url: requestUrl({
      service: `v${version}-pipeline`,
      action: `record/${encodeURIComponent(payload.recordId)}/note`,
    }),
    options: {
      method: REQUEST_METHOD.PUT,
      body: JSON.stringify({
        text: payload.text,
      }),
    },
    auth: true,
  }),
  updatePipelineTask: (payload, version: 3 | 4) => {
    const body: any = {
      index: payload.index,
      column: payload.column,
    };

    if (payload.dealValue) body.deal_value = payload.dealValue;
    if (payload.recordText) body.record_text = payload.recordText;
    if (payload.recordTitle) body.record_title = payload.recordTitle;
    if (payload.assignedUserId) body.assigned_user_id = payload.assignedUserId;

    return {
      url: requestUrl({
        service: `v${version}-pipeline`,
        action: `record/${encodeURIComponent(payload.recordId)}`,
      }),
      options: {
        method: REQUEST_METHOD.PATCH,
        body: JSON.stringify(body),
      },
      auth: true,
    };
  },
  deletePipelineTask: (payload, version: 3 | 4) => ({
    url: requestUrl({
      service: `v${version}-pipeline`,
      action: `record/${encodeURIComponent(payload.recordId)}`,
    }),
    options: {
      method: REQUEST_METHOD.DELETE,
    },
    auth: true,
  }),
  getPipelineRecord: (recordId, version: 3 | 4) => ({
    url: requestUrl({
      service: `v${version}-pipeline`,
      action: `record/${encodeURIComponent(recordId)}`,
    }),
    options: {
      method: REQUEST_METHOD.GET,
    },
    auth: true,
  }),
  /**
   * @deprecated -- all pipeline contact data should be provided in the initial request
   */
  getPipelineCsv: (version: 3 | 4) => ({
    url: requestUrl({
      service: `v${version}-pipeline`,
      action: `pipeline_export`,
    }),
    options: {
      method: REQUEST_METHOD.GET,
    },
    auth: true,
  }),
  recordSession: () => {
    return {
      url: requestUrl({
        service: "v2-analytics",
        action: "session",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({}),
      },
      auth: true,
    };
  },
  getNotifications: () => {
    return {
      url: requestUrl({
        service: "v2-user",
        action: "get-notifications",
      }),
      options: {
        method: REQUEST_METHOD.GET,
      },
      auth: true,
    };
  },
  clearNotifications: (notificationIds) => {
    return {
      url: requestUrl({
        service: "v2-user",
        action: "clear-notifications",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          saved_search_ids: notificationIds,
        }),
      },
      auth: true,
    };
  },
  deleteAccount: () => {
    return {
      url: requestUrl({
        service: "v2-user",
        action: "delete",
      }),
      options: {
        method: REQUEST_METHOD.DELETE,
      },
      auth: true,
    };
  },
  setBhAcctDetails: (payload) => {
    return {
      url: requestUrl({
        service: "v2-bullhorn",
        action: "account",
      }),
      options: {
        method: REQUEST_METHOD.PUT,
        body: JSON.stringify({
          client_id: payload.clientId,
          client_secret: payload.secret,
        }),
      },
      auth: true,
    };
  },
  setVincereAccountDetails: (payload) => {
    return {
      url: requestUrl({
        service: "v1-vincere",
        action: "account",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          subdomain: payload.subdomain,
        }),
      },
      auth: true,
    };
  },
  getVincereCompanyList: (eventId) => {
    return {
      url: requestUrl({
        service: "v1-vincere",
        action: `company_matches?event_id=${eventId}`,
      }),
      options: {
        method: REQUEST_METHOD.GET,
      },
      auth: true,
    };
  },
  authoriseBhAcct: (payload) => {
    return {
      url: requestUrl({
        service: "v2-bullhorn",
        action: "authorise",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          code: payload.code,
          redirect_uri: payload.redirect,
        }),
      },
      auth: true,
    };
  },
  authoriseVincereAcct: (payload) => {
    return {
      url: requestUrl({
        service: "v1-vincere",
        action: "authorise",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          code: payload.code,
          redirect_uri: payload.redirect,
        }),
      },
      auth: true,
    };
  },
  addBhEvent: (payload) => {
    return {
      url: requestUrl({
        service: "v2-bullhorn",
        action: "lead",
      }),
      options: {
        method: REQUEST_METHOD.PUT,
        body: JSON.stringify(payload),
      },
      auth: true,
    };
  },
  addVincereEvent: (payload) => {
    return {
      url: requestUrl({
        service: "v1-vincere",
        action: "job",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify(payload),
      },
      auth: true,
    };
  },
  addVincereContact: (payload) => {
    return {
      url: requestUrl({
        service: "v1-vincere",
        action: "contact",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify(payload),
      },
      auth: true,
    };
  },
  getFreeTrial: (payload) => {
    return {
      url: requestUrl({
        service: "v2-seats",
        action: "apply_trial_token",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify(payload),
      },
      auth: true,
    };
  },
  fetchTeams: (organisation: Organisation) => {
    return {
      url: requestUrl({
        service: "v1-teams",
        action: `team?org_id=${organisation.id}`,
      }),
      options: {
        method: REQUEST_METHOD.GET,
      },
      auth: true,
    };
  },
  getOrgMembers: () => {
    return {
      url: requestUrl({
        service: "v1-teams",
        action: "assignable_users",
      }),
      options: {
        method: REQUEST_METHOD.GET,
      },
      auth: true,
    };
  },
  fetchTeamById: (id) => {
    return {
      url: requestUrl({
        service: "v1-teams",
        action: `team/${encodeURIComponent(id)}`,
      }),
      options: {
        method: REQUEST_METHOD.GET,
      },
      auth: true,
    };
  },
  addTeam: (payload) => {
    return {
      url: requestUrl({
        service: "v1-teams",
        action: "team",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          team_name: payload.teamName,
          org_id: payload.organisation?.id,
        }),
      },
      auth: true,
    };
  },
  deleteTeam: (id) => {
    return {
      url: requestUrl({
        service: "v1-teams",
        action: `team/${encodeURIComponent(id)}`,
      }),
      options: {
        method: REQUEST_METHOD.DELETE,
      },
      auth: true,
    };
  },
  assignUserToTeam: (payload) => {
    const method = payload.isLead !== undefined ? "PATCH" : "PUT";
    return {
      url: requestUrl({
        service: "v1-teams",
        action: `team/${encodeURIComponent(
          payload.teamId
        )}/user/${encodeURIComponent(payload.userId)}`,
      }),
      options: {
        method: REQUEST_METHOD[method],
        body: JSON.stringify({
          is_admin: payload.isLead || false,
        }),
      },
      auth: true,
    };
  },
  removeUserFromTeam: (payload) => {
    return {
      url: requestUrl({
        service: "v1-teams",
        action: `team/${encodeURIComponent(
          payload.teamId
        )}/user/${encodeURIComponent(payload.userId)}`,
      }),
      options: {
        method: REQUEST_METHOD.DELETE,
      },
      auth: true,
    };
  },
  revokeInvitation: (payload: RevokeInvitationPayload) => {
    return {
      url: requestUrl({
        service: "v2-seats",
        action: `revoke_invitation/${encodeURIComponent(payload.seatId)}`,
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({ org_id: payload.organisation.id }),
      },
      auth: true,
    };
  },
  updateJobSeekerDetails: (payload) => ({
    url: requestUrl({
      service: "v2-user",
      action: "jobseeker_details",
    }),
    options: {
      method: REQUEST_METHOD.PATCH,
      body: JSON.stringify(payload),
    },
    auth: true,
  }),
  getJobSeekerDetails: () => ({
    url: requestUrl({
      service: "v2-user",
      action: "jobseeker_details",
    }),
    options: {
      method: REQUEST_METHOD.GET,
    },
    auth: true,
  }),
  getManagers: ({ lead, skip, limit, managersToExclude }) => {
    return {
      url: requestUrl({
        service: "v1-people",
        action: "match",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          company_id: lead.companyId,
          location_id: lead.locationId,
          role_ids: lead.roleIds,
          manager_ids_to_exclude: managersToExclude,
          limit,
          skip,
        }),
      },
      auth: true,
    };
  },
  unmatchedPeopleInCompany: ({ lead, skip, limit, managersToExclude }) => {
    return {
      url: requestUrl({
        service: "v1-people",
        action: "unmatched-people-at-company",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          company_id: lead.companyId,
          location_id: lead.locationId,
          role_ids: lead.roleIds,
          manager_ids_to_exclude: managersToExclude,
          limit,
          skip,
        }),
      },
      auth: true,
    };
  },
  notifyMissingContacts: ({ eventUrl, message, eventId, companyId }) => {
    return {
      url: requestUrl({
        service: "v1-people",
        action: "notify-missing",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify({
          event_url: eventUrl,
          message,
          event_id: eventId,
          companyId: companyId,
        }),
      },
      auth: true,
    };
  },
  getOrganisations: () => {
    // /v1-organisations/organisations
    return {
      url: requestUrl({
        service: "v1-organisations",
        action: "organisations",
      }),
      options: {
        method: REQUEST_METHOD.GET,
      },
      auth: true,
    };
  },
  deleteContactsFromRecord: (
    pipelineRecordUuid: string,
    recipients: Partial<Person>[]
  ) => {
    const contacts = recipients.map((recipient) => recipient.id).join();
    return {
      url: requestUrl({
        service: "v3-pipeline",
        action: `record/${pipelineRecordUuid}/contact`,
      }),
      options: {
        method: REQUEST_METHOD.DELETE,
        body: JSON.stringify({ contacts }),
      },
      auth: true,
    };
  },
  contactUsSubmission: (body: ContactUsValues) => {
    return {
      url: requestUrl({
        service: "v2-user",
        action: "contact-us",
      }),
      options: {
        method: REQUEST_METHOD.POST,
        body: JSON.stringify(body),
      },
      auth: false,
    };
  },
};
