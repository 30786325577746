import { ACTION } from "./constants";
export const initialState = {
  loading: null,
  teams: [],
  currentTeam: {},
  orgMembers: [],
  error: null,
};

export default function teamsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.FETCH_TEAMS:
    case ACTION.FETCH_ORG_MEMBERS:
    case ACTION.FETCH_TEAM:
    case ACTION.ADD_TEAM:
    case ACTION.ADD_MEMBER:
      return { ...state, error: null, loading: true };

    case ACTION.FETCH_TEAMS_SUCCESS:
      return { ...state, loading: false, teams: action.payload };

    case ACTION.FETCH_ORG_MEMBERS_SUCCESS:
      return { ...state, loading: false, orgMembers: action.payload };

    case ACTION.FETCH_TEAM_SUCCESS:
      return { ...state, loading: false, currentTeam: action.payload };

    case ACTION.ADD_TEAM_SUCCESS:
    case ACTION.ADD_MEMBER_SUCCESS:
      return { ...state, loading: false };

    case ACTION.FETCH_TEAMS_FAILED:
    case ACTION.FETCH_TEAM_FAILED:
    case ACTION.ADD_MEMBER_FAILED:
    case ACTION.ADD_TEAM_FAILED:
    case ACTION.FETCH_ORG_MEMBERS_FAILED:
      return { ...initialState, loading: false, error: action.err };

    default:
      return state;
  }
}
