import { ofType } from "redux-observable";
import { ACTION } from "./constants";
import { getState } from "store/index";
import { ajax } from "rxjs/ajax";
import { map, mergeMap, catchError } from "rxjs/operators";
import { requestUrl } from "utils/api-request";
import { getAccessToken } from "lib/auth";
import { of, forkJoin } from "rxjs";

import {
  fetchStatsSuccess,
  fetchStatsFailed,
  exportCsvSuccess,
  exportCsvFailed,
} from "./actions";

const handleFetchStatsSuccess = (json) => fetchStatsSuccess(json);
const handleFetchStatsError = (error) => of(fetchStatsFailed(error));
const handleExportCsvSuccess = (json) => exportCsvSuccess(json.data);
const handleExportCsvFailed = (error) => of(exportCsvFailed(error));

const mapUsersOntoStats = (assignedUsers, histogram) => {
  let startOfYesterday = new Date();
  startOfYesterday.setDate(new Date().getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);
  startOfYesterday = startOfYesterday.getTime();
  const output = assignedUsers.data.assigned_users.map((assignedUser) => {
    const userSessionHistogram = histogram.data.activites_histogram.filter(
      (item) =>
        item.user_id === assignedUser.user_id &&
        item.activity_type === "SESSION"
    );
    const sessionStats = userSessionHistogram.reduce(
      (prev, curr) => {
        const thing = curr.histogram.reduce(
          (hprev, hcurr) => {
            const sessionDate = new Date(hcurr.key).getTime();
            if (startOfYesterday - sessionDate < 86400000) {
              hprev.yesterdaySessions += hcurr.count;
            }
            if (startOfYesterday - sessionDate < 604800000) {
              hprev.lastWeekSessions += hcurr.count;
            }
            if (startOfYesterday - sessionDate < 2592000000) {
              hprev.lastMonthSessions += hcurr.count;
            }
            if (startOfYesterday - sessionDate < 10368000000) {
              hprev.lastQuarterSessions += hcurr.count;
            }
            return hprev;
          },
          {
            yesterdaySessions: 0,
            lastWeekSessions: 0,
            lastMonthSessions: 0,
            lastQuarterSessions: 0,
          }
        );
        prev.yesterdaySessions += thing.yesterdaySessions;
        prev.lastWeekSessions += thing.lastWeekSessions;
        prev.lastMonthSessions += thing.lastMonthSessions;
        prev.lastQuarterSessions += thing.lastQuarterSessions;
        return prev;
      },
      {
        yesterdaySessions: 0,
        lastWeekSessions: 0,
        lastMonthSessions: 0,
        lastQuarterSessions: 0,
      }
    );
    const userSearchesHistogram = histogram.data.activites_histogram.filter(
      (item) =>
        item.user_id === assignedUser.user_id && item.activity_type === "SEARCH"
    );
    const searchesStats = userSearchesHistogram.reduce(
      (prev, curr) => {
        const thing = curr.histogram.reduce(
          (hprev, hcurr) => {
            const searchDate = new Date(hcurr.key).getTime();
            if (startOfYesterday - searchDate < 86400000) {
              hprev.yesterdaySearches += hcurr.count;
            }
            if (startOfYesterday - searchDate < 604800000) {
              hprev.lastWeekSearches += hcurr.count;
            }
            if (startOfYesterday - searchDate < 2592000000) {
              hprev.lastMonthSearches += hcurr.count;
            }
            if (startOfYesterday - searchDate < 10368000000) {
              hprev.lastQuarterSearches += hcurr.count;
            }
            return hprev;
          },
          {
            yesterdaySearches: 0,
            lastWeekSearches: 0,
            lastMonthSearches: 0,
            lastQuarterSearches: 0,
          }
        );
        prev.yesterdaySearches += thing.yesterdaySearches;
        prev.lastWeekSearches += thing.lastWeekSearches;
        prev.lastMonthSearches += thing.lastMonthSearches;
        prev.lastQuarterSearches += thing.lastQuarterSearches;
        return prev;
      },
      {
        yesterdaySearches: 0,
        lastWeekSearches: 0,
        lastMonthSearches: 0,
        lastQuarterSearches: 0,
      }
    );
    return {
      teamMemberName: assignedUser.name,
      teamMemberEmail: assignedUser.email,
      ...sessionStats,
      ...searchesStats,
    };
  });
  return output;
};

function getStatsObservable(action) {
  const token = getAccessToken();
  const getAssignedUsersAjax = ajax.getJSON(
    requestUrl({
      service: "v2-seats",
      action: `${action.userId}/assigned_users`,
      query: action.organisation ? `org_id=${action.organisation.id}` : ``,
    }),
    { Authorization: `Bearer ${token}` }
  );
  const getHistogramAjax = ajax.getJSON(
    requestUrl({
      service: "v2-analytics",
      action: `${action.userId}/team_activity/histogram`,
      query: action.organisation ? `org_id=${action.organisation.id}` : ``,
    }),
    { Authorization: `Bearer ${token}` }
  );
  return forkJoin(
    getAssignedUsersAjax,
    getHistogramAjax,
    mapUsersOntoStats
  ).pipe(map(handleFetchStatsSuccess), catchError(handleFetchStatsError));
}

function exportCsvObservable(action) {
  const token = getAccessToken();
  const getAssignedUsersAjax = ajax.getJSON(
    requestUrl({
      service: "v2-analytics",
      action: `${action.userId}/team_activity`,
      guest: false,
    }),
    { Authorization: `Bearer ${token}` }
  );
  return getAssignedUsersAjax.pipe(
    map(handleExportCsvSuccess),
    catchError(handleExportCsvFailed)
  );
}

export const getStatsEpic = (action$) =>
  action$.pipe(ofType(ACTION.FETCH_STATS), mergeMap(getStatsObservable));

export const exportCsvEpic = (action$) =>
  action$.pipe(ofType(ACTION.EXPORT_CSV), mergeMap(exportCsvObservable));
