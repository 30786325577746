import { findKey } from "components/Sourcing/shared/utils";
import { EmailScores, PhoneNumberScores, PhoneNumberType } from "./types";

export const phoneNumberTypeOptions: PhoneNumberType[] = [
  "DIRECT_DIAL",
  "MOBILE",
  "COMPANY_SWITCHBOARD",
];

export const qualityMarks = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 30,
    label: "Medium",
  },
  {
    value: 60,
    label: "High",
  },
  {
    value: 100,
    label: "Very High",
  },
];

export const phoneQualityMarks = [
  {
    value: 0,
    label: "Medium",
  },
  {
    value: 50,
    label: "High",
  },
  {
    value: 100,
    label: "Very High",
  },
];

/**
 * 0 => 20 (Medium),
 * 50 => 60 (Hight),
 * 100 => 69 (Very high)
 */
export const phoneQualityMap = {
  0: PhoneNumberScores.Medium,
  50: PhoneNumberScores.High,
  100: PhoneNumberScores.VeryHigh,
} as const;

/**
 * 30 => 60 (Medium),
 * 60 => 80 (Hight),
 * 100 => 86 (Very high)
 */
export const emailQualityMap = {
  0: 0,
  30: EmailScores.Medium,
  60: EmailScores.High,
  100: EmailScores.VeryHigh,
};

/**
 * Phone number type mapping
 */
export const phoneTypeMap = {
  DIRECT_DIAL: "Direct",
  MOBILE: "Mobile",
  COMPANY_SWITCHBOARD: "Switchboard",
};

/**
 * Get stored value
 * @param mapObj
 * @param value
 * @returns
 */
export const getStoredQuality = (mapObj, value): number => {
  if (value) {
    const score = findKey(mapObj, value);
    return Number(score);
  }
  return 0;
};
