/**
 * This is used to expose the user from Auth0 and set redux accordingly..
 * This is meant to be refactored out over time
 */

import { useAuth0 } from "@auth0/auth0-react";
import { usePermissions } from "lib/features";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "store/auth/actions";
import { UserContext } from "./context";

export const UserProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { user } = useAuth0();
  const { permissions, permissionsObj } = usePermissions();
  const dispatch = useDispatch();
  const handleUserData = useCallback(async () => {
    dispatch(
      updateUser({
        ...user,
      })
    );
  }, [dispatch, user]);

  useEffect(() => {
    handleUserData();
  }, [handleUserData]);
  return (
    <UserContext.Provider value={{ permissions, permissionsObj }}>
      {children}
    </UserContext.Provider>
  );
};
