import { useTheme, Theme } from "theme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { globalLight } from "./shared";
import { useMemo } from "react";

type ThemeProviderProps = {
  children: JSX.Element;
  /**
   * A theme to forceably override the default one
   */
  theme?: Theme;
};

/**
 * Feature flag aware theme provider
 *
 * @requires EnvProvider & FeatureProvider to be higher in the component tree
 */
export const ThemeProvider = ({
  children,
  theme: overrideTheme,
}: ThemeProviderProps): JSX.Element => {
  const theme = useTheme();
  const globalTheme = useMemo(() => {
    if (overrideTheme) {
      return overrideTheme;
    }
    const light = globalLight(theme);
    return {
      ...light,
      ...theme,
      breakpoints: { ...light.breakpoints, ...theme.breakpoints },
    };
  }, [theme, overrideTheme]);

  return <MuiThemeProvider theme={globalTheme}>{children}</MuiThemeProvider>;
};
