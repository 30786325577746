export const ACTION = {
  FETCH_TEAM: "FETCH_TEAM",
  FETCH_TEAM_SUCCESS: "FETCH_TEAM_SUCCESS",
  FETCH_TEAM_FAILED: "FETCH_TEAM_FAILED",
  FETCH_ORG_MEMBERS: "FETCH_ORG_MEMBERS",
  FETCH_ORG_MEMBERS_SUCCESS: "FETCH_ORG_MEMBERS_SUCCESS",
  FETCH_ORG_MEMBERS_FAILED: "FETCH_ORG_MEMBERS_FAILED",
  FETCH_TEAMS: "FETCH_TEAMS",
  FETCH_TEAMS_SUCCESS: "FETCH_TEAMS_SUCCESS",
  FETCH_TEAMS_FAILED: "FETCH_TEAMS_FAILED",
  ADD_TEAM: "ADD_TEAM",
  ADD_TEAM_SUCCESS: "ADD_TEAM_SUCCESS",
  ADD_TEAM_FAILED: "ADD_TEAM_FAILED",
  DELETE_TEAM: "DELETE_TEAM",
  DELETE_TEAM_SUCCESS: "DELETE_TEAM_SUCCESS",
  DELETE_TEAM_FAILED: "DELETE_TEAM_FAILED",
  ADD_MEMBER: "ADD_MEMBER",
  ADD_MEMBER_SUCCESS: "ADD_MEMBER_SUCCESS",
  ADD_MEMBER_FAILED: "ADD_MEMBER_FAILED",
  REMOVE_MEMBER: "REMOVE_MEMBER",
  REMOVE_MEMBER_SUCCESS: "REMOVE_MEMBER_SUCCESS",
  REMOVE_MEMBER_FAILED: "REMOVE_MEMBER_FAILED",
  UPDATE_MEMBER: "UPDATE_MEMBER",
  UPDATE_MEMBER_SUCCESS: "UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAILED: "UPDATE_MEMBER_FAILED",
};
