export const ACTION = {
  FETCH_SEATS: "FETCH_SEATS",
  FETCH_SEATS_SUCCESS: "FETCH_SEATS_SUCCESS",
  FETCH_SEATS_FAILED: "FETCH_SEATS_FAILED",
  FETCH_PAYMENT_SOURCE: "FETCH_PAYMENT_SOURCE",
  FETCH_PAYMENT_SOURCE_SUCCESS: "FETCH_PAYMENT_SOURCE_SUCCESS",
  FETCH_PAYMENT_SOURCE_FAILED: "FETCH_PAYMENT_SOURCE_FAILED",
  FINISH_UPGRADING: "FINISH_UPGRADING",
  VACATE_SEAT: "VACATE_SEAT",
  VACATE_SEAT_CONFIRMED: "VACATE_SEAT_CONFIRMED",
  CANCEL_VACATE_SEAT: "CANCEL_VACATE_SEAT",
  DELETE_USER_FROM_SEAT: "DELETE_USER_FROM_SEAT",
  DELETE_USER_FROM_SEAT_FAILED: "DELETE_USER_FROM_SEAT_FAILED",
  SET_ORG_NAME: "SET_ORG_NAME",
  SET_ORG_NAME_SUCCESS: "SET_ORG_NAME_SUCCESS",
  SET_ORG_NAME_FAILED: "SET_ORG_NAME_FAILED",
  CLEAN_SEATS_DATA: "CLEAN_SEATS_DATA",
};
