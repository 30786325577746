import { requestUrl } from "api/apiUtil";
import { getUserId } from "lib/auth";
import request from "utils/request";

export type PowerbarOptions = {
  industries: Chip[];
  roles: Chip[];
  sectors: {
    name: string;
    id: number;
    type: ChipType;
    es_id: string;
  }[];
};

export let suggestionsAbortController: AbortController;
export const getPowerbarSuggestions = async (
  input: string,
  filter:
    | "allData"
    | "companies"
    | "locations"
    | "roles"
    | "roles-and-locations"
    | "roles-list"
): Promise<Required<PowerbarSuggestions> & { cancelled?: boolean }> => {
  if (!suggestionsAbortController) {
    suggestionsAbortController = new AbortController();
  } else if (suggestionsAbortController) {
    suggestionsAbortController.abort();
    suggestionsAbortController = new AbortController();
  }

  if (typeof input !== "string") {
    suggestionsAbortController.abort();
    return { all: [], company: [], location: [], role: null, cancelled: true };
  }

  let chipType: ChipType | ChipType[];
  switch (filter) {
    case "companies":
      chipType = "company";
      break;
    case "locations":
      chipType = "location";
      break;
    case "roles":
      chipType = "role";
      break;
    case "roles-list":
      chipType = ["role"];
      break;
    case "roles-and-locations":
      chipType = ["role", "location"];
      break;
  }

  const res = await request(
    requestConfig.getPowerbarSuggested(
      input,
      chipType,
      suggestionsAbortController.signal
    )
  );

  if (!res) {
    return { all: [], company: [], location: [], role: null, cancelled: true };
  }

  const data = res.data as PowerbarSuggestions;

  return {
    all: data.all || [],
    company: data.company || [],
    location: data.location || [],
    role: filter === "roles-list" ? data.role : null,
  };
};

export const getPowerbarOptions = async (): Promise<PowerbarOptions> => {
  const res = await request(requestConfig.getPowerbarOptions());
  const options = res.data as PowerbarOptions;

  options.industries = options.industries.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  options.roles = options.roles.sort((a, b) => a.name.localeCompare(b.name));
  return options;
};

export const getPowerbarPopular = async (): Promise<Chip[]> => {
  const res = await request(requestConfig.getPowerbarPopular());
  return res.data;
};

export const getPowerbarRelated = async (
  searchTerms: Chip[]
): Promise<PowerbarRelated> => {
  const res = await request(
    requestConfig.getPowerbarRelated(searchTerms.filter((chip) => !!chip))
  );
  return res.data;
};

export const requestConfig = {
  getPowerbarPopular: (): RequestConfig => {
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "history",
        query: "limit=20&skip=0",
      }),
      options: {
        method: "GET",
      },
      auth: true,
    };
  },
  getPowerbarOptions: (): RequestConfig => {
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "options",
      }),
      options: {
        method: "GET",
      },
      auth: true,
    };
  },
  getPowerbarRelated: (searchTerms: Chip[]): RequestConfig => {
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "relate",
      }),
      options: {
        method: "POST",
        body: JSON.stringify({
          input: searchTerms,
          limit: 200,
        }),
      },
      auth: true,
    };
  },
  getPowerbarSuggested: (
    input: string,
    filter: ChipType | ChipType[],
    signal?: AbortSignal
  ): RequestConfig => {
    const filterPart = filter
      ? Array.isArray(filter)
        ? `&types=${filter.join()}`
        : `&type=${filter}`
      : "";
    const query = createQuery(input, filterPart);
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "suggest",
        query,
      }),
      options: {
        method: "GET",
        signal,
      },
    };
  },
};

/**
 * Create search query
 * @param input User input
 * @param filter Search type
 * @returns Generated query
 */
const createQuery = (input: string, filter: string): string => {
  let query = "";
  const hasIndustry = filter.includes("industry");
  const userId = getUserId();
  if (hasIndustry) {
    // If search type is "industry" then search hidden data too
    query = `input=${encodeURIComponent(
      input
    )}&limit=20${filter}&include_hidden=True&user_id=${encodeURIComponent(
      userId
    )}`;
  } else {
    query = `input=${encodeURIComponent(
      input
    )}&limit=20${filter}&user_id=${encodeURIComponent(userId)}`;
  }
  return query;
};
