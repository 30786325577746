import { CircularProgress } from "@mui/material";
import React, { MouseEventHandler } from "react";
import styled from "@emotion/styled";
import { Icon, StyledAButton, StyledButton } from "./styles";

export interface ButtonProps {
  className?: string;
  /**
   * @deprecated
   * Everyone on the team agreed that the naming of this prop was repulsive
   * Use `onClick`
   */
  clickHandler?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  fullWidth?: boolean;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  children: React.ReactNode;
  size?: "small" | "med" | "large";
  style?: React.CSSProperties;
  whitespace?: string;
  width?: string;
  type?: "submit" | "reset" | "button";
  asLink?: boolean;
  href?: string;
  icon?: string;
  iconDirection?: "left" | "right";
  outlined?: boolean;
  backgroundColor?: string;
  loading?: boolean;
  noUnderline?: boolean;
  smCircle?: boolean;
  textButton?: boolean;
}

export default function Button({
  className,
  clickHandler,
  onClick,
  children,
  disabled,
  secondary,
  fullWidth,
  type,
  size,
  style,
  asLink,
  width,
  href,
  icon,
  iconDirection,
  outlined,
  backgroundColor,
  noUnderline,
  smCircle,
  textButton,
  loading = false,
  ...custom
}: ButtonProps): JSX.Element {
  return asLink ? (
    <StyledAButton
      href={href}
      type={type}
      style={style}
      className={className}
      secondary={secondary}
      size={size}
      fullWidth={fullWidth}
      outlined={outlined}
      width={width}
      backgroundColor={backgroundColor}
      noUnderline={noUnderline}
      smCircle={smCircle}
      textButton={textButton}
      {...custom}
    >
      {children}
      {icon && <Icon src={icon} />}
      {iconDirection}
      {loading && <StyledSpinner size="0.75rem" data-testid="button-loader" />}
    </StyledAButton>
  ) : (
    <StyledButton
      type={type}
      style={style}
      className={className}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick || clickHandler}
      size={size}
      fullWidth={fullWidth}
      outlined={outlined}
      width={width}
      backgroundColor={backgroundColor}
      noUnderline={noUnderline}
      smCircle={smCircle}
      textButton={textButton}
      {...custom}
    >
      {children}
      {icon && <Icon src={icon} size={size} />}
      {loading && <StyledSpinner data-testid="button-loader" size="0.75rem" />}
    </StyledButton>
  );
}

export const StyledSpinner = styled(CircularProgress)`
  position: absolute;
  right: 15px;
  top: auto;
  bottom: auto;
`;
