import { createContext, useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { getUserId } from "store/auth/selectors";
import useSWR from "swr";
import { requestUrl } from "utils/api-request";
import request from "utils/request";

type OrganisationContextType = {
  organisation: Organisation;
  setOrganisation: (organisation: Organisation) => void;
};

const OrganisationContext = createContext<OrganisationContextType>({
  organisation: { id: "", name: "" },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setOrganisation: (organisation: Organisation) => {},
});

export default OrganisationContext;

type OrganisationResponse = {
  data: {
    organisation: Organisation;
  };
};

const fetcher = async (url): Promise<OrganisationResponse> =>
  await request({
    url: requestUrl({
      service: "v1-organisations",
      action: url,
    }),
    options: {
      method: "GET",
    },
    auth: true,
  });

type Props = { children: JSX.Element };

export type OrganisationStatus = "active" | "cancelled" | "paused" | "in-trial";
export type Organisation = {
  id: string;
  organisation_id?: string;
  name: string;
  current_term_end?: string;
  date_created?: string;
  date_updated?: string;
  last_billed?: string;
  organisation_email?: string;
  organisation_owners?: string[];
  plan_id?: string;
  status?: OrganisationStatus;
  transaction_id?: string;
};

export const OrganisationProvider = (props: Props): JSX.Element => {
  const sessionOrganisationName =
    global.window && window.sessionStorage
      ? window.sessionStorage.getItem("organisation-name")
      : undefined;
  const sessionOrganisationId =
    global.window && window.sessionStorage
      ? window.sessionStorage.getItem("organisation-id")
      : undefined;
  const sessionSlug =
    global.window && window.sessionStorage
      ? window.sessionStorage.getItem("slug")
      : undefined;
  const [organisation, setOrganisation] = useState<Organisation>({
    name: sessionOrganisationName,
    id: sessionOrganisationId,
  });
  const userId = useSelector(getUserId);
  // fetch users current org
  const { data } = useSWR(
    typeof userId !== "undefined" && userId !== "guest" ? `organisation` : null,
    fetcher,
    { shouldRetryOnError: false }
  );
  const [, setSlug] = useState(sessionSlug);
  function set(value: Organisation) {
    if (value.name === "me") {
      setOrganisation(undefined);
      setSlug(undefined);
      window.sessionStorage.removeItem("organisation");
      window.sessionStorage.removeItem("slug");
    } else {
      const slug = value.name.replace(" ", "-").toLowerCase();
      setOrganisation(value);
      setSlug(slug);
      window.sessionStorage.setItem("organisation-id", value.id);
      window.sessionStorage.setItem("organisation-name", value.name);
      window.sessionStorage.setItem("slug", slug);
    }
  }

  useEffect(() => {
    if (data?.data?.organisation) {
      setOrganisation({
        ...data.data.organisation,
        id: data.data.organisation.organisation_id,
      });
    }
  }, [data]);
  return (
    <OrganisationContext.Provider
      value={{
        organisation,
        setOrganisation: set,
      }}
    >
      {props.children}
    </OrganisationContext.Provider>
  );
};
