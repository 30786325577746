import { ACTION } from "./constants";
export const initialState = {
  hostedPage: null,
  error: null,
  loading: null,
  orgName: null,
  accessToken: null,
  own: [],
  assigned: [],
  upgrading: false,
};

export default function seatsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.FETCH_SEATS:
      return {
        ...state,
        loading: true,
      };
    case ACTION.FETCH_SEATS_SUCCESS:
      return {
        ...state,
        loading: false,
        own:
          action.payload &&
          action.payload.own &&
          action.payload.own.map((seat) => {
            let invitationTokenData;
            if (seat.invitation_token) {
              try {
                invitationTokenData = JSON.parse(
                  window.atob(seat.invitation_token)
                );
                // eslint-disable-next-line no-empty
              } catch (error) {}
            }
            return invitationTokenData
              ? {
                  email: invitationTokenData.invitee_email,
                  status: "Invite Sent",
                  seatId: invitationTokenData.seat_id,
                  occupied: false,
                }
              : {
                  seatId: seat.id,
                  occupied: !!seat.assigned_user_id,
                  email: seat.user && seat.user.email,
                  name: seat.user && seat.user.name,
                  picture: seat.user && seat.user.picture,
                  assignedUserId: seat.assigned_user_id,
                  hasOutlookIntegration: seat?.user?.has_outlook_integration,
                  status: "User",
                  subscriptionId: seat.subscription_id,
                  planId: seat.plan_id,
                  admin:
                    seat.assigned_user_id &&
                    seat.assigned_user_id === seat.owner_id,
                };
          }),
        accessToken: action.payload.accessToken,
      };

    case ACTION.FETCH_SEATS_FAILED:
      return {
        ...state,
        own: [],
        assigned: [],
        error: "Could not get seats",
      };

    case ACTION.FETCH_PAYMENT_SOURCE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION.FINISH_UPGRADING:
      return {
        ...state,
        upgrading: false,
      };

    case ACTION.SET_ORG_NAME:
    case ACTION.SET_ORG_NAME_SUCCESS:
      return {
        ...state,
        orgName: action.payload.orgName,
      };

    case ACTION.CLEAN_SEATS_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
