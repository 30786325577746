/**
 * Captain Hook bro
 */

import { FetchResponse, requestV2 } from "api/request";
import useSWR, { SWRResponse } from "swr";
import { PublicConfiguration } from "swr/dist/types";

type Args<P> = {
  url: string | string[];
  method: "GET" | "POST";
  key: string | number;
  suspense?: boolean;
  condition?: boolean;
  multiple?: boolean;
  payload?: P;
  swrConfig?: Partial<PublicConfiguration>;
};
export type ExtendedSWRResponse<T, K = Record<string, unknown>> = SWRResponse<
  FetchResponse<T, K>,
  Error
> & {
  isLoading: boolean;
  isError: Error;
};

/**
 * Request needs token to be passed
 * @param - { url, method, key, suspense, condition }
 */
export const useAuthenticatedSWR = <
  T,
  P = Record<string, unknown>,
  K = Record<string, unknown>
>({
  url,
  method = "GET",
  key,
  swrConfig,
  suspense = false,
  condition = true,
  payload = {} as P,
}: Args<P>): ExtendedSWRResponse<T, K> => {
  const fetcher = async (): Promise<FetchResponse<T, K>> => {
    try {
      const req = (u: string) =>
        requestV2<T, K>({
          url: u,
          method,
          body: method !== "GET" ? JSON.stringify(payload) : undefined,
        });
      if (Array.isArray(url)) {
        const urls = url as string[];
        const data = await Promise.all(urls.map(req));
        return data.reduce((acc, curr) => ({
          ...acc,
          data: {
            ...acc.data,
            ...curr.data,
          },
        }));
      }
      return await req(url);
    } catch (e) {
      throw new Error(e);
    }
  };

  const { data, error, isValidating, ...rest } = useSWR(
    condition ? (key as string) : null,
    fetcher,
    {
      suspense,
      shouldRetryOnError: false,
      ...swrConfig,
    }
  );

  return {
    data,
    isLoading: !data,
    isError: error,
    isValidating,
    ...rest,
  };
};
