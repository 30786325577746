import { useAuth0 } from "@auth0/auth0-react";
import { TrayProvider } from "@talentticker/shared";
import OrganisationContext from "organisation-context";
import { useContext } from "react";

export const TrayProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { organisation } = useContext(OrganisationContext);
  const organisationId = organisation?.organisation_id;
  const { user } = useAuth0();
  return (
    <TrayProvider organisationId={organisationId as string} user={user}>
      {children}
    </TrayProvider>
  );
};
