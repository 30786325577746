import {
  getMarketMappingResults,
  MarketMappingSearchResponse,
} from "./SearchApi";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";
import { useDispatch } from "react-redux";
import { fetchSearchResultsRequest } from "store/searches/actions";
import { ContactEmailTypes } from "@talentticker/tt-utils";

export type MarketMappingSearchActions =
  | "market-mapping-search"
  | "market-mapping-search-beta";

type UseMarketMappingSearchArgs = {
  included: Chip[];
  featureFlag?: boolean;
  filters: Partial<SearchFilters>;
  shouldFetch: boolean;
};

export function useMarketMappingSearch({
  included,
  filters,
  shouldFetch = null,
}: UseMarketMappingSearchArgs): SWRInfiniteResponse<
  MarketMappingSearchResponse,
  unknown
> {
  const endpoint = `v5-powersearch/market-mapping-search`;
  const action = endpoint.split("/")[1] as MarketMappingSearchActions;
  const dispatch = useDispatch();
  async function fetcher(url: string): Promise<MarketMappingSearchResponse> {
    dispatch(
      fetchSearchResultsRequest(
        {
          search: included,
          excluded: [],
          userId: "",
          radius: undefined,
          tab: "market-mapping",
        } as SearchCriteria,
        filters
      )
    );
    const cursor = url.split("?")[1];
    if (cursor) {
      const results = await getMarketMappingResults({
        searchTerms: included,
        action,
        cursor,
        filters,
      });
      return results;
    }
    return await getMarketMappingResults({
      searchTerms: included,
      action,
      filters,
    });
  }
  return useSWRInfinite(
    (index: number, previousPage: MarketMappingSearchResponse) => {
      let cursor = "";
      if (previousPage && previousPage.after_key) {
        cursor = `?${JSON.stringify(previousPage.after_key)}`;
      }
      return shouldFetch
        ? `${endpoint}${JSON.stringify({
            ...included,
            ...filters,
          })}${cursor}`
        : null;
    },
    fetcher,
    { revalidateAll: true }
  );
}
