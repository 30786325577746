import { ACTION } from "./constants";
export const initialState = {
  cookiesAcknowledged: false,
  saveSearchTooltipAcknowledged: false,
  snackbarMessages: [],
  dismissedCountdown: 0,
};

export default function noticesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.ACKNOWLEDGE_COOKIES:
      return { ...state, cookiesAcknowledged: true };
    case ACTION.ACKNOWLEDGE_SAVE_SEARCH_TOOLTIP:
      return { ...state, saveSearchTooltipAcknowledged: true };
    case ACTION.ADD_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarMessages: []
          .concat(state.snackbarMessages)
          .concat(action.payload),
      };
    case ACTION.REMOVE_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarMessages: state.snackbarMessages.filter(
          (item) => item && item.message !== action.message
        ),
      };
    case ACTION.DISMISSED_COUNTDOWN:
      return {
        ...state,
        dismissedCountdown: global.Date.now(),
      };
    case ACTION.RESET_COUNTDOWN:
      return {
        ...state,
        dismissedCountdown: initialState.dismissedCountdown,
      };
    default:
      return state;
  }
}
