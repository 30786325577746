import { ACTION } from "./constants";
export const initialState = {};

export default function statsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.FETCH_STATS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_STATS_SUCCESS:
      return {
        ...state,
        data: [...action.payload],
        loading: false,
      };

    case ACTION.FETCH_STATS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case ACTION.EXPORT_CSV:
      return {
        ...state,
        csvData: null,
      };

    case ACTION.EXPORT_CSV_SUCCESS:
      return {
        ...state,
        csvData: action.payload,
      };

    case ACTION.EXPORT_CSV_CLEAR:
      return {
        ...state,
        csvData: null,
      };

    default:
      return state;
  }
}
