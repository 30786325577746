import { PermissionKey } from "lib/features";

export const GOOGLE_RECAPTCHA = {
  SITE_KEY: "6LccykcUAAAAAJcDpkyxmXLYEwM32uRQSoaWkvqt",
  SECRET_KEY: "6LccykcUAAAAAEipuU6KMsVnJe7ZlamYQ9-fPriL",
};

export const ERROR_MESSAGES = {
  EMAIL: "Please enter a valid email address",
  EMAIL_REQUIRED: "Please enter email address",
  EMAIL_MATCH: "Email addresses must match",
  PASSWORD_REQUIRED: "Please enter password",
  PASSWORD: "Your password should be at least 8 characters",
  PASSWORD_MATCH: "Passwords must match",
  FNAME_REQUIRED: "Please enter a first name",
  LNAME_REQUIRED: "Please enter a last name",
  NAME_REQUIRED: "Please enter a valid name",
  YNAME_REQUIRED: "Please enter a valid name",
  MESSAGE_REQUIRED: "Please enter a valid message",
  COMPANY_REQUIRED: "Please enter a company name",
  ROLE_REQUIRED: "Please enter a role",
  LINKEDINID_REQUIRED: "Please enter your linked in url or id",
  LINKEDIN_FORMAT:
    "Please include your full linked in profile url or your linked in user id",
  REASON_REQUIRED: "Please enter a reason for this suggested edit",
  CODE_REQUIRED: "Please enter a valid invitation code",
  EVENT_NOT_FOUND: "We can't find this article.",
  SOMETHING_WENT_WRONG: "Oops! Something went wrong",
};

export const REQUEST_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const APP_ERROR = {
  TYPE404: 404,
  TYPE500: 500,
  CONTENT_NOT_FOUND: "CONTENT_NOT_FOUND",
  HIDE_ERROR: null,
};

export const SSO_ACTION = {
  login: "login",
  signup: "signup",
};

export const LABELS = {
  allIndustries: "All Industries",
  allRoles: "All Roles",
  allLocations: "All Locations",
  typeLocation: "Type Location",
  allHiringFiring: "Both",
};

export const PERMISSIONS = {
  READ_COMPANY_MANAGERS: "read:company_managers",
  READ_CURRENT_USER: "read:current_user",
  READ_EVENTS_NEWEST: "read:events_newest",
  READ_EVENT_MANAGERS: "read:event_managers",
  CREATE_PIPELINE_TASK: "create:pipeline_task",
  READ_PIPELINE_TASK: "read:pipeline_tasks",
  EDIT_PIPELINE_TASK: "edit:pipeline_task",
  DELETE_PIPELINE_TASK: "delete:pipeline_task",
  CREATE_PIPELINE_NOTE: "create:pipeline_note",
  READ_NOTIFICATIONS: "read:in_app_notifications",
  ADD_TO_BULLHORN: "create:bullhorn_entity",
  ADD_TO_VINCERE: "create:vincere_entity",
  EDIT_TEAM: "edit:team",
  READ_JOBSEEKER_PROFILE: "read:jobseekerprofile",
  EDIT_ANY_ORGANISATION: "edit:any_org",
  PERSONAL_EMAILS: "filter:personal_contacts",
  DELETE_RAW_EVENT: "delete:raw_event",
  SEND_EMAIL: "send:email",
  READ_MARKET_MAPPING: "read:market_mapping",
  READ_SEARCH: "read:search",
  READ_SEARCH_MORE_RESULTS: "read:search_more_results",
  READ_TALENT_SOURCING: "read:talent_sourcing",
  CREATE_EVENT_ACCURACY: "create:event_accuracy",
  UPDATE_EVENT_STATUS: "update:event_status",
};

export const IMAGE_ERROR_FALLBACK = "/images/og_placeholder.jpg";
export const IMAGE_ERROR_FALLBACK_SM = "/images/og_placeholder_small.jpg";
