import App from "next/app";
import { Fragment } from "react";
import { Provider } from "react-redux";
import withReduxStore from "../lib/with-redux-store";
import ErrorBoundary from "components/ErrorBoundary";
import ScrollToTop from "components/ScrollToTop";
import { OrganisationProvider } from "../organisation-context";
import * as Sentry from "@sentry/browser";
import "nprogress/nprogress.css";
import "../styles.scss";
import "@talentticker/tt-editor/dist/style.css";
import * as configcat from "configcat-js-ssr";
import { Store } from "redux";
import { CONFIG_CAT_KEY, ENV, PRODUCT_NAME } from "../env";
import { ConfigCatProvider } from "lib/configcat";
import { FeatureProvider } from "lib/features";
import { EnvProvider } from "lib/env";
import * as env from "../env";
import dynamic from "next/dynamic";
import { BookDemoModalContextProvider } from "components/BookDemoModal/context";
import { OrganisationSeatContextProvider } from "lib/useOrganisationSeatContext/context";
import { UserProvider } from "lib/auth/UserProvider";
import { TrackerProvider } from "lib/tracking/TrackerProvider";
import { WebAuth } from "auth0-js";
import { Toaster } from "react-hot-toast";
import Auth0Setup from "lib/auth/AuthProvider";
import { TrayProviders } from "components/Tray/Provider";
import { ThemeProvider } from "theme/ThemeProvider";

declare global {
  interface Window {
    webAuth: WebAuth;
    Chargebee: any;
    pendo: any;
    ga: any;
    _hsq: any;
    smartlook: any;
  }
}

// This is to be able to identify the release
// eslint-disable-next-line no-console
console.log(
  `${process.env.NEXT_PUBLIC_PRODUCT_NAME} - ${
    process.env.NEXT_PUBLIC_RELEASE || "dev"
  }`
);
if (global.navigator && "serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`/service-worker.js?PRODUCT_NAME=${PRODUCT_NAME}`)
    .then((registration) => {
      // eslint-disable-next-line no-console
      console.log(
        "ServiceWorker registration succesful with scope",
        registration.scope
      );
    })
    // eslint-disable-next-line no-console
    .catch(console.log);
}

// Client-side cache, shared for the whole session of the user in the browser.
/* const clientSideEmotionCache = createEmotionCache(); */

/**
 * This acts a page load inidicator and should only be client side rendered to track route changes correctly
 */
const TopProgressBar = dynamic(() => import(`components/ProgressBar`), {
  ssr: false,
});

type Props = {
  isProduction: boolean;
  reduxStore: Store<GlobalState>;
  /* emotionCache: EmotionCache; */
};

class TalentTickerApp extends App<Props> {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  private configCatClient = configcat.createClientWithLazyLoad(
    CONFIG_CAT_KEY || "",
    {
      cacheTimeToLiveSeconds: 300,
      logger: ENV === "prod" ? undefined : configcat.createConsoleLogger(3),
    }
  );

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  render() {
    const {
      Component,
      pageProps,
      reduxStore,
      /* emotionCache = clientSideEmotionCache, */
    } = this.props;
    return (
      <Fragment>
        <>
          <noscript>
            <iframe
              title="google-tag-manager"
              src={`https://www.googletagmanager.com/ns.html?id=${env.GOOGLE_TAG_MANAGER_ID}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        </>
        <ErrorBoundary>
          <Provider store={reduxStore as Store<GlobalState>}>
            <Auth0Setup>
              <OrganisationProvider>
                <OrganisationSeatContextProvider>
                  <UserProvider>
                    <EnvProvider env={env}>
                      <TrackerProvider />
                      <ConfigCatProvider client={this.configCatClient}>
                        <FeatureProvider>
                          <ThemeProvider>
                            <ScrollToTop>
                              <BookDemoModalContextProvider>
                                <TrayProviders>
                                  <>
                                    {/* Adding this so we can migrate away from the redux version and be synced with the extension                                  */}
                                    <Toaster
                                      containerStyle={{ top: 175 }}
                                      position="top-center"
                                    />
                                    <TopProgressBar />
                                    <Component {...pageProps} />
                                  </>
                                </TrayProviders>
                              </BookDemoModalContextProvider>
                            </ScrollToTop>
                          </ThemeProvider>
                        </FeatureProvider>
                      </ConfigCatProvider>
                    </EnvProvider>
                  </UserProvider>
                </OrganisationSeatContextProvider>
              </OrganisationProvider>
            </Auth0Setup>
          </Provider>
        </ErrorBoundary>
      </Fragment>
    );
  }
}

export default withReduxStore(TalentTickerApp);
