import { mapFiltersToServerFormat, requestUrl } from "api/apiUtil";
import request from "../../utils/request";
import { EVENTS } from "store/searches/constants";
import { MarketMappingSearchActions } from "./useMarketMappingSearch";
import { ContactEmailTypes, DEFAULT_EMAIL_TYPES } from "@talentticker/tt-utils";

type GetMarketMappingResultsArgs = {
  searchTerms: Chip[];
  action: MarketMappingSearchActions;
  cursor?: string;
  filters?: Partial<SearchFilters>;
};

export type MarketMappingSearchRequest = {
  aggregation_field: string;
  input: Chip[];
  exclusion: Chip[];
  limit: number;
  company_sizes: CompanySizeFilter[];
  after_value: string;
  size_per_bucket: number;
  email_types: ContactEmailTypes[];
  distance_in_miles: number;
};

export type MarketMappingSearchResponse = {
  after_key: {
    company_id: number;
  };
  count: number; // Number of groups, correlates to "limit" passed in the request, Like 10
  groups: MarketMappingResult[];
  total: number; // Number of total ungrouped people matched by search, like 27189
};

export const getMarketMappingResults = async ({
  searchTerms,
  action,
  filters,
  cursor,
}: GetMarketMappingResultsArgs): Promise<MarketMappingSearchResponse> => {
  try {
    let cursorObj;
    let after_value = undefined;
    let aggregation_field = "company_id";

    if (cursor) {
      cursorObj = JSON.parse(cursor);
      aggregation_field = Object.getOwnPropertyNames(cursorObj)[0];
      after_value = cursorObj[aggregation_field];
    }

    const res = await request(
      requestConfig.getMarketMappingResults(
        {
          after_value,
          aggregation_field,
          distance_in_miles: 10,
          exclusion: [],
          input: searchTerms,
          limit: 10,
          size_per_bucket: 8,
          company_sizes: filters.companySizes,
          email_types: DEFAULT_EMAIL_TYPES,
        },
        action
      )
    );
    return res.data.results as MarketMappingSearchResponse;
  } catch (err) {
    // console.log(err);
  }
};

export const getSavedSearches = async (
  userId: string
): Promise<SavedSearchesResponse> => {
  const res = await request(requestConfig.getSavedSearches(userId));

  return res.data as SavedSearchesResponse;
};

export const postSavedSearch = async (
  included: Chip[],
  excluded: Chip[],
  filters: Partial<SearchFilters>
): Promise<SaveSearchResponse> => {
  const res = await request(
    requestConfig.saveSearch(included, excluded, filters)
  );

  return res.data as SaveSearchResponse;
};

export const getPowerSearchResults = async (
  payload: (SearchCriteria | GetUpdatedResultsSearchCriteria) & {
    eventsLock?: { key: EventTypeCategoryFilter }[];
  } & Partial<SearchFilters>,
  guest?: boolean
): Promise<SearchResultsResponse> => {
  const tab: SearchResultsTab = payload.tab ? payload.tab : "news";
  let body = {} as Partial<SearchRequest>;
  if (guest) {
    body = {
      chip_set: payload.search,
      limit: payload.limit || EVENTS.LIMIT,
      type: tab,
    } as Partial<SearchRequest>;
  } else {
    body = {
      chip_set: payload.search,
      limit: payload.limit || EVENTS.LIMIT,
      skip: payload.skip || 0,
      search_after: payload.searchAfter || undefined,
      tab: tab,
      user_id: payload.userId,
      events_lock: payload.eventsLock || null,
      save_history: payload.saveHistory || false,
      distance_in_miles: payload.radius === 0 ? 1 : payload.radius,
      desc: "timestamp_published",
      query_duplicate_names:
        typeof payload.queryDuplicateNames === "boolean"
          ? payload.queryDuplicateNames
          : true,
      ...mapFiltersToServerFormat(payload),
    };
  }

  if (payload.tab === "bdl") {
    body.trend = "hiring";
  }

  if (payload.excluded) {
    body.exclusion = payload.excluded;
  }

  const res = await request(
    requestConfig.getPowerbarSearchResults(body, guest)
  );

  return res.data as SearchResultsResponse;
};

const requestConfig = {
  getMarketMappingResults: (
    body: MarketMappingSearchRequest,
    action: MarketMappingSearchActions
  ): RequestConfig => {
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action,
      }),
      options: {
        method: "POST",
        body: JSON.stringify(body),
      },
      auth: true,
    };
  },
  getPowerbarSearchResults: (
    body: Partial<SearchRequest>,
    guest?: boolean
  ): RequestConfig => {
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: guest ? "search/guest" : "search",
      }),
      options: {
        method: "POST",
        body: JSON.stringify(body),
      },
      auth: true,
    };
  },
  saveSearch: (
    search: Chip[],
    exclude: Chip[],
    searchFilters: Partial<SearchFilters> = {}
  ): RequestConfig => {
    const body = {
      chip_set: search,
      chip_set_exclusion: exclude,
      ...mapFiltersToServerFormat(searchFilters),
    };

    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "save",
      }),
      options: {
        method: "POST",
        body: JSON.stringify(body),
      },
      auth: true,
    };
  },
  getSavedSearches: (userId: string): RequestConfig => {
    return {
      url: requestUrl({
        service: `v5-powersearch`,
        action: "save",
        query: `user_id=${encodeURIComponent(userId)}&sort=desc`,
      }),
      options: {
        method: "GET",
      },
      auth: true,
    };
  },
};
