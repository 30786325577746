import { parseISO } from "date-fns";
import format from "date-fns/format";

export type EventArticle = {
  article_status: string;
  name: string;
  logo: string;
  title: string;
  url: string;
  opengraph_data: {
    site_name: string;
    image: string;
    description: string;
    type: string;
    pictures: Record<string, string>;
  };
};
export type EventData = {
  title: string;
  event_status?: "annotated" | "hidden" | "invalid";
  assigned_users: any[];
  timestamp_published: string;
  summary: string;
  summary_anaxym: string; // as per https://t-ticker.atlassian.net/browse/TP-1235 this is likely to start coming through
  score: number;
  event_id: string;
  company_details?: {
    alias: string[];
    display_name: string;
    id: number;
    name: number;
    num_employees_max: number;
    short_description: string;
    offices: {
      city_id: number;
      country: string;
      province: string;
      street_1: string;
      city: string;
      street_2: string;
      is_headquarters: boolean;
      phone_number: string;
      id: string;
      postal_code: string;
      region: string;
      us_state: string;
    }[];
    industries: {
      clearbit_industries: any[];
      crunchbase_industries: any[];
      industry_id: number;
      pdl_industries: any[];
      hidden: boolean;
      sector_id: number;
      covered: boolean;
      name: string;
    }[];
    urls: Record<string, string>;
    profile_image_url: string;
  };
  job_description?: string;
  sources?: EventArticle[];
  like?: boolean;
  num_likes: number;
  leads: EventLead[];
  isVacancy?: boolean;
  is_in_pipeline?: boolean;
};
export type FormattedEvent = ReturnType<typeof formatEventProperties>;

const formatEventProperties = (eventData: EventData) => {
  const offices =
    (eventData.company_details && eventData.company_details.offices) || [];
  const getOfficePhoneNumber = (lead) => {
    const office = offices.find((s) => s.city_id === lead.location_id);
    return office ? office.phone_number : null;
  };
  return {
    fullData: eventData,
    assignedUsers: eventData.assigned_users || [],
    eventStatus: eventData.event_status || "annotated",
    eventTitle: eventData.title || null,
    eventDate: eventData.timestamp_published,
    eventSummaryTalentTicker: eventData.summary,
    eventSummaryAnaxym: eventData.summary_anaxym,
    eventImage:
      eventData.sources &&
      eventData.sources.length &&
      eventData.sources[0].opengraph_data &&
      eventData.sources[0].opengraph_data.image,
    eventImages:
      eventData.sources &&
      eventData.sources.length &&
      eventData.sources[0].opengraph_data &&
      eventData.sources[0].opengraph_data.pictures,
    eventScore: eventData.score,
    eventSource:
      eventData.sources &&
      eventData.sources.length &&
      eventData.sources[0].name,
    eventId: eventData && eventData.event_id && eventData.event_id.toString(),
    eventUrl:
      eventData.sources && eventData.sources[0] && eventData.sources[0].url,
    error: !eventData,
    companyDetails: {
      displayName:
        eventData.company_details && eventData.company_details.display_name,
      id: eventData.company_details && eventData.company_details.id,
      homepage:
        (eventData.company_details &&
          eventData.company_details.urls &&
          eventData.company_details.urls.homepage) ||
        "",
    },
    headquarters: offices ? offices.find((s) => s.is_headquarters) : "",
    leads: (eventData.leads
      ? eventData.leads.map((lead) => ({
          ...lead,
          office: offices.find((s) => s.city_id === lead.location_id),
          switchboardNumber:
            eventData.isVacancy ||
            (eventData.leads &&
              eventData.leads.length &&
              !eventData.leads
                .reduce(
                  (prev, curr) =>
                    prev.concat([
                      curr.hr_manager_details,
                      curr.hiring_manager_details,
                    ]),
                  []
                )
                .filter(Boolean).length)
              ? getOfficePhoneNumber(lead)
              : null,
        }))
      : null) as EventLead[] | null,
    inPipeline: eventData.is_in_pipeline,
    description: eventData.job_description,
  };
};

function reduceCompanySpotlight(events) {
  const company = events[0].company_details;
  const scores = events.map((s) => s.score);
  const avgScore =
    scores.reduce((prev, curr) => prev + curr, 0) / scores.length;

  const scoreHistogram = events.map((event) => {
    return {
      date: format(parseISO(event.timestamp_published), "dd/MM"),
      score: event.score,
    };
  });
  return {
    event_scores: scores,
    event_histo: scoreHistogram,
    events,
    career_url: company.urls ? company.urls.careers : null,
    company_url: company.urls ? company.urls.homepage : null,
    company_average_score: avgScore,
    company_display_name: company.display_name,
    company_image_url: company.profile_image_url,
    company_name: company.name,
    _id: events[0].company_id,
  };
}

export { formatEventProperties, reduceCompanySpotlight };
