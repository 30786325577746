import { useCallback, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";

import { getUserId, getProfileData, getLoginEmail } from "store/auth/selectors";
import { cleanUserContent, startSession } from "store/auth/actions";
import request from "utils/request";
import { requestConfig } from "config/apiResource";
import OrganisationContext from "organisation-context";
import { isAccessTokenExpired } from "lib/auth";

function handleHubspot(
  userEmail: string,
  profileData: Record<string, unknown>
) {
  if (window._hsq) {
    window._hsq.push([
      "identify",
      {
        email: userEmail,
        ...profileData,
      },
    ]);
  }
}

function handlePendo(
  userId: string,
  userEmail: string,
  orgId: string,
  orgName: string
) {
  if (!(userId && userEmail)) {
    return;
  }
  if (window?.pendo) {
    window.pendo.initialize({
      visitor: {
        id: userId,
        email: userEmail,
      },
      account: {
        id: orgId,
        name: orgName,
      },
    });
  }
}

function handleSmartlook(userId: string, name: string, email: string) {
  if (window?.smartlook && !/\/business-news/.test(window.location.pathname)) {
    window.smartlook("identify", userId, { name, email });
  }
}

function recordSession() {
  return request(requestConfig.recordSession());
}
export const TrackerProvider = (): null => {
  const dispatch = useDispatch();
  const { organisation } = useContext(OrganisationContext);
  const userId = useSelector(getUserId);
  const profileData = useSelector(getProfileData);
  const userEmail = useSelector(getLoginEmail);
  const accessTokenExpired = isAccessTokenExpired();

  const handleSessionRecording = useCallback(async () => {
    if (!window.sessionStorage.getItem("sessionRecorded")) {
      await recordSession();
      window.sessionStorage.setItem("sessionRecorded", "true");
      dispatch(startSession());
    }
  }, [dispatch]);

  useEffect(() => {
    if (userId && userId !== "guest") {
      if (!accessTokenExpired) {
        handleSessionRecording();
      }
      Sentry.setUser({
        id: userId,
        email: userEmail as string,
      });
      handleHubspot(userEmail as string, profileData);
      if (organisation?.id) {
        handlePendo(
          userId,
          userEmail as string,
          organisation.id,
          organisation.name
        );
      }
      handleSmartlook(userId, profileData?.name || "", userEmail as string);
      if (window.ga) {
        window.ga("set", "userId", userId);
        window.ga("send", "event", "account", "login");
      }
    } else {
      dispatch(cleanUserContent());
    }
    // This is how this worked and changing it will affect the
    // analytics. It might be something that we do need to
    // investigate. It would be important to reassess this
    // when we come to rework auth0
    //eslint-disable-next-line
  }, [userId, dispatch, organisation]);
  return null;
};
