export const ACTION = {
  GET_CAROUSEL: "GET_CAROUSEL",
  GET_CAROUSEL_SUCCESS: "GET_CAROUSEL_SUCCESS",
  GET_CAROUSEL_ERROR: "GET_CAROUSEL_ERROR",
  CANCEL_GET_CAROUSEL: "CANCEL_GET_CAROUSEL",
  GET_SAVED_SEARCH: "GET_SAVED_SEARCH",
  GET_SAVED_SEARCH_SUCCESS: "GET_SAVED_SEARCH_SUCCESS",
  GET_SAVED_SEARCH_ERROR: "GET_SAVED_SEARCH_ERROR",
  CANCEL_GET_SAVED_SEARCH: "CANCEL_GET_SAVED_SEARCH",
  GET_LATEST_VACANCIES: "GET_LATEST_VACANCIES",
  GET_LATEST_VACANCIES_SUCCESS: "GET_LATEST_VACANCIES_SUCCESS",
  CANCEL_GET_VACANCIES: "CANCEL_GET_VACANCIES",
  GET_VACANCIES_ERROR: "GET_VACANCIES_ERROR",
  GET_BREAKING_NEWS: "GET_BREAKING_NEWS",
  GET_BREAKING_NEWS_SUCCESS: "GET_BREAKING_NEWS_SUCCESS",
  CANCEL_GET_BREAKING_NEWS: "CANCEL_GET_BREAKING_NEWS",
  GET_BREAKING_NEWS_ERROR: "GET_BREAKING_NEWS_ERROR",
  GET_LATEST_NEWS: "GET_LATEST_NEWS",
  GET_LATEST_NEWS_SUCCESS: "GET_LATEST_NEWS_SUCCESS",
  CANCEL_GET_LATEST_NEWS: "CANCEL_GET_LATEST_NEWS",
  GET_LATEST_NEWS_ERROR: "GET_LATEST_NEWS_ERROR",
  GET_BLOG_POSTS: "GET_BLOG_POSTS",
  GET_BLOG_POSTS_SUCCESS: "GET_BLOG_POSTS_SUCCESS",
  CANCEL_GET_BLOG_POSTS: "CANCEL_GET_BLOG_POSTS",
  GET_BLOG_POSTS_ERROR: "GET_BLOG_POSTS_ERROR",
  GET_HIRING_TRENDS: "GET_HIRING_TRENDS",
  GET_TRENDS_SUCCESS: "GET_TRENDS_SUCCESS",
  CANCEL_GET_TRENDS: "CANCEL_GET_TRENDS",
  GET_TRENDS_ERROR: "GET_TRENDS_ERROR",
};

export const SEARCHES = {
  latestLeads: {
    desc: "timestamp_published",
    limit: 3,
    skip: 0,
    tab: "news",
    type: ["intelligence"],
    input: [],
  },
  latestVacancies: {
    desc: "timestamp_published",
    limit: 4,
    skip: 0,
    tab: "vacancies",
    input: [],
  },
  breakingNews: {
    desc: "timestamp_published",
    limit: 1,
    skip: 0,
    tab: "news",
    input: [],
  },
  newestLeads: {
    desc: "timestamp_published",
    limit: 6,
    skip: 0,
    tab: "news",
    input: [],
  },
};
