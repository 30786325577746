import { ACTION } from "./constants";
import { SetOutsideSearchAction } from "./reducer";

export function updateEventPipelineStatus(payload) {
  return {
    type: ACTION.UPDATE_PIPELINE_STATUS,
    payload,
  };
}

export function setOutsideSearch(
  search: Chip[],
  excluded?: Chip[]
): SetOutsideSearchAction {
  return {
    type: "SET_OUTSIDE_SEARCH",
    payload: { search, excluded },
  };
}

export function clearSearchResults() {
  return {
    type: ACTION.CLEAR_SEARCH_RESULTS,
  };
}

export function setSearchTerms(
  included: Chip[],
  excluded?: Chip[],
  radius?: number
) {
  return {
    type: ACTION.SET_SEARCH_TERMS,
    payload: { included, excluded, radius },
  };
}

export type FetchSearchResultsSuccessAction = {
  type: "FETCH_SEARCH_RESULTS_SUCCESS";
  payload: SearchResultsResponse;
};
export function fetchSearchResultsSuccess(
  data: SearchResultsResponse
): FetchSearchResultsSuccessAction {
  return {
    type: "FETCH_SEARCH_RESULTS_SUCCESS",
    payload: data,
  };
}
type FetchSearchResultsRequestAction = {
  type: "FETCH_SEARCH_RESULTS_REQUEST";
  payload: {
    excluded: Chip[];
    filters: Partial<SearchFilters>;
    radius: number;
    search: Chip[];
    tab: EventSearchType;
  };
};

export function fetchSearchResultsRequest(
  criteria: SearchCriteria | GetUpdatedResultsSearchCriteria,
  filters: Partial<SearchFilters>
): FetchSearchResultsRequestAction {
  return {
    type: "FETCH_SEARCH_RESULTS_REQUEST",
    payload: {
      excluded: criteria.excluded,
      filters,
      radius: criteria.radius,
      search: criteria.search,
      tab: criteria.tab,
    },
  };
}
export type FetchMoreResultsSuccessAction = {
  type: "FETCH_MORE_RESULTS_SUCCESS";
  payload: SearchResultsResponse;
};
export type FetchSearchResultsFailedAction = {
  type: "FETCH_SEARCH_RESULTS_FAILED";
  error: any;
};
export function fetchSearchResultsFailed(
  error: any
): FetchSearchResultsFailedAction {
  return {
    type: "FETCH_SEARCH_RESULTS_FAILED",
    error,
  };
}
export function fetchMoreResultsSuccess(
  data: SearchResultsResponse
): FetchMoreResultsSuccessAction {
  return {
    type: "FETCH_MORE_RESULTS_SUCCESS",
    payload: data,
  };
}
