import { createContext, ReactNode, useContext } from "react";

type Env = Record<string, unknown>;

type EnvProviderProps = {
  children: ReactNode;
  env: Env;
};

const EnvContext = createContext<Env>(null);

/**
 * Used to access the env.ts file from one point
 * Allowing easy mocking of environment
 */
export const EnvProvider = ({
  children,
  env = {},
}: EnvProviderProps): JSX.Element => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);

export const useEnv = () => useContext(EnvContext);
