import { ACTION, EVENTS, SEARCHES } from "./constants";
import {
  savedSearchApiToSavedSearchMapper,
  savedSearchSavedApiMapper,
} from "./mapper";

const searchResult: SearchResult = {
  page: 1,
  docCount: 1,
  totalCount: 1,
  totalPages: 1,
  documents: [],
  events: [],
};

const suggest = {
  searches: [],
  events: [],
  managers: {},
};

let newState;

export const initialState: SearchState = {
  loading: undefined,
  error: null,
  isPowerBarOpen: false,
  saved: [],
  recentSearches: [],
  results: {
    news: searchResult,
    vacancies: searchResult,
    bdl: searchResult,
  },
  suggested: {
    news: suggest,
    vacancies: suggest,
    bdl: suggest,
    "market-mapping": undefined,
  },
  searchTerms: [],
  excluded: [],
  outsideSearch: [],
  radius: 10,
  filterOptions: {
    news: {},
    vacancies: {},
    bdl: {},
  },
};

const sameChips = (a: Chip[], b: Chip[]) => {
  if (!a || !b || !a.map || !b.map) {
    return false;
  }
  const aIds = new Set(a.map((x) => x.es_id));
  const bIds = b.map((x) => x.es_id);

  return aIds.size === bIds.length && bIds.every((es_id) => aIds.has(es_id));
};

export const sameSearch = (a: RecentSearch, b: RecentSearch) => {
  return (
    sameChips(a.chips, b.chips) &&
    sameChips(a.chips_exclusion, b.chips_exclusion)
  );
};

type ChangeSearchNameSuccessAction = {
  type: "CHANGE_SEARCH_NAME_SUCCESS";
  payload: { id: string; name: string };
};

type ChangeNotificationTypeSuccessAction = {
  type: "CHANGE_NOTIFICATION_TYPE_SUCCESS";
  payload: {
    id: string;
    notificationType: SavedSearchNotificationOption;
  };
};

type ChangeNotificationsEnabledSuccessAction = {
  type: "CHANGE_NOTIFICATIONS_ENABLED_SUCCESS";
  payload: {
    id: string;
    notificationsEnabled: boolean;
  };
};

type DeleteSavedSearchRequest = {
  type: "DELETE_REQUEST";
  payload: { id: string };
};

type DeleteSavedSearchFailed = {
  type: "DELETE_FAILED";
  payload: { id: string; error: string[] };
};

type DeleteSavedSearchSuccess = {
  type: "DELETE_SUCCESS";
  payload: { id: string };
};

type FailedActions = {
  type:
    | "CHANGE_NOTIFICATIONS_ENABLED_FAILED"
    | "CHANGE_NOTIFICATION_TYPE_FAILED"
    | "CHANGE_SEARCH_NAME_FAILED"
    | "FETCH_SAVED_SEARCHES_FAILED"
    | "SAVE_SEARCH_FAILED"
    | "FETCH_SEARCH_RESULTS_FAILED";
  error: string[];
  payload?: any;
};

type FetchMoreResultsSuccessAction = {
  type: "FETCH_MORE_RESULTS_SUCCESS";
  payload: SearchResultsResponse;
};

type FetchSavedSearchSuccessAction = {
  type: "FETCH_SAVED_SEARCH_SUCCESS";
  payload: Record<string, SavedSearchApi>;
};

type SaveSearchSuccessAction = {
  type: "SAVE_SEARCH_SUCCESS";
  payload: SaveSearchResponse;
};

type FetchSearchResultsRequestAction = {
  type: "FETCH_SEARCH_RESULTS_REQUEST";
  payload: {
    excluded: Chip[];
    filters: Partial<SearchFilters>;
    radius: number;
    search: Chip[];
    tab: EventSearchType;
  };
};

type FetchSearchResultsSuccessAction = {
  type: "FETCH_SEARCH_RESULTS_SUCCESS";
  payload: SearchResultsResponse;
};

type SetNextEventsPageAction = {
  type: "SET_NEXT_EVENTS_PAGE";
  payload: { tab: EventSearchType };
};
type SetPowerBarOpen = {
  type: "SET_POWERBAR_OPEN";
  payload: { isOpen: boolean };
};

export type SetOutsideSearchAction = {
  type: "SET_OUTSIDE_SEARCH";
  payload: { search: Chip[]; excluded: Chip[] };
};

export type SearchReducerAction =
  | { type: ""; payload: any }
  | ChangeNotificationTypeSuccessAction
  | ChangeSearchNameSuccessAction
  | ChangeNotificationsEnabledSuccessAction
  | DeleteSavedSearchFailed
  | DeleteSavedSearchRequest
  | DeleteSavedSearchSuccess
  | FailedActions
  | FetchMoreResultsSuccessAction
  | FetchSavedSearchSuccessAction
  | FetchSearchResultsRequestAction
  | FetchSearchResultsSuccessAction
  | SaveSearchSuccessAction
  | SetNextEventsPageAction
  | SetOutsideSearchAction
  | SetPowerBarOpen;

export default function searchesReducer(
  state = initialState,
  action: SearchReducerAction
): Partial<SearchState> {
  let tab;

  switch (action.type) {
    case "FETCH_SEARCH_RESULTS_REQUEST": {
      const recentChipsObject: RecentSearch = {
        chips: action.payload.search || [],
        chips_exclusion: action.payload.excluded || [],
      };

      const existingSearches = (state.recentSearches || []).filter(
        (search) => !!search && !sameSearch(recentChipsObject, search)
      );

      const recentSearches = [recentChipsObject, ...existingSearches]
        .slice(0, SEARCHES.RECENT_STORED)
        .reverse();

      return {
        ...state,
        loading: true,
        error: null,
        radius: action.payload.radius || initialState.radius,
        recentSearches,
        results: initialState.results,
        filterOptions: {
          ...initialState.filterOptions,
          [action.payload.tab]: action.payload.filters,
        },
      };
    }
    case "SAVE_SEARCH_SUCCESS": {
      const key = Object.keys(action.payload)[0];
      const data = action.payload[key];
      return {
        ...state,
        loading: false,
        error: null,
        saved: [...state.saved, savedSearchSavedApiMapper(data, key)],
      };
    }
    case ACTION.SET_SEARCH_TERMS:
      return {
        ...state,
        searchTerms: action.payload.included.filter((chip: Chip) => !!chip),
        excluded: action.payload.excluded,
        radius: action.payload.radius || initialState.radius,
      };
    case "DELETE_REQUEST":
      return {
        ...state,
        loading: true,
        saved: state.saved.map((s) => ({
          ...s,
          deleting: action.payload.id === s.savedSearchUuid,
        })),
      };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        saved: state.saved.filter(
          (savedSearch) => savedSearch.savedSearchUuid !== action.payload.id
        ),
      };
    case "DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        saved: state.saved.map((savedSearch) =>
          action.payload.id === savedSearch.savedSearchUuid
            ? { ...savedSearch, deleting: false }
            : { ...savedSearch }
        ),
      };
    case "CHANGE_NOTIFICATIONS_ENABLED_SUCCESS":
      return {
        ...state,
        saved: state.saved.map((savedSearch) =>
          savedSearch.savedSearchUuid === action.payload.id
            ? {
                ...savedSearch,
                notifications: action.payload.notificationsEnabled,
              }
            : { ...savedSearch }
        ),
      };
    case "CHANGE_NOTIFICATION_TYPE_SUCCESS":
      return {
        ...state,
        saved: state.saved.map((savedSearch) =>
          savedSearch.savedSearchUuid === action.payload.id
            ? {
                ...savedSearch,
                notificationEventTypes: action.payload.notificationType,
              }
            : { ...savedSearch }
        ),
      };
    case "CHANGE_SEARCH_NAME_SUCCESS":
      return {
        ...state,
        saved: state.saved.map((savedSearch) =>
          savedSearch.savedSearchUuid === action.payload.id
            ? { ...savedSearch, name: action.payload.name }
            : { ...savedSearch }
        ),
      };
    case ACTION.UPDATE_PIPELINE_STATUS:
      newState = Object.assign({}, state);
      Object.keys(newState.results).forEach((set) => {
        if (
          newState.results[set] &&
          newState.results[set].documents &&
          newState.results[set].documents.length
        ) {
          newState.results[set].documents.map((doc) => {
            if (doc.event_id === action.payload.eventId)
              doc.is_in_pipeline = action.payload.status || false;
            return true;
          });
        }
      });

      return { ...newState };

    case "FETCH_SEARCH_RESULTS_SUCCESS": {
      return {
        ...state,
        loading: false,
        error: null,
        results: {
          ...state.results,
          [action.payload.results.tab]: {
            documents: action.payload.results.documents,
            page: 1,
            totalCount: action.payload.results.total,
            docCount: action.payload.results.count,
            totalPages: Math.ceil(action.payload.results.total / EVENTS.LIMIT),
            managers: action.payload.results.managers,
            events: action.payload.results.events,
          },
        },
        suggested: {
          ...state.suggested,
          [action.payload.results.tab]: {
            searches: action.payload.search_suggestions || [],
            events:
              (action.payload.event_suggestions &&
                action.payload.event_suggestions.results &&
                action.payload.event_suggestions.results.documents) ||
              [],
            managers:
              action.payload.event_suggestions &&
              action.payload.event_suggestions.results &&
              action.payload.event_suggestions.results.managers,
          },
        },
        outsideSearch: [],
      };
    }
    case "FETCH_MORE_RESULTS_SUCCESS":
      tab = action.payload.results.tab;
      return {
        ...state,
        loading: false,
        results: {
          ...state.results,
          [tab]: {
            ...state.results[tab],
            documents: [
              ...state.results[tab].documents,
              ...action.payload.results.documents,
            ],
            page: state.results[tab].page,
            managers: {
              ...state.results[tab].managers,
              ...action.payload.results.managers,
            },
            events: state.results[tab].events,
          },
        },
      };

    case "SET_NEXT_EVENTS_PAGE":
      tab = action.payload.tab;
      return {
        ...state,
        loading: true,
        results: {
          ...state.results,
          [tab]: {
            ...state.results[tab],
            page:
              state.results[tab].page < state.results[tab].totalPages
                ? state.results[tab].page + 1
                : state.results[tab].totalPages,
          },
        },
      };
    case "SET_POWERBAR_OPEN":
      return {
        ...state,
        isPowerBarOpen: action.payload.isOpen,
      };
    case ACTION.SET_OUTSIDE_SEARCH:
      return {
        ...state,
        outsideSearch: action.payload.search,
        searchTerms: action.payload.search,
        excluded: action.payload.excluded,
      };
    case "FETCH_SAVED_SEARCH_SUCCESS":
      return {
        ...state,
        saved: Object.values(action.payload).map((item) =>
          savedSearchApiToSavedSearchMapper(item)
        ),
      };
    case ACTION.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        results: initialState.results,
        suggested: initialState.suggested,
        filterOptions: initialState.filterOptions,
      };
    case "CHANGE_NOTIFICATIONS_ENABLED_FAILED":
    case "CHANGE_NOTIFICATION_TYPE_FAILED":
    case "CHANGE_SEARCH_NAME_FAILED":
    case "FETCH_SAVED_SEARCHES_FAILED":
    case "SAVE_SEARCH_FAILED":
    case "FETCH_SEARCH_RESULTS_FAILED":
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
