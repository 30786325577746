//@ts-nocheck
import { createSelector, Selector } from "reselect";
import getOr from "lodash/fp/getOr";
import get from "lodash/fp/get";
import jwtDecode from "jwt-decode";
import { NAMESPACE } from "lib/auth";
import { getAccessToken as getLocalAccessToken } from "lib/auth";
const authSelector = (state: GlobalState) => {
  if (!state) {
    // Only to make tests of selectors happy
    return {};
  }

  let auth = state.auth;

  if (!state.auth) {
    auth = {} as AuthState;
  }

  return auth;
};

type UserProfile = {
  name: string;
} & Auth0UserData;

export const getUser = createSelector(authSelector, (state) => {
  return get("authData", state);
});

export const getAccountData = createSelector(getUser, (state) => {
  const auth0UserData = getOr<Auth0UserData>(null, NAMESPACE)(state as any);

  const userProfile: UserProfile = {
    ...auth0UserData,
    name: state ? state.name : "",
  };
  return userProfile;
});

export const getProfileData = createSelector(getAccountData, (state) => {
  return {
    name: state ? state.name : "",
    role: state.role || "",
    company: state.company || "",
    city: state.city || "",
    phone: state.phone || "",
    linkedIn: state.linkedIn || "",
    email: state.email || "",
  };
});

export const getUserId = (state: GlobalState) => {
  const user = getUser(state);
  let userId = "guest";
  if (user) {
    userId = user.sub;
  }
  return userId;
};

export const isLinkedInUser = (state) =>
  (getUserId(state) || "").startsWith("linkedin|");

export const isLoginPreferred = createSelector(
  authSelector,
  getOr(false, "prefer_login")
);

export const getPicture = createSelector(getUser, getOr("", "picture"));

export const getRoles = createSelector(getAccountData, getOr([], "roles"));

export const getCBExpiry = createSelector(
  getAccountData,
  getOr(null, "expiry")
);

export const getUserName: Selector<GlobalState, string> = createSelector(
  getUser,
  getOr("", "name")
);

export const getPreferences = createSelector(
  authSelector,
  getOr("", "preferences")
);

export const getJobSeekerDetails = createSelector(
  authSelector,
  getOr(null, "jobseekerDetails")
);

export const getJobSeekerChips = createSelector(
  getJobSeekerDetails,
  getOr([], "formattedChips")
);

export const getNotificationFrequency = createSelector(
  getPreferences,
  getOr("", "browser_notification_frequency")
);

export const getNotificationType = createSelector(
  getPreferences,
  getOr("", "email_notification_types")
);

export const getLoginEmail = (state) => {
  return createSelector(getAccountData, getOr(null, "email"))(state);
};

export const getPreferredEmail = createSelector(
  getPreferences,
  getOr(null, "preferred_email")
);

export const getVerifiedEmails = createSelector(
  getPreferences,
  getOr([], "verified_emails")
);

export const getInterests = createSelector(
  getPreferences,
  getOr(null, "interests")
);

export const getOnboardingCompleted = createSelector(
  authSelector,
  getOr("", "onboardingCompleted")
);

export const getNotifications = createSelector(
  authSelector,
  getOr({} as AuthState["notifications"], "notifications")
);

export const getSearchNotifications = createSelector(
  getNotifications,
  getOr([], "searches")
);

export const getNotificationCount = createSelector(
  getNotifications,
  getOr(0, "count")
);

export const isLoggedIn = (state) =>
  !!getLocalAccessToken() ?? !!getUserId(state);

export const isCurrent = (state) => !getRoles(state).includes("free");

export const getIsPremium = (state) => getRoles(state).includes("premium");

export const getIsTrialExpired = (state) =>
  getRoles(state).includes("free") && !getCBExpiry(state);

export const getIsTrial = (state) =>
  getRoles(state).includes("premium") && !!getCBExpiry(state);

export const getHasRole = createSelector(
  getAccountData,
  (data) =>
    data && data.roles && data.roles.map((role) => role.length > 0).length > 0
);

export const checkIfVerified = (state) => {
  const preferredEmail = getPreferredEmail(state);
  const verifiedEmails = getVerifiedEmails(state);
  const usesLinkedIn = isLinkedInUser(state);

  return preferredEmail
    ? verifiedEmails.includes(preferredEmail)
    : usesLinkedIn || !preferredEmail;
};

// We cannot Memoize because the date is impure
const isTokenExpired = (user) => {
  return getTokenExpirationDateFromToken(user) < global.Date.now();
};

const getTokenExpirationDateFromToken = (token) =>
  token && token.exp && new global.Date(0).setUTCSeconds(token.exp);

const getTokenIssuedDateFromToken = (token) =>
  token && token.iat && new global.Date(0).setUTCSeconds(token.iat);

export const getErrors = createSelector(
  authSelector,
  getOr({} as AuthState["errors"], "errors")
);

const getAccessToken = createSelector(
  authSelector,
  getOr("", "authData.accessToken")
);

export const getAccessTokenExpiry = createSelector(getAccessToken, (state) => {
  if (!(state && state.indexOf(".") > -1)) {
    return global.Date.now();
  }
  return getTokenExpirationDateFromToken(jwtDecode(state));
});

export const getTokenAgeInMinutes = createSelector(getAccessToken, (state) => {
  if (!(state && state.indexOf(".") > -1)) {
    return 9999;
  }
  return Math.floor(
    (global.Date.now() - getTokenIssuedDateFromToken(jwtDecode(state))) / 60000
  );
});

export const getTokenAgeInSeconds = createSelector(getAccessToken, (state) => {
  if (!(state && state.indexOf(".") > -1)) {
    return 9999999;
  }
  return Math.floor(
    (global.Date.now() - getTokenIssuedDateFromToken(jwtDecode(state))) / 1000
  );
});

const getPermissionsFromToken = (token): string[] =>
  (token && token.permissions) || [];

/**
 * @deprecated
 */
export const getPermissions = createSelector(
  getAccessToken,
  (state) =>
    state &&
    state.indexOf(".") > -1 &&
    getPermissionsFromToken(jwtDecode(state))
);

export const getUserRoles = createSelector(
  getUser,
  (state) =>
    state &&
    state["https://talentticker.ai"] &&
    state["https://talentticker.ai"].roles
);
