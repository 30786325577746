import { DeckRounded } from "@mui/icons-material";
import JwtDecode from "jwt-decode";
import { PermissionKey } from "lib/features";
import { decode } from "punycode";

type LocalAuthData = {
  body: {
    access_token: string;
    audience: string;
    client_id: string;
  };
};

export const getLocalAuthData = (): LocalAuthData | null => {
  if (typeof window === "undefined") {
    return null;
  }
  const regex = /@@auth0spajs@@/g;
  const storageItem = Object.keys(localStorage).find((key) => regex.test(key));
  if (storageItem) {
    const authData = localStorage.getItem(storageItem);
    return JSON.parse(authData);
  }
  return null;
};

export const getAccessToken = (): string => {
  const localData = getLocalAuthData();
  return localData?.body?.access_token;
};
export const isAccessTokenExpired = (): boolean => {
  const token = getAccessToken();
  if (token) {
    const decoded = JwtDecode<{ exp: number }>(token);
    return decoded.exp * 1000 < Date.now();
  }
};

export const getUserId = (): string => {
  const token = getAccessToken();
  if (token) {
    const decoded = JwtDecode<{ sub: string }>(token);
    return decoded.sub;
  }
  return "guest";
};

export const NAMESPACE = "https://talentticker.ai";

export const getPermissions = (): Record<PermissionKey, true> | null => {
  const token = getAccessToken();
  if (token) {
    const decoded =
      JwtDecode<{ permissions: PermissionKey[] }>(token).permissions;
    const permissions = decoded?.reduce(
      (acc, perm) => ({ ...acc, [perm]: true }),
      {}
    ) as Record<PermissionKey, true>;
    return permissions;
  }
  return null;
};
