import { Applicability } from "./peopleSearch";
import hash from "object-hash";
import { PhoneNumberType } from "components/Sourcing/Form/ContactInformation/types";
import {
  emailQualityMap,
  phoneQualityMap,
} from "components/Sourcing/Form/ContactInformation/utils";
import { useMemo } from "react";
import { ExtendedSWRResponse, useAuthenticatedSWR } from "lib/api";
import { ContactEmailTypes } from "@talentticker/tt-utils";

export type Options = {
  // For boolean searches
  included: Chip[];
  excluded: Chip[];
  page: number;
  loggedIn: boolean;
  itemsPerPage: number;
  queryDuplicateNames: boolean;
  emailTypes: ContactEmailTypes[];
};

export type SourcingOptions = Options & {
  applicability: Applicability;
  monthsInRoleMin: number;
  monthsInRoleMax: number;
  monthsExperienceMin: number;
  monthsExperienceMax: number;
  yearsExperienceMin: number;
  yearsExperienceMax: number;
  companySizes: CompanySizeFilter[];
  seniorities: string[];
  minLeavingScore: number;
  distanceInMiles: number;
  veteransOnly: boolean;
  veteranCountryLocationId: number;
  specialistFilterGender: boolean;
  phoneNumberType: PhoneNumberType[];
  phoneNumberScore: number;
  emailScore: number;
};

/**
 * @param options type Options
 * @param options.featureFlag - this is a temporary param to flag the new cognism feature -- will be removed once all approved
 */
function isTalentSourcingOptions(
  options: Options | SourcingOptions
): options is SourcingOptions {
  return (options as SourcingOptions).applicability !== undefined;
}

type PeopleSearchResult = { results: PeopleSearchResponse };

type UsePeopleSearch = ExtendedSWRResponse<PeopleSearchResult> & {
  count: number;
  contacts: Contact[];
  total: number;
};

export function usePeopleSearch(
  options: Options | SourcingOptions,
  fetch?: boolean
): UsePeopleSearch {
  const endpoint = `v5-powersearch/people-search`;

  const { params, hashed, url } = useMemo(() => {
    const input = options.included.filter(
      (option) =>
        options.excluded.findIndex((o) => o.es_id === option.es_id) === -1
    );

    const includedInput = input.filter(
      (chip) => chip.id && chip?.boolean_operator !== "not"
    );
    const excludedInput = input.filter(
      (chip) => chip.id && chip?.boolean_operator === "not"
    );
    const params = isTalentSourcingOptions(options)
      ? {
          applicability: options.applicability,
          exclusion: excludedInput,
          input: includedInput,
          company_sizes: options.companySizes,
          // Multiple by because label shows "year" to user
          months_exp_min:
            typeof options.monthsExperienceMin === "number"
              ? options.monthsExperienceMin * 12
              : options.monthsExperienceMin,
          months_exp_max:
            typeof options.monthsExperienceMax === "number"
              ? options.monthsExperienceMax * 12
              : options.monthsExperienceMax,
          months_in_role_min: options.monthsInRoleMin,
          months_in_role_max: options.monthsInRoleMax,
          seniorities: options.seniorities,
          min_leaving_score: options.minLeavingScore,
          distance_in_miles: options.distanceInMiles,
          veterans_only: options.veteransOnly,
          veteran_country_location_id: options.veteranCountryLocationId,
          specialist_filter_gender: options.specialistFilterGender,
          phone_number_type: options.phoneNumberType,
          // Only filter phone number score when phone number type selected
          phone_number_score:
            options.phoneNumberType?.length > 0
              ? phoneQualityMap[options.phoneNumberScore]
              : 0,
          email_score: options.emailScore
            ? Number(emailQualityMap[options.emailScore])
            : 0,
          query_duplicate_names: options.queryDuplicateNames,
          email_types: options.emailTypes,
          email_limit: 4, //default value.. guess there will be frontend logic to change this
        }
      : {
          exclusion: options.excluded,
          input: input,
          free_text_fields: ["name", "job_title"],
          query_duplicate_names: options.queryDuplicateNames,
        };

    const query = `limit=${options.itemsPerPage}&skip=${
      options.itemsPerPage * (options.page - 1)
    }`;
    const url = `/${endpoint}?${query}`;

    return {
      params,
      hashed: hash(options),
      url,
    };
  }, [options, endpoint]);

  /**
   * Flag to decide whether to make network request for people
   */
  const shouldFetch =
    options.loggedIn && (typeof fetch === "undefined" ? true : fetch);

  const res = useAuthenticatedSWR<PeopleSearchResult>({
    url,
    key: `${endpoint}${hashed}`,
    condition: shouldFetch,
    payload: params,
    method: "POST",
    swrConfig: {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  });

  const { count, documents, total } = res.data?.data?.results || {};
  return {
    ...res,
    count: count || 0,
    contacts: documents || [],
    total: total || 0,
  };
}
