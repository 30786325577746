import { ACTION } from "./constants";

export function fetchStats(userId, organisation) {
  return { type: ACTION.FETCH_STATS, userId, organisation };
}

export function fetchStatsSuccess(payload) {
  return { type: ACTION.FETCH_STATS_SUCCESS, payload };
}

export function fetchStatsFailed(error) {
  return { type: ACTION.FETCH_STATS_FAILED, error };
}

export function exportCsv(userId) {
  return { type: ACTION.EXPORT_CSV, userId };
}

export function exportCsvSuccess(payload) {
  return { type: ACTION.EXPORT_CSV_SUCCESS, payload };
}

export function exportCsvFailed(error) {
  return { type: ACTION.EXPORT_CSV_FAILED, error };
}

export function exportCsvClear() {
  return { type: ACTION.EXPORT_CSV_CLEAR };
}
