import { css } from "@emotion/react";
import styled from "@emotion/styled";

type StyleProps = {
  size?: string;
  primary?: boolean;
  secondary?: boolean;
  height?: "small";
  width?: string;
  fullWidth?: boolean;
  whitespace?: string;
  outlined?: boolean;
  backgroundColor?: string;
  noUnderline?: boolean;
  smCircle?: boolean;
  textButton?: boolean;
};

export const StyledAButton = styled.a<StyleProps>(
  ({
    theme,
    size,
    outlined,
    backgroundColor,
    height,
    width,
    secondary,
    smCircle,
    noUnderline,
    fullWidth,
    whitespace,
  }) => css`
    align-items: center;
    border-radius: ${size === "large" ? "40" : "20"}px;
    border: ${outlined ? " 2px solid #000" : "transparent"};
    background: ${outlined
      ? "transparent"
      : secondary
      ? "var(--bg-dark-alt)"
      : backgroundColor || theme.colors.highlight};
    color: ${outlined ? "#000" : "var(--white)"};
    cursor: pointer;
    display: inline-flex;
    font-size: ${size === "small" ? "16" : "18"}px;
    font-weight: 500;
    height: ${size === "large" ? "55" : height === "small" ? "24" : "38"}px;
    justify-content: center;
    line-height: 1.61;
    min-width: ${width || "144px"};
    padding: 0 20px;
    text-decoration: none;
    text-transform: capitalize;
    width: ${fullWidth ? "100%" : width || "auto"};
    white-space: ${whitespace || "auto"};

    &:visited {
      color: ${outlined ? "#000" : "var(--white)"};
    }

    &:hover {
      color: ${outlined ? "#000" : "var(--white)"};
      text-decoration: ${!noUnderline && outlined ? "underline" : "none"};
      background-color: ${outlined
        ? "transparent"
        : secondary
        ? "var(--secondary-hover)"
        : theme.colors.highlightHover};
    }

    &:active {
      background-color: ${outlined
        ? "transparent"
        : secondary
        ? "var(--bg-dark)"
        : theme.colors.highlightActive};
    }

    &.disabled,
    &:disabled {
      cursor: default;
      color: rgba(255, 255, 255, 0.5);
      opacity: 0.3;
    }

    @media ${theme.breakpoints.mobile} {
      ${smCircle &&
      `
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 100%;
        padding: 0;
      `}
    }
  `
);

export const StyledButton = styled.button<StyleProps>(
  ({
    theme,
    size,
    outlined,
    backgroundColor,
    textButton,
    height,
    width,
    secondary,
    smCircle,
    noUnderline,
    fullWidth,
    whitespace,
  }) => css`
    align-items: center;
    position: relative;
    border-radius: ${size === "large" ? "40" : "20"}px;
    border: ${outlined ? "2px solid #000" : "transparent"};
    background: ${outlined || textButton
      ? "transparent"
      : secondary
      ? "var(--bg-dark-alt)"
      : backgroundColor || theme.colors.highlight};
    color: ${textButton ? "#2430c6" : outlined ? "#000" : "var(--white)"};
    cursor: pointer;
    display: inline-flex;
    font-size: ${size === "small" ? "16" : "18"}px;
    font-weight: 500;
    height: ${size === "large" ? "55" : height === "small" ? "24" : "38"}px;
    justify-content: center;
    line-height: 1.61;
    min-width: ${width || "144px"};
    padding: 0 20px;
    text-decoration: none;
    text-transform: capitalize;
    width: ${fullWidth ? "100%" : width || "auto"};
    white-space: ${whitespace || "auto"};

    &:hover {
      color: ${textButton ? "#2430c6" : outlined ? "inherit" : "var(--white)"};
      text-decoration: ${textButton || (!noUnderline && outlined)
        ? "underline"
        : "none"};
      background-color: ${outlined || textButton
        ? "transparent"
        : secondary
        ? "var(--secondary-hover)"
        : backgroundColor || theme.colors.highlightHover};
    }

    &:active {
      background-color: ${outlined || textButton
        ? "transparent"
        : secondary
        ? "var(--bg-dark)"
        : theme.colors.highlightActive};
    }

    &.disabled,
    &:disabled {
      cursor: default;
      color: rgba(255, 255, 255, 0.5);
      opacity: 0.3;
    }

    .MuiCircularProgress-colorPrimary {
      color: #fff !important;
    }
    @media ${theme.breakpoints.mobile} {
      ${smCircle &&
      `
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 100%;
        padding: 0;
      `}
    }
  `
);

export const Icon = styled.img<{ size?: string }>(
  (props) => css`
    width: ${props.size === "small" ? "16" : "18"}px;
    height: ${props.size === "small" ? "16" : "18"}px;
    margin-left: 10px;
  `
);
