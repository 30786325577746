import {
  getCarouselEpic,
  getLatestVacanciesEpic,
  getBreakingNewsEpic,
  getLatestNewsEpic,
  getHiringTrendsEpic,
  getBlogPostsEpic,
  getSavedSearchesEpic,
} from "./landing/epic";

import {
  getSeatsEpic,
  vacateSeatEpic,
  vacateSeatConfirmEpic,
  finallyVacateSeatEpic,
  fetchPaymentSourceEpic,
  deleteUserFromSeatEpic,
  setOrgNameEpic,
} from "./seats/epic";
import { getStatsEpic, exportCsvEpic } from "./stats/epic";

export default [
  getCarouselEpic,
  getLatestVacanciesEpic,
  getBreakingNewsEpic,
  getLatestNewsEpic,
  getHiringTrendsEpic,
  getBlogPostsEpic,
  getSeatsEpic,
  vacateSeatEpic,
  vacateSeatConfirmEpic,
  finallyVacateSeatEpic,
  getSavedSearchesEpic,
  fetchPaymentSourceEpic,
  deleteUserFromSeatEpic,
  setOrgNameEpic,
  getStatsEpic,
  exportCsvEpic,
];
