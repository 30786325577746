import { useState, createContext } from "react";
import { getOrganizationSeats } from "containers/OrgMemberContainer/fetcher";
import { OrganisationStatus } from "organisation-context";

export type Seat = {
  assigned_user_id: null;
  current_term_end: string;
  customer_id: string;
  id: string;
  invitation_token: string;
  last_billed: string;
  last_event_id: string;
  modified_date: string;
  organisation_id: string;
  organisation_name: string;
  owner_id: string;
  plan_id: string;
  status: OrganisationStatus;
  subscription_id: string;
  transaction_id: string;
};

type OrganisationSeatState = {
  assignedSeats: ReturnTypeAsync<typeof getOrganizationSeats>["assigned_users"];
  freeSeats: Seat[];
  owners: string[];
  emptySeatId: string;
};

export type OrganisationSeatContextType = {
  state: OrganisationSeatState;
  setState: React.Dispatch<
    React.SetStateAction<Partial<OrganisationSeatState>>
  >;
};

const initialState = {
  assignedSeats: [],
  freeSeats: [],
  owners: [],
  emptySeatId: "",
};
export const OrganisationSeatContext =
  createContext<OrganisationSeatContextType>({
    state: initialState,
    setState: undefined,
  });

export const OrganisationSeatContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [state, setState] = useState(initialState);

  return (
    <OrganisationSeatContext.Provider value={{ state, setState }}>
      {children}
    </OrganisationSeatContext.Provider>
  );
};
