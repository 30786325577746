export type PhoneNumberType =
  | "All"
  | "DIRECT_DIAL"
  | "MOBILE"
  | "COMPANY_SWITCHBOARD";

export type Email = "Work" | "Personal";

export type PhoneType = {
  label: PhoneNumberType;
  selected: boolean;
};

// Scores won't be changed frequently, so we think it's not worth to
// store in an env file after discussion
export enum PhoneNumberScores {
  Medium = 20,
  High = 60,
  VeryHigh = 69,
}

export enum EmailScores {
  Medium = 45,
  High = 80,
  VeryHigh = 86,
}
