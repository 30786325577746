import { ofType } from "redux-observable";
import { ACTION } from "./constants";
import { getState } from "store/index";
import { ajax } from "rxjs/ajax";
import { map, mergeMap, takeUntil, delay, catchError } from "rxjs/operators";
import { requestUrl } from "utils/api-request";
import { getAccessToken } from "lib/auth";
import { forkJoin, of } from "rxjs";
import {
  fetchSeatsSuccess,
  fetchSeatsFailed,
  fetchPaymentSourceSuccess,
  fetchPaymentSourceFailed,
  cancelVacateSeat,
  deleteUserFromSeatFailed,
  setOrgNameSuccess,
  setOrgNameFailed,
  startUpgrading,
} from "./actions";
import {
  addSnackbarMessage,
  removeSnackbarMessage,
} from "store/notices/actions";

const handleFetchSeatsError = (error) => of(fetchSeatsFailed(error));
const handleFetchPaymentError = (error) => of(fetchPaymentSourceFailed(error));
const handleDeleteUserFromSeatError = (error) =>
  of(deleteUserFromSeatFailed(error));
const handleSetOrgNameError = (error) => of(setOrgNameFailed(error));

const mapUsersOntoSeats = (seats, users, teamName) => {
  const token = getAccessToken();
  return {
    assigned: seats.data.assigned,
    own: seats.data.own.map((own) => ({
      ...own,
      user: users.data.assigned_users.find(
        (user) => user.user_id === own.assigned_user_id
      ),
    })),
    orgName: teamName.data.team_name,
    accessToken: token,
  };
};

export const getSeatsEpic = (action$) =>
  action$.pipe(
    ofType(ACTION.FETCH_SEATS),
    delay(1000),
    mergeMap((action) => {
      const token = getAccessToken();
      const getSeatsAjax = ajax.getJSON(
        requestUrl({
          service: "v2-seats",
          action: encodeURIComponent(action.userId),
          query: action.organisation && `org_id=${action.organisation.id}`,
        }),
        { Authorization: `Bearer ${token}` }
      );
      return forkJoin(getSeatsAjax, mapUsersOntoSeats).pipe(
        map(fetchSeatsSuccess),
        catchError(handleFetchSeatsError)
      );
    })
  );

export const vacateSeatConfirmEpic = (action$) =>
  action$.pipe(
    ofType(ACTION.VACATE_SEAT),
    map((action) =>
      action.payload.assignedUserId
        ? addSnackbarMessage({
            message: "Team member removed",
            undoAction: cancelVacateSeat(action.payload),
          })
        : { type: "EMPTY" }
    )
  );

export const vacateSeatEpic = (action$) =>
  action$.pipe(
    ofType(ACTION.VACATE_SEAT),
    delay(1000),
    map((action) =>
      action.payload.assignedUserId
        ? { type: ACTION.VACATE_SEAT_CONFIRMED, ...action.payload }
        : { type: "EMPTY" }
    ),
    takeUntil(action$.pipe(ofType(ACTION.CANCEL_VACATE_SEAT)))
  );

export const finallyVacateSeatEpic = (action$) =>
  action$.pipe(
    ofType(ACTION.VACATE_SEAT_CONFIRMED),
    mergeMap((action) => {
      const token = getAccessToken();
      return ajax
        .delete(
          requestUrl({
            service: "v2-seats",
            action: `${encodeURIComponent(
              action.userId
            )}/assigned_user/${encodeURIComponent(action.assignedUserId)}`,
            query: action.organisation ? `org_name=${action.organisation}` : ``,
          }),
          { Authorization: `Bearer ${token}` }
        )
        .pipe(mergeMap(() => [removeSnackbarMessage("Team member removed")]));
    })
  );

const writeAddress = (address) =>
  `${address.line1 ? `${address.line1}, ` : ""}${
    address.city ? `${address.city}, ` : ""
  }${address.state_code ? `${address.state_code}, ` : ""}${
    address.country || ""
  }`;

// TODO: This had a bug in it that I found during other stuff. I need to investigate if this is being used
export const fetchPaymentSourceEpic = (action$) =>
  action$.pipe(
    ofType(ACTION.FETCH_PAYMENT_SOURCE),
    mergeMap((action) => {
      const token = getAccessToken();
      return ajax
        .getJSON(
          requestUrl({
            service: "chargebee",
            action: encodeURIComponent(action.userId),
            guest: false,
          }),
          { Authorization: `Bearer ${token}` }
        )
        .pipe(
          map((json) =>
            fetchPaymentSourceSuccess({
              card: {
                cardNumber: json.data.card.masked_number,
                expiryDate: `${json.data.card.expiry_month}/${json.data.card.expiry_year}`,
              },
              address: writeAddress(json.data.customer.billing_address),
            })
          )
        );
    }),
    catchError(handleFetchPaymentError)
  );

export const deleteUserFromSeatEpic = (action$) =>
  action$.pipe(
    ofType(ACTION.DELETE_USER_FROM_SEAT),
    mergeMap((action) => {
      const token = getAccessToken();
      return ajax
        .delete(
          requestUrl({
            service: "v2-seats",
            action: encodeURIComponent(action.userId),
          }),
          { Authorization: `Bearer ${token}` }
        )
        .pipe(
          mergeMap(() => [addSnackbarMessage("Your account has been closed")])
        );
    }),
    catchError(handleDeleteUserFromSeatError)
  );

export const setOrgNameEpic = (action$) =>
  action$.pipe(
    ofType(ACTION.SET_ORG_NAME),
    mergeMap((action) => {
      const token = getAccessToken();
      return ajax
        .post(
          requestUrl({
            service: "v2-seats",
            action: `${encodeURIComponent(action.payload.userId)}/team_name`,
          }),
          JSON.stringify({
            team_name: action.payload.orgName,
          }),
          { Authorization: `Bearer ${token}` }
        )
        .pipe(
          map((json) => {
            return setOrgNameSuccess({
              orgName: json.response.data.team_name,
            });
          })
        );
    }),
    catchError(handleSetOrgNameError)
  );
