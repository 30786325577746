import { createStore, combineReducers, applyMiddleware } from "redux";
import { ajax } from "rxjs/ajax";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import thunk from "redux-thunk";

import reducers from "./reducers";
import epics from "./epics";
import { loadState, saveState } from "./localStorage";
import { websocketMiddleware } from "./websocketMiddleware";

import { composeWithDevTools as compose } from "redux-devtools-extension/developmentOnly";

// Used to invalidate local storage for the next load.
// Only to be used if there is major changes that would mean
// broken functionality between deployments since it force clears
// auth, recent tickers and so on for ALL users.
const STORAGE_TOKEN = "a2a5433c-bc7f-4a48-96b0-b5e4c463ff82";

let store;

export function initializeStore() {
  const epicMiddleware = createEpicMiddleware({
    dependencies: { ajax, getJSON: ajax.getJSON, post: ajax.post },
  });
  const middleware = [thunk, epicMiddleware, websocketMiddleware];
  const initialState = loadState();
  const validatedState =
    initialState &&
    initialState.storageToken &&
    initialState.storageToken === STORAGE_TOKEN
      ? initialState
      : {};
  store = createStore(
    combineReducers(reducers),
    validatedState,
    compose(applyMiddleware(...middleware))
  );

  epicMiddleware.run(combineEpics(...epics));

  store.subscribe(() => {
    const state = store.getState();
    // if (!state.ticker.loading) {
    const { ticker, ...oldState } = state;
    const noEvents = ticker.companyData.map((company) => ({
      ...company,
      events: [],
      positions: [],
    }));
    const filteredCompanyData = noEvents.filter(
      (company) => company.company_id
    );
    const refinedState = {
      ...oldState,
      ticker: { ...ticker, companyData: filteredCompanyData },
    };

    // We don't want to persist storage that
    // is the middle of waiting for an asynchronous
    //  action
    saveState({
      ticker: refinedState.ticker,
      auth: {
        ...refinedState.auth,
        metadataLoaded: false,
      },
      notices: refinedState.notices,
      storageToken: STORAGE_TOKEN,
      searches: refinedState.searches,
      seats: refinedState.seats,
    });
    // }
  });
  return store;
}

export function getState() {
  return store && store.getState();
}

export default store;
