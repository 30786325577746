import { ACTION } from "./constants";

export const initialState = {
  columns: {},
  loading: true,
  current: {
    loading: true,
    data: {},
  },
};

export default function pipelineReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.GET_PIPELINE:
    case ACTION.ADD_TASK:
    case ACTION.ADD_TASK_FAILED:
      return {
        ...state,
        loading: true,
      };
    case ACTION.GET_PIPELINE_SUCCESS:
      return {
        ...state,
        columns: action.payload.columns,
        loading: false,
      };
    case ACTION.ADD_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION.GET_PIPELINE_RECORD:
      return {
        ...state,
        current: initialState.current,
      };
    case ACTION.GET_PIPELINE_RECORD_SUCCESS:
      return {
        ...state,
        current: {
          loading: false,
          data: action.payload,
        },
      };
    default:
      return state;
  }
}
