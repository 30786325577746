/* global Sentry */
/**
 *
 * Error Boundary
 *
 */

import { Component } from "react";
import * as Sentry from "@sentry/browser";
import Button from "components/atoms/Button";

export const Fallback = () => {
  const handleRefresh = () => {
    localStorage.clear();
    document.location.href = "/";
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: 1280,
            padding: "120px 0",
            position: "relative",
            borderBottom: "1px solid #ededed",
          }}
        >
          <svg width="106.633" height="80.633" viewBox="0 0 106.633 80.633">
            <g
              id="Group_5776"
              data-name="Group 5776"
              transform="translate(-632 -301.367)"
            >
              <g
                id="Group_5766"
                data-name="Group 5766"
                transform="translate(-9127 19694.502)"
              >
                <g
                  id="noun_Laptop_486117"
                  transform="translate(9759 -19362.182)"
                >
                  <g id="Group_5767" data-name="Group 5767">
                    <g id="Group_5766-2" data-name="Group 5766">
                      <path
                        id="Path_10359"
                        d="M6.922 12.975v36.277l21.318.038h1.152a3.157 3.157 0 0 1 .4-.038 3.2 3.2 0 0 1 1.289.25q.082.036.163.075l.088.038.038.013c.081.047.161.1.238.15a3.205 3.205 0 0 1 1.527 2.7v.013H58.6a3.331 3.331 0 0 1 3.518-3.2h23.1l-.475-36.314zM1.864 53.869a4.792 4.792 0 0 1-.776.125c-.026.477-.039.76-.088 1.49V60.4h89.734v-6.41H65.108a3.2 3.2 0 0 1-3.217 3.2H29.843a3.205 3.205 0 0 1-3.2-3.2H2.815a1.535 1.535 0 0 0-.951-.121z"
                        style={{ fill: "#24252b" }}
                        data-name="Path 10359"
                        transform="translate(-1 -12.975)"
                      />
                    </g>
                  </g>
                </g>
                <rect
                  id="Rectangle_4172"
                  width="78"
                  height="42"
                  style={{ fill: "#24252b" }}
                  data-name="Rectangle 4172"
                  rx="4"
                  transform="translate(9765 -19376.502)"
                />
                <rect
                  id="Rectangle_4173"
                  width="89.697"
                  height="4.5"
                  style={{ fill: "#24252b" }}
                  data-name="Rectangle 4173"
                  rx="2.25"
                  transform="translate(9759 -19317.002)"
                />
              </g>
              <g
                id="Group_5768"
                data-name="Group 5768"
                transform="translate(695.514 306.367)"
              >
                <g
                  id="Path_10358"
                  style={{ fill: "#24252b" }}
                  data-name="Path 10358"
                >
                  <path
                    d="M39.415 43.12H3.704A3.684 3.684 0 0 1 0 39.414V3.704A3.684 3.684 0 0 1 3.704 0h35.711a3.708 3.708 0 0 1 3.704 3.704v35.711a3.684 3.684 0 0 1-3.704 3.704z"
                    style={{ stroke: "none" }}
                    transform="translate(-2.5 -2.5)"
                  />
                  <path
                    d="M3.704 2.5c-.682 0-1.204.522-1.204 1.204v35.711c0 .683.522 1.204 1.204 1.204h35.711c.683 0 1.204-.521 1.204-1.204V3.704c0-.642-.521-1.204-1.204-1.204H3.704M21.56 18.149l6.018-6.019a.85.85 0 0 1 .602-.24c.221 0 .442.08.602.24l2.207 2.207a.873.873 0 0 1 0 1.204L24.97 21.56l6.019 6.018a.873.873 0 0 1 0 1.204l-2.207 2.207a.85.85 0 0 1-.602.24.85.85 0 0 1-.602-.24L21.56 24.97l-6.02 6.019a.85.85 0 0 1-.601.24.85.85 0 0 1-.602-.24l-2.207-2.207a.873.873 0 0 1 0-1.204l6.019-6.018-6.019-6.02a.873.873 0 0 1 0-1.203l2.207-2.207a.85.85 0 0 1 .602-.24c.22 0 .441.08.602.24l6.019 6.019M3.704-2.5h35.711a6.21 6.21 0 0 1 6.204 6.204v35.711a6.168 6.168 0 0 1-1.81 4.394 6.168 6.168 0 0 1-4.394 1.81H3.704A6.168 6.168 0 0 1-.69 43.81 6.168 6.168 0 0 1-2.5 39.415V3.704c0-1.666.643-3.226 1.81-4.393A6.168 6.168 0 0 1 3.705-2.5z"
                    style={{ stroke: "none", fill: "#fff" }}
                    transform="translate(-2.5 -2.5)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div
          style={{
            textAlign: "center",
            margin: "40px 0",
            fontFamily: "'Source Sans Pro', sans-serif",
            fontSize: "20px",
            lineHeight: 1.4,
          }}
        >
          <h1
            style={{
              fontFamily: "'Source Sans Pro', sans-serif",
              fontWeight: 300,
              fontSize: 36,
              textAlign: "center",
              margin: "41px 0",
              left: 0,
              right: 0,
            }}
          >
            Something went wrong.
          </h1>
          Our engineers are aware
          <br />
          And working on a fix.
        </div>
        <Button
          style={{
            marginBottom: "40px",
          }}
          secondary
          onClick={handleRefresh}
        >
          Reset Session
        </Button>
        <br />
      </div>
    </div>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidCatch(error, info) {
    this.setState({ error });
    Sentry &&
      Sentry.withScope((scope) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key]);
        });
        Sentry.captureException(error, {
          level: "critical",
        });
      });
  }

  render() {
    return this.state.error ? <Fallback /> : this.props.children;
  }
}

export default ErrorBoundary;
