import { ACTION } from "./constants";

export const initialState = {
  carousel: {
    loading: true,
    results: [],
  },
  latestVacancies: {
    loading: true,
    results: [],
  },
  breakingNews: {
    loading: true,
    results: null,
  },
  latestNews: {
    loading: true,
    results: [],
  },
  fromtt: {
    loading: true,
    posts: [],
  },
  savedSearches: {
    results: {},
  },
  trends: {
    loading: true,
    companies: [],
  },
};

export default function landingReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.GET_CAROUSEL_SUCCESS:
      return {
        ...state,
        carousel: {
          loading: false,
          results: action.payload.results && [
            ...action.payload.results.documents,
          ],
          isLimited:
            action.payload.results &&
            action.payload.results.limited_interest_results,
        },
      };
    case ACTION.GET_LATEST_VACANCIES_SUCCESS:
      return {
        ...state,
        latestVacancies: {
          loading: false,
          results:
            action.payload.results &&
            [...action.payload.results.documents].slice(0, 4),
          isLimited:
            action.payload.results &&
            action.payload.results.limited_interest_results,
        },
      };
    case ACTION.GET_BREAKING_NEWS_SUCCESS:
      return {
        ...state,
        breakingNews: {
          loading: false,
          results:
            action.payload.results &&
            action.payload.results.documents &&
            action.payload.results.documents[0],
        },
      };
    case ACTION.GET_LATEST_NEWS_SUCCESS:
      return {
        ...state,
        latestNews: {
          loading: false,
          results: action.payload._recommended
            ? action.payload.data.map((result) => result._source)
            : action.payload.data.results && [
                ...action.payload.data.results.documents,
              ],
          isLimited:
            action.payload.results &&
            action.payload.results.limited_interest_results,
        },
      };
    case ACTION.GET_TRENDS_SUCCESS:
      return {
        ...state,
        trends: {
          companies: Object.values(action.payload),
          loading: false,
        },
      };
    case ACTION.GET_SAVED_SEARCH_SUCCESS: {
      const returnObject = {
        ...state,
        savedSearches: {
          results: {
            ...state.savedSearches.results,
          },
        },
      };
      const data = {
        loading: false,
        results: action.payload &&
          action.payload.data &&
          action.payload.data.results && [
            ...action.payload.data.results.documents,
          ],
      };
      returnObject.savedSearches.results[`${action.payload.id}`] = data;
      return returnObject;
    }
    case ACTION.GET_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        fromtt: {
          loading: false,
          posts: [...action.payload],
        },
      };
    default:
      return state;
  }
}
