import { usePermissions } from "lib/features";
import { useContext } from "react";
import { UserContext, UserContextType } from "./context";

type UseUserResponse = UserContextType & {
  loggedIn: boolean;
};
export const useUser = (): UseUserResponse => {
  const { loggedIn } = usePermissions();
  const context = useContext(UserContext);
  return {
    ...context,
    loggedIn,
  };
};
