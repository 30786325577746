import { ACTION } from "./constants";
export const initialState = {
  tickerData: [],
  companyData: [],
  loading: false,
  loadingTicker: false,
  timestamp: 0,
};

export default function tickerReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.LOADING:
      return { ...state, loading: true };
    case ACTION.LOADING_TICKER:
      return {
        ...state,
        loadingTicker: true,
        tickerData: initialState.tickerData,
      };
    case ACTION.UPDATE_TICKER:
      return {
        ...state,
        tickerData: action.data,
        loadingTicker: false,
        timestamp: global.Date.now(),
      };
    case ACTION.EXPIRE_TICKER:
      return {
        ...state,
        timestamp: 0,
      };
    default:
      return state;
  }
}
