import { useState } from "react";
import { createContext } from "react";

export type BookDemoModalContextType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialContext = {
  open: false,
  setOpen: undefined,
};

export const BookDemoModalContext = createContext<BookDemoModalContextType>(
  initialContext
);

export const BookDemoModalContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  return (
    <BookDemoModalContext.Provider value={{ open, setOpen }}>
      {children}
    </BookDemoModalContext.Provider>
  );
};
