import { FC, useEffect } from "react";
import {
  AUTH0_AUDIENCE,
  AUTH0_CALLBACK_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  isDev,
} from "env";

import { Auth0Provider } from "@auth0/auth0-react";
import { initializeAuth0 } from "auth0";

const Auth0Setup: FC = ({ children }) => {
  useEffect(() => {
    initializeAuth0(); // keep this for legacy login support for the time being.. exposes auth0 methods to the window.
  }, []);

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      useRefreshTokens
      cacheLocation="localstorage"
      redirectUri={
        isDev && typeof window !== "undefined"
          ? `${window.location.origin}/callback`
          : AUTH0_CALLBACK_URL
      }
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0Setup;
