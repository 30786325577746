import { requestUrl } from "api/apiUtil";
import { request } from "api/index";
import { requestConfig } from "api/powersearch/PowerbarApi";

export const fetchPeopleSuggestions = async (
  input: string,
  filter: ChipType | ChipType[]
): Promise<{ data: { all: Chip[] } }> => {
  return request(requestConfig.getPowerbarSuggested(input, filter));
};

export const fetchJobTitleSuggestions = async (
  input: string
): Promise<Chip[]> => {
  if (!input || input.length < 3) {
    return [];
  }
  const { data } = await request({
    url: requestUrl({
      service: "v5-powersearch",
      action: "job-title-suggest",
    }),
    options: {
      method: "POST",
      body: JSON.stringify({
        input,
        limit: 20,
      }),
    },
    auth: true,
  });
  return Array.isArray(data)
    ? data.map((item) => ({
        ...item,
        name: item.title,
      }))
    : [];
};
