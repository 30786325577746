import { globalDark, sharedTheme } from "./shared";
import { THEME as currentTheme } from "../env";
import { Theme as MaterialTheme } from "@mui/material";
import { useMemo } from "react";

export const shared = {
  fontPrimary: "#292929",
  fontSecondary: "#a3a4aa",
  fontDark: "#0d0d0d",
  fontLight: "#fff",
  white: "#f8f8f8",
  tertiary: "#5747FF",
  homeHighlight: "#ff003b",
  quoteMark: "#e4513f",
  activityBadge: "#e6e6e6",
  error: "#B00020",
  successStatus: "#23ba74",
  midGrey: "#706e6b",
};

const selligence = {
  primary: "#619CE8",
  primary_rgb: "97, 156, 232",
  secondary: "#242D4E",
  secondary_rgb: "36, 45, 78",
  white: "#f9f9f9",
  white_rgb: "249, 249, 249",
  // more specific colour palette
  primary0: "#F5F9FF",
  primary1: "#E3EFFF",
  primary2: "#C5DDFD",
  primary3: "#95BDF0",
  primary4: "#6D9CE2",
  primary5: "#144D96",
  primary6: "#242D4E",
  red: "#DB1C1C",
  green: "#2AB500",
  tertiary: "#F42CAE",
};

export const talentticker = {
  primary: "#f54906",
  primary_rgb: "245, 73, 6",
  secondary: "#24252a",
  secondary_rgb: "36, 37, 42",
  white: shared.white,
  green: "#2AB50F",
  tertiary: shared.tertiary,
};

const tableColors = {
  included: "#7aeba8",
};

export const sequencingColors = {
  email: "#39BA74",
  linkedIn: "#3076B0",
  phone: "#F9B52A",
  general: "#646464",
} as const;

export const priorityColors = {
  high: "#ff6e6e",
  medium: "#ffcf74",
  low: "#95e279",
} as const;

const sourcingColors = {
  starIcon: "#ffdc4b",
  purple: "#6159fc",
  selected: "#FFF6F5",
  companyName: "#2430c6",
  available: "#00c87b",
  unsure: "#ffc076",
  notAvailable: "#959595",
  infoBackground: "#f8f8f8",
};

export const theme = {
  selligence: {
    meta: {
      title: "Selligence - Pre-Sales Intelligence to Optimise Your Activity",
      description:
        "We streamline your sales process by ensuring you only start a process with the most relevant and profitable opportunities ",
      productName: "Selligence",
    },
    content: {
      overlayMessage:
        "Selligence's unique offering predicts the spending movements of an organisation. By searching through the worldʼs news and social media platforms to uncover key events predictive of growth.",
      overlayHeading: "Use unique insight to drive new business",
      projectedBanner: "View the functions projected to spend.",
      firingChipName: "Divesting",
      hiringChipName: "Investing",
      trendsTabName: "Investing/Divesting",
      rolesTabName: "Functions",
      searchPlaceholder: "function",
      sourcingIndividuals: "prospects",
      vacanciesName: "Job Postings",
      vacancyName: "Job Posting",
    },
    colors: {
      ...selligence,
      primaryActive: selligence.primary,
      primaryHover: selligence.primary,
      secondaryHover: "#5f6171",
      tertiary: selligence.tertiary,
      lightGrey: "#ededed",
      slateGrey: "#5f6379",
      fontPrimary: shared.fontPrimary,
      fontSecondary: shared.fontSecondary,
      fontLight: shared.fontLight,
      fontDark: shared.fontDark,
      midGrey: shared.midGrey,
      pipelineButtonPrimary: selligence.secondary,
      pipelineButtonSecondary: selligence.primary,
      highlight: selligence.primary,
      highlightHover: selligence.primary,
      highlightActive: selligence.primary,
      highlightAlt: selligence.primary,
      sidebarHover: selligence.secondary,
      background: {
        default: "#f9f9f9",
        light: "#f5f7f8",
      },
      outbox: {
        tabBackground: selligence.primary2,
        tabText: shared.fontPrimary,
        stepActive: selligence.secondary,
      },
      sidebarHoverBackground: selligence.white,
      cloudyBlue: "#c9d2da",
      cloudyGrey: "#a7a8ae",
      resultControls: {
        background: selligence.secondary,
        foreground: "var(--bg-dark)",
      },
      table: { ...tableColors, exluded: selligence.secondary },
      header: {
        primary: selligence.primary,
        secondary: "#253b56",
      },
      sequencingColors,
      modal: {
        primaryBackground: "#f4fffd",
        confirmButton: selligence.secondary,
      },
      sideNav: {
        background: {
          selected: selligence.secondary,
          default: "transparent",
          mobile: selligence.primary,
        },
        text: {
          disabled: shared.fontSecondary,
          selected: selligence.white,
          selectedHover: selligence.primary,
          default: shared.fontPrimary,
          mobileSHover: selligence.secondary,
          mobileDefaultHoover: selligence.primary,
        },
      },
      chip: {
        secondary: selligence.secondary,
        icon: selligence.primary3,
      },
      popup: {
        icon: selligence.tertiary,
      },
      contact: {
        icon: selligence.secondary,
        iconDisabled: "#e6e6e6",
      },
      tooltip: {
        warningIcon: selligence.tertiary,
      },
      activityBadge: shared.activityBadge,
      addToPipeline: { primary: selligence.primary, secondary: "#A3A4AA" },
      contactsPowerbarChip: " #364658",
      homeHighlight: shared.homeHighlight,
      quoteMark: shared.quoteMark,
      eventGuage: {
        hiring: "#00c497",
        firing: "#FF3E3E",
      },
      talentSourcing: {
        ...sourcingColors,
        selected: selligence.primary0,
        formButtons: selligence.secondary,
        available: selligence.tertiary,
        infoBackground: "#fff",
      },
      contentful: {
        subheading: selligence.tertiary,
      },
      indicatorHighlight: selligence.tertiary,
      sequencingTint: selligence.tertiary,
      scheduleChip: selligence.secondary,
      powerbarChip: selligence.primary3,
      events: {
        scoreHighlight: selligence.secondary,
        scoreHighlightAlt: selligence.secondary,
        pipelineButton: selligence.primary,
      },
    },
    ...sharedTheme,
    isSelligence: true,
    key: "selligence",
  },
  talentticker: {
    meta: {
      title: "Talent Ticker - Predictive Market Intelligence",
      description:
        "Talent Ticker provides AI-driven recruitment intelligence to bring your recruitment business into the data age.",
      productName: "Talent Ticker",
    },
    content: {
      overlayMessage:
        "Talent Tickerʼs unique offering predicts the hiring movements of an organisation. By searching through the worldʼs news, jobs boards and social media platforms to uncover the key information, news stories and events.",
      overlayHeading: "Use unique insight to drive new business",
      projectedBanner:
        "View the roles projected to be hired based on this event",
      firingChipName: "Firing",
      hiringChipName: "Hiring",
      trendsTabName: "Hiring/Firing",
      rolesTabName: "Roles",
      searchPlaceholder: "role",
      sourcingIndividuals: "candidates",
      vacanciesName: "Vacancies",
      vacancyName: "Vacancy",
    },
    colors: {
      ...talentticker,
      lightGrey: "#ededed",
      slateGrey: "#5f6379",
      midGrey: shared.midGrey,
      success: "#46efa1",
      primaryActive: "#db1900",
      primaryHover: "#ff8467",
      secondaryHover: "#5f6171",
      fontPrimary: shared.fontPrimary,
      fontSecondary: shared.fontSecondary,
      fontDark: shared.fontDark,
      fontLight: shared.fontLight,
      activityBadge: shared.activityBadge,
      pipelineButtonPrimary: talentticker.primary,
      pipelineButtonSecondary: "#4ECDAA",
      highlight: talentticker.primary,
      highlightHover: "var(--highlight-hover)",
      highlightActive: "var(--highlight-active)",
      highlightAlt: "var(--highlight-alt)",
      cloudyBlue: "#c9d2da",
      cloudyGrey: "#a7a8ae",
      background: {
        default: "#f9f9f9",
        light: "#f5f7f8",
      },
      outbox: {
        tabBackground: "#ffe7e5",
        tabText: talentticker.primary,
        stepActive: talentticker.primary,
      },
      sidebarHover: shared.white,
      sidebarHoverBackground: talentticker.primary,
      sequencingColors,
      resultControls: {
        background: "var(--bg-dark)",
        foreground: "var(--bg-dark-alt)",
      },
      header: {
        primary: talentticker.primary,
        secondary: "#3e4150",
      },
      table: { ...tableColors, exluded: talentticker.primary },
      sideNav: {
        background: {
          selected: talentticker.primary,
          default: "transparent",
          mobile: talentticker.primary,
        },
        text: {
          disabled: shared.fontSecondary,
          selected: shared.white,
          selectedHover: shared.fontPrimary,
          default: shared.fontPrimary,
          mobileSHover: shared.fontPrimary,
          mobileDefaultHoover: talentticker.primary,
        },
      },
      modal: {
        primaryBackground: "#ffeae8",
        confirmButton: talentticker.primary,
      },
      chip: {
        secondary: "#354658",
        icon: talentticker.primary,
      },
      tooltip: {
        warningIcon: talentticker.primary,
      },
      popup: {
        icon: talentticker.primary,
      },
      contact: {
        icon: talentticker.primary,
        iconDisabled: "#e6e6e6",
      },
      addToPipeline: { primary: talentticker.primary, secondary: "#46EFA1" },
      contactsPowerbarChip: " #364658",
      homeHighlight: shared.homeHighlight,
      quoteMark: shared.quoteMark,
      eventGuage: {
        firing: "#ff4900",
        hiring: "#46efa1",
      },
      talentSourcing: {
        ...sourcingColors,
        formButtons: talentticker.primary,
      },
      contentful: {
        subheading: talentticker.primary,
      },
      indicatorHighlight: talentticker.primary,
      sequencingTint: talentticker.primary,
      scheduleChip: talentticker.primary,
      powerbarChip: talentticker.primary,
      events: {
        scoreHighlight: talentticker.primary,
        scoreHighlightAlt: "var(--highlight-alt)",
        pipelineButton: "#4ECDAA",
      },
    },
    ...sharedTheme,
    isTalentTicker: true,
    key: "talentticker",
  },
};

export const THEME = currentTheme as keyof typeof theme; // nice

export type Theme = typeof theme["selligence"] & MaterialTheme;

export const useTheme = (): Theme => {
  const themeKey = currentTheme;
  return theme[themeKey];
};

export const useDarkTheme = (): Theme => {
  const theme = useTheme();
  const dark = useMemo(
    () => ({
      ...theme,
      ...globalDark(theme),
    }),
    [theme]
  );
  //@ts-ignore -- MuiTheme and Theme no play nice
  return dark;
};

export const vacanciesName = theme[THEME].content.vacanciesName;
export const vacancyName = theme[THEME].content.vacancyName;

export * from "./ThemeProvider";
