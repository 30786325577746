export const ActiveFlags = [
  "dynamicVacancyTesting",
  "personalisedMessaging",
  "contactsToVincere",
  "contactInformationFilter",
  "sequencing",
  "messageSchedule",
  "maiUnrevealedContacts",
  "message_threading",
  "contactInformationFilter",
  "personalEmailsOnProjects",
  "ungroupedCompaniesInSuggestion",
  "displayVacancyJobDescription",
  "internalSalesforce",
  "selligenceBrandingForAnaxym",
  "mailFailureReasons",
] as const;

export type ActiveFlag = typeof ActiveFlags[number];
