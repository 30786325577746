import { v4 as uuid } from "uuid";
import {
  BOOLEAN_OPERATOR_REGEX,
  BOOLEAN_KEYWORDS,
  LETTERS_AND_NUMBERS,
  BOOLEAN_SYMBOL,
  BOOLEAN_SEARCH,
} from "../regular-expressions";
import { fetchPeopleSuggestions } from "./SuggestSearch/fetchers";

type OperatorType = "+" | "-" | "AND" | "OR" | "NOT";

/**
 * Get input and search the best results
 * @param value boolean search input
 * @param type chipType
 * @returns Promise<Chip[]>
 */
export const fetchBooleanSearchResults = async (
  value: string,
  type: ChipType
): Promise<Chip[]> => {
  const newChips = setter(value);
  const generatedChips = newChips([]);
  return Promise.all(
    generatedChips.map<Promise<Chip | undefined>>(async (chip) => {
      const { data } = await fetchPeopleSuggestions(chip.name, type);
      const res = data?.[type]?.[0]
        ? {
            ...data[type][0],
            boolean_operator: chip?.boolean_operator,
          }
        : undefined;
      return res;
    })
  ).then((c) => c.filter((chip) => typeof chip !== "undefined"));
};

/**
 * Match input with different types of boolean operators
 * @param value User input value
 * @returns Chip[]
 */
export const setter =
  (value: string) =>
  (ps: Chip[]): Chip[] => {
    if (value.match(BOOLEAN_KEYWORDS)) {
      // If input matches any of AND, OR, NOT
      const makeupedChips = formatInput(value);
      return [...ps, ...makeupedChips];
    } else if (value.match(BOOLEAN_SYMBOL)) {
      // Input matches any of +, -, ""
      // and remove empty or whitespace strings from array
      const matches = value.match(BOOLEAN_SEARCH).filter((n) => n.trim() != "");
      let newChips: string[] = [];
      if (
        matches.length === 1 &&
        matches[0].match(BOOLEAN_SYMBOL)?.length > 1
      ) {
        newChips = conductWithMuliple(matches);
      }
      return newChips.length > 0
        ? ([...ps, ...newChips.map(mapper)] as Chip[])
        : ([...ps, ...matches.map(mapper)] as Chip[]);
    } else {
      // If input doesn't meet boolean search rules, take it as normal input
      return [...ps, mapper(value)] as Chip[];
    }
  };

/**
 * Conduct input with "+", "-" and ""
 * @param input Filtered user input
 * @returns Chip
 */
export function mapper(input: string): Chip {
  const booleanOperator = setChipStatus(input);
  // Remove boolean research symbols
  const name = input.replace(BOOLEAN_OPERATOR_REGEX, "").replace(/["']/g, "");
  const chip = createChip(name, booleanOperator);
  return chip;
}

/**
 * Conduct with mutiple + or -
 * @param input user input which has at least two + or -
 * @returns formatted Chip array
 */
export function conductWithMuliple(input: RegExpMatchArray): string[] {
  const newChips = [];
  const allMacthes = input[0].split('"');
  allMacthes.forEach((item: OperatorType, i: number) => {
    if (item.trim() === "+" || item.trim() === "-") {
      newChips.push(item + allMacthes[i + 1]);
    }
  });
  return newChips;
}

/**
 * Conduct input with AND, NOT, and OR
 * @param input User input value
 * @returns Chip[]
 */
export const formatInput = (input: string): Chip[] => {
  const inputArray = input.split(BOOLEAN_KEYWORDS).map((ele) => ele.trim());
  const newChips: Chip[] = [];
  inputArray.forEach((ele: OperatorType, i: number) => {
    switch (ele) {
      case "AND":
        // If start with AND
        if (!inputArray[i - 1]) {
          // If front chip has exists or user's input with NOT directly
          const chip = createChip(inputArray[i + 1], "and");
          newChips.unshift(chip);
        } else {
          [inputArray[i + 1], inputArray[i - 1]].map((ele) =>
            newChips.unshift(createChip(ele, "and"))
          );
        }
        break;
      case "NOT": {
        const hasFrontChip = newChips.some(
          (ele) => ele.name === inputArray[i - 1]
        );
        if (hasFrontChip || !inputArray[i - 1]) {
          // If front chip has exists or user's input with NOT directly
          const chip = createChip(inputArray[i + 1], "not");
          newChips.unshift(chip);
        } else {
          [inputArray[i + 1], inputArray[i - 1]].map((ele) => {
            const status: BooleanOperator =
              // NOT only applys to the latter one
              ele === inputArray[i - 1] ? "or" : "not";
            newChips.unshift(createChip(ele, status));
          });
        }
        break;
      }
      case "OR": {
        // Check if the front chip has been added already
        const hasFrontChip = newChips.some(
          (ele) => ele.name === inputArray[i - 1]
        );
        if (hasFrontChip || !inputArray[i - 1]) {
          const chip = createChip(inputArray[i + 1], "or");
          newChips.unshift(chip);
        } else {
          [inputArray[i + 1], inputArray[i - 1]].map((ele) =>
            newChips.unshift(createChip(ele, "or"))
          );
        }
      }
    }
  });
  return newChips;
};

export const createChip = (
  name: string,
  booleanOperator?: BooleanOperator,
  type?: ChipType,
  freetext?: boolean
): Chip => {
  const match = name.trim().match(LETTERS_AND_NUMBERS);
  return {
    es_id: uuid(),
    name: freetext ? name : match?.[0],
    type: type ?? "freetext",
    id: uuid(),
    boolean_operator: booleanOperator || "or",
    freetext: freetext,
  };
};

export const setChipStatus = (input: string): BooleanOperator => {
  const booleanOperator = input
    .match(BOOLEAN_OPERATOR_REGEX)[0]
    .replace("+", "and")
    .replace("-", "not")
    .replace(/"/g, "and") // quotation marks
    .trim() as BooleanOperator;
  return booleanOperator;
};

export const changeChipStatus = (status: BooleanOperator): BooleanOperator => {
  switch (status) {
    case "and":
      return "not";
    case "or":
      return "and";
    case "not":
      return "or";
  }
};

/**
 * Find value via object key
 * @param obj
 * @param value
 * @returns the first value found
 */
export const findKey = <T extends string | number>(
  obj: Record<string, T>,
  value: T
): string => {
  const key = Object.entries(obj)
    .filter((e) => e[1] === value)
    .map((e) => e[0])[0];
  return key;
};

/**
 * Number of selected specialist filter
 */
export const getSelectedNumber = (
  gender,
  locationId,
  likelyToChange
): number => {
  /**
   * Number of selected filters
   */
  const totalSelected = [gender, locationId, likelyToChange].filter(
    Boolean
  ).length;
  return totalSelected;
};

/**
 * @pure
 */
export const getOptionLabel = (option: Chip): string => {
  if (!option) {
    return "";
  }
  let symbol = "";
  switch (option.boolean_operator) {
    case "and":
      symbol = "+";
      break;

    case "not":
      symbol = "-";
      break;
  }
  if (option.freetext) {
    return `Free text search: "${option.name}"`;
  }
  return `${symbol}${option.name}`;
};
