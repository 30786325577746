import fetch from "isomorphic-fetch";
import { getAccessToken } from "lib/auth";

import { getOrCreateStore } from "../lib/with-redux-store";
import { PermissionError } from "./errors";
import * as Sentry from "@sentry/browser";
import { ServerErrorWithMessage } from "config";

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.json
    ? response
        .json()
        .then((data) => {
          return new Promise((resolve) => {
            return resolve(data);
          });
        })
        .catch((error) => {
          return new Promise((_resolve, reject) => {
            return reject(error);
          });
        })
    : Promise.resolve();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
  if (Math.floor(response.status / 100) === 2) {
    return Promise.resolve(response);
  } else if (response.status === 401) {
    // eslint-disable-next-line no-console
    console.warn("Auth Token Invalid");
    const store = getOrCreateStore();
    store.dispatch(
      window.webAuth.logout({
        returnTo: `${window.location.protocol}//${window.location.host}/logout`,
      })
    );
    return Promise.resolve(response);
  }

  const json = await response.json();
  let message;
  if (response.status === 422) {
    message = json.message;
  } else {
    message = JSON.stringify(json);
  }
  const error = new Error(message);
  (<any>error).response = response;
  throw error;
}

function toQueryString(obj) {
  return Object.keys(obj)
    .map((k) => {
      return encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]);
    })
    .join("&");
}

type RequestProps = {
  url: string;
  options: Pick<RequestInit, "body" | "method" | "headers">;
  auth?: boolean;
  contentType?: boolean;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {object} config    The config for the request
 * @param  {object} [query]   The query paramas for the request
 *
 * @return {object}           The response data
 */
export default async function request(
  { url, options, auth = false, contentType = true }: RequestProps,
  query: Record<string, unknown> = undefined,
  optOutFromErrorHandlerHttpCodes: number[] = []
) {
  if (query) {
    url += "?" + toQueryString(query);
  }
  options.headers = options.headers || {};

  Sentry.setContext("request.config", {
    url,
    options,
    auth,
    contentType,
    query,
  });

  const accessToken = getAccessToken();
  Sentry.setExtra("accessTokenRetrieved", Boolean(accessToken));

  try {
    /* Auth token */
    if (auth && !!accessToken) {
      options.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    if (contentType) {
      options.headers["Content-Type"] = "application/json";
    }
    const response = await fetch(url, options);
    Sentry.setContext("request.response.metadata", {
      data: {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
      },
    });

    if (optOutFromErrorHandlerHttpCodes.includes(response.status)) {
      /* Opt out for default error handling status for specific http codes
       * Allow the consumer to handle certain statuses
       * Used to show microsoft error messages returned from outlook_send
       */
      return response;
    } else {
      const checkedResponse = await checkStatus(response);
      return Promise.resolve(
        checkedResponse.status === 204
          ? checkedResponse.statusText
          : parseJSON(checkedResponse)
      );
    }
  } catch (err) {
    if (err?.name === "AbortError") {
      /* Intentionally left blank
       * Api code will handle returning results upon cancellations
       */
    } else if (err?.response?.status === 404) {
      throw new Error("404");
    } else if (err?.response?.status === 422) {
      /* Api communicated error messages */
      throw new ServerErrorWithMessage(err.message);
    } else {
      if (err?.response?.status === 403) {
        throw new PermissionError();
      }

      if (err?.response?.status === 402) {
        //handle missing credits.. this will need to propagate up at some stage
        throw new Error("You have reached your credit limit.");
      }

      if (err?.response?.status === 500) {
        throw new Error("Something went wrong please try again.");
      }

      //this is also a catch all for all errors.. again we need to be using the response code correctly to propagate messages through from the API
      throw new Error(
        `Failed fetching "${url}" : ${JSON.stringify(options)} : ${err}`
      );
    }
  }
}
