export const ACTION = {
  CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS",
  GET_RECENT_SEARCH: "GET_RECENT_SEARCH",
  SET_OUTSIDE_SEARCH: "SET_OUTSIDE_SEARCH",
  SET_EVENTS_PAGE: "SET_EVENTS_PAGE",
  SET_SEARCH_TERMS: "SET_SEARCH_TERMS",
  UPDATE_PIPELINE_STATUS: "UPDATE_PIPELINE_STATUS",
};

export const EVENTS = {
  LIMIT: 20,
};

export const SEARCHES = {
  RECENT_STORED: 10,
};
