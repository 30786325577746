import { WebAuth } from "auth0-js";
import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_RESPONSE_TYPE,
  AUTH0_SCOPE,
  AUTH0_AUDIENCE,
} from "./env";

type Auth0Options = {
  domain: string;
  clientID: string;
  responseType: string;
  scope: string;
  audience?: string;
};
/**
 *  This needs to remain for legacy auth logic.. We can slowly migrate these practices to new ones
 * @returns void
 */
export function initializeAuth0(): void {
  if (window.webAuth) {
    return;
  }
  const options: Auth0Options = {
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    responseType: AUTH0_RESPONSE_TYPE,
    scope: AUTH0_SCOPE,
  };
  if (AUTH0_AUDIENCE.trim()) {
    options.audience = AUTH0_AUDIENCE;
  }
  window.webAuth = new WebAuth(options);
}
