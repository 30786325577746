export const ACTION = {
  GET_PIPELINE: "GET_PIPELINE",
  GET_PIPELINE_SUCCESS: "GET_PIPELINE_SUCCESS",
  GET_PIPELINE_FAILED: "GET_PIPELINE_FAILED",
  ADD_TASK: "ADD_TASK",
  ADD_TASK_SUCCESS: "ADD_TASK_SUCCESS",
  ADD_TASK_FAILED: "ADD_TASK_FAILED",
  UPDATE_TASK: "UPDATE_TASK",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
  UPDATE_TASK_FAILED: "UPDATE_TASK_FAILED",
  DELETE_TASK: "DELETE_TASK",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILED: "DELETE_TASK_FAILED",
  GET_PIPELINE_RECORD: "GET_PIPELINE_RECORD",
  GET_PIPELINE_RECORD_SUCCESS: "GET_PIPELINE_RECORD_SUCCESS",
  GET_PIPELINE_RECORD_FAILED: "GET_PIPELINE_RECORD_FAILED",
  ADD_NOTE: "ADD_NOTE",
  ADD_NOTE_SUCCESS: "ADD_NOTE_SUCCESS",
  ADD_NOTE_FAILED: "ADD_NOTE_FAILED",
};

export const TITLES = {
  PROSPECT: "Prospect",
  CONTACTED: "Contacted",
  ACTIVE: "Active",
  SUCCESS: "Success",
};

export const JS_TITLES = {
  PROSPECT: "Interested",
  CONTACTED: "Contacted",
  ACTIVE: "Interviews",
  SUCCESS: "Offers",
};
