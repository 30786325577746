import { get, x64hash128 } from "fingerprintjs2";
import { getAccessToken } from "lib/auth";
import { WS } from "../env";

let connected = false;
let connecting = false;
let queuedEvents = [];
let socket;
let fingerprint;

export function getFingerprintHash(components, hashFn) {
  const hash = hashFn(
    components.map((component) => component.value).join(""),
    31
  );
  return hash;
}

get((components) => (fingerprint = getFingerprintHash(components, x64hash128)));

export const websocketMiddleware = (store) => (next) => (action) => {
  if (!(window && typeof window.WebSocket !== "undefined")) {
    return next(action);
  }
  if (!socket || (!connected && !connecting)) {
    const state = store.getState();
    const token = getAccessToken(state);

    if (!token) {
      return next(action);
    }

    connected = false;
    connecting = true;
    socket = new window.WebSocket(`${WS}?jwt=${token}`);
    socket.addEventListener("open", () => {
      connected = true;
      connecting = false;
      while (queuedEvents.length > 0) {
        socket.send(JSON.stringify(queuedEvents.shift()));
      }
    });
    socket.addEventListener("close", () => {
      if (socket) {
        socket = null;
        connected = false;
        connecting = false;
      }
    });
  }
  const websocketEvent = mapReduxActionToWebsocketEvent(action);
  if (websocketEvent) {
    if (connected) {
      console.log(websocketEvent);
      socket.send(JSON.stringify(websocketEvent));
    } else {
      queuedEvents.push(websocketEvent);
    }
  }
  return next(action);
};

export const mapReduxActionToWebsocketEvent = (reduxAction) => {
  switch (reduxAction.type) {
    case "SESSION_START":
      // eslint-disable-next-line no-case-declarations
      const userAgent = navigator ? navigator.userAgent : "unknown";
      return {
        action: "record-event",
        type: "session-start",
        payload: {
          user_agent: userAgent,
          fingerprint: fingerprint || null,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "UPDATE_EVENT":
      return {
        action: "record-event",
        type: "event-view",
        payload: {
          ...reduxAction.data,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "FETCH_SEARCH_RESULTS_REQUEST": {
      const filters = {};
      if (reduxAction && reduxAction.payload && reduxAction.payload.filters) {
        if (reduxAction.payload.filters.eventTypes) {
          filters.event_types = reduxAction.payload.filters.eventTypes;
        }
        if (reduxAction.payload.filters.companySizes) {
          filters.company_sizes = reduxAction.payload.filters.companySizes;
        }
        if (reduxAction.payload.filters.contractTypes) {
          filters.contract_types = reduxAction.payload.filters.contractTypes;
        }
        if (reduxAction.payload.filters.eventTypes) {
          filters.event_types = reduxAction.payload.filters.eventTypes;
        }
        if (reduxAction.payload.filters.trend) {
          filters.trend = reduxAction.payload.filters.trend;
        }
        if (reduxAction.payload.filters.range) {
          filters.range = reduxAction.payload.filters.range;
        }
      }

      return {
        action: "record-event",
        type: "search-perform",
        payload: {
          ...reduxAction.payload,
          filters,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };
    }

    case "SAVE_SEARCH_SUCCESS":
      return {
        action: "record-event",
        type: "search-save",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "ADD_TASK":
      return {
        action: "record-event",
        type: "pipeline-lead-add",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "UPDATE_TASK":
      return {
        action: "record-event",
        type: "pipeline-status-update",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "ROLES_FEEDBACK":
      return {
        action: "record-event",
        type: "roles-feedback",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "ENABLE_BROWSER_NOTIFICATIONS":
      return {
        action: "record-event",
        type: "browser-notification-enable",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "EXPORT_TO_CSV":
      return {
        action: "record-event",
        type: "export_to_csv",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "ADD_BH_EVENT_REQUEST":
      return {
        action: "record-event",
        type: "add_bullhorn_event",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "ADD_VINCERE_EVENT_REQUEST":
      return {
        action: "record-event",
        type: "add_vincere_event",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "VIEW_CONTACT_CARD_DETAILS":
      return {
        action: "record-event",
        type: "view_contact_card_details",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "COPY_CONTACT_DETAILS_TO_CLIPBOARD":
      return {
        action: "record-event",
        type: "copy_contact_card_details_to_clipboard",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "MESSAGES_SENT":
      return {
        action: "record-event",
        type: "messages_sent",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "VIEW_COMPANY_PROFILE":
      return {
        action: "record-event",
        type: "view_company_profile",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "REQUEST_CONTACT_DETAILS":
      return {
        action: "record-event",
        type: "request_contact_details",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "REQUEST_CONTACT_DETAILS_COMPLETED":
      return {
        action: "record-event",
        type: "request_contact_details_completed",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "TALENT_SOURCING_SEARCH_REQUEST":
      return {
        action: "record-event",
        type: "analytics_talent_sourcing",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "REQUEST_REPORT_AND_HIDE_NEWS_EVENT":
      return {
        action: "record-event",
        type: "report_and_hide_news_event",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    case "REQUEST_REPORT_ACCURACY_FOR_NEWS_EVENT":
      return {
        action: "record-event",
        type: "accuracy_statistics",
        payload: {
          ...reduxAction.payload,
          source: process.env.NEXT_PUBLIC_PRODUCT_NAME,
        },
      };

    default:
      return null;
  }
};
