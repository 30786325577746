import { checkFlags, defaultFlags, useConfigCatClient } from "lib/configcat";
import { ActiveFlag } from "lib/configcat/flags";
import { useEnv } from "lib/env";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getLoginEmail, getUserId, isLoggedIn } from "store/auth/selectors";
import { PERMISSIONS } from "constants/index";
import { useAuth0 } from "@auth0/auth0-react";
import JwtDecode from "jwt-decode";
import { useUser } from "lib/auth/hook";
import * as Sentry from "@sentry/browser";
import { setCookie } from "nookies";

export type PermissionKey = keyof typeof PERMISSIONS;
export type PermissionMap = Record<PermissionKey, true>;
type FeatureKey =
  | ActiveFlag
  | PermissionKey
  | "isLoggedIn"
  | "hasTicker"
  | "hasMarketMapping"
  | "hasOrganisationPicker"
  | "hasArticleScore"
  | "isJobSeeker"
  | "isTalentTicker"
  | "hasBullhorn"
  | "hasVincere"
  | "hasTalentSourcing"
  | "canSearch"
  | "canSearchMoreResults"
  | "hasPersonalEmails"
  | "isSelligenceTheme";

export type FeatureMap = Record<FeatureKey, boolean> & {
  permissions: PermissionKey[];
};

/**
 * Setup a default object of entirely false values
 */
const defaultValues: FeatureMap = Object.keys(PERMISSIONS).reduce(
  (values, key) => ({ ...values, [key]: false }),
  {
    ...defaultFlags,
    isLoggedIn: false,
  } as FeatureMap
);

export const FeatureContext = createContext<FeatureMap>(defaultValues);

type UsePermissions = {
  permissionsObj: Record<PermissionKey, boolean>;
  permissions: PermissionKey[];
  loading: boolean;
  loggedIn: boolean;
};

/**
 *  @name usePermissions
 *  This is a convenience hook to expose the permissions directly.
 *  Its useful for when features need to be exposed for a particular permission set etc
 * @returns permissionsObj { 'read:talentsourcing': true, ... }
 * @returns permissions ["read:talentsourcing", "edit:teams"...]
 */
export const usePermissions = (): UsePermissions => {
  const [permissions, setPermissions] = useState<PermissionKey[]>([]);
  const [loading, setLoading] = useState<boolean | null>(null);

  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();

  const permissionsObj = useMemo(
    () => decodePermissionKeys(permissions),
    [permissions]
  );

  useEffect(() => {
    async function initializePermissions() {
      try {
        // check for localStorage perms string
        setLoading(true);
        const localPermissions = getPermissionsFromLocalStorage();
        if (localPermissions) {
          setPermissions(localPermissions);
          // we're done so return early
          return;
        }

        if (user) {
          // No Local perms so lets fetch
          const token = await getAccessTokenSilently();
          const decoded = getPermissionsFromToken(token);
          savePermissionsToLocalStorage(decoded);
          setPermissions(decoded);
        }
      } catch (e) {
        // gracefully handle any unforseen errors
        Sentry.captureException(e);
      }
    }
    initializePermissions().finally(() => setLoading(false));
  }, [getAccessTokenSilently, user, setPermissions]);

  return { permissions, permissionsObj, loading, loggedIn: isAuthenticated };
};

/**
 * Checks and parses the localStorage string for permissions
 * @returns list of permission keys
 */
function getPermissionsFromLocalStorage(): PermissionKey[] | null {
  const locallyStoredPermissions = localStorage?.getItem("ttPerms");
  if (locallyStoredPermissions) {
    return JSON.parse(locallyStoredPermissions || "{}");
  }
  return null;
}

/**
 * Gets the permissions from an Auth0 token
 * @param token {string} -- Auth0 token
 * @returns list of permission keys
 */
function getPermissionsFromToken(token: string): PermissionKey[] {
  const decoded = JwtDecode<{ permissions: PermissionKey[] }>(token);
  return decoded.permissions;
}

/**
 * Stores the given permissions to localstorage for later retrieval
 * @param permissions {PermissionKey[]} -- list of permission keys
 */
function savePermissionsToLocalStorage(permissions: PermissionKey[]) {
  localStorage?.setItem("ttPerms", JSON.stringify(permissions)); // "{permissions: ["..."]}"
}

/**
 * Transforms a list of keys into a map of key: true values
 */
function decodePermissionKeys(keys: PermissionKey[]): PermissionMap {
  return keys.reduce(
    (acc, perm) => ({ ...acc, [perm]: true }),
    {} as PermissionMap
  );
}

/**
 * The source of truth for which features to display
 * Uses Environment Variables, ConfigCat flags and Auth0 User Permissions
 * to build one Record of string, boolean pairs
 */
export const useFeature = (): FeatureMap => useContext(FeatureContext);

/**
 * Context is used so the flag checks only happen once
 * - Not everytime the hook is used
 */
export const FeatureProvider = ({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element => {
  const env = useEnv();
  const THEME = env.THEME as string;

  const client = useConfigCatClient();
  const { permissionsObj: permissions } = useUser();
  // const isSelligence = env.PRODUCT_NAME === "Anaxym";
  const isTalentTicker = env.PRODUCT_NAME === "Talent Ticker";

  const [flags, setFlags] = useState(defaultFlags);

  const { user, loggedIn } = useSelector(selector);

  /**
   * This is kept local for now
   * but it could be passed to subscribers of the hook
   * if we need to refresh flags manually
   */
  const updateFlags = useCallback(
    async (client, user) => {
      if (user) {
        const checked = await checkFlags(client, user);
        // TODO: update client cookies

        setFlags((f) => ({ ...f, ...checked }));
      }
    },
    [setFlags]
  );

  useEffect(() => {
    updateFlags(client, user);
  }, [client, user, updateFlags]);

  const hasMarketMapping =
    permissions && permissions[PERMISSIONS.READ_MARKET_MAPPING];
  const hasOrganisationPicker =
    permissions && permissions[PERMISSIONS.EDIT_ANY_ORGANISATION];
  const hasTicker = !hasOrganisationPicker && isTalentTicker;
  const isJobSeeker =
    permissions && permissions[PERMISSIONS.READ_JOBSEEKER_PROFILE];
  const hasBullhorn = permissions && permissions[PERMISSIONS.ADD_TO_BULLHORN];
  const hasVincere = permissions && permissions[PERMISSIONS.ADD_TO_VINCERE];
  const canSearch = permissions && permissions[PERMISSIONS.READ_SEARCH];
  const canSearchMoreResults =
    permissions && permissions[PERMISSIONS.READ_SEARCH_MORE_RESULTS];
  const hasTalentSourcing =
    permissions && permissions[PERMISSIONS.READ_TALENT_SOURCING];
  const hasPersonalEmails =
    permissions && permissions[PERMISSIONS.PERSONAL_EMAILS];

  /**
   * Selligence theme is only visible when THEME is specifically selligence
   * OR when Anaxym with feature flag active
   */
  const isSelligenceTheme =
    THEME === "selligence" ||
    (THEME === "anaxym" && flags.selligenceBrandingForAnaxym);

  useEffect(() => {
    setCookie(null, "tt-theme", isSelligenceTheme ? "selligence" : THEME);
  }, [isSelligenceTheme, THEME]);

  const features: FeatureMap = {
    ...defaultValues,
    ...flags,
    isLoggedIn: loggedIn,
    hasTicker,
    hasOrganisationPicker,
    isTalentTicker,
    hasArticleScore: isTalentTicker,
    hasMarketMapping,
    isJobSeeker,
    hasBullhorn,
    hasVincere,
    hasTalentSourcing,
    canSearch,
    canSearchMoreResults,
    hasPersonalEmails,
    isSelligenceTheme,
  };

  return (
    <FeatureContext.Provider value={features}>
      {children}
    </FeatureContext.Provider>
  );
};

/**
 * Memoized redux selector
 * We can only know about permissions which are active
 * This means we can't fully type the `FeatureMap` with all of it's possible keys
 */
export const selector = createSelector(
  isLoggedIn,
  getLoginEmail,
  getUserId,
  (loggedIn, email, identifier) => {
    const domain = typeof email === "string" ? email.split("@")[1] : undefined;
    return {
      loggedIn,
      user: { email, identifier, custom: { domain } },
    };
  }
);
