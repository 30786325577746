import { ACTION } from "./constants";
import { getPicture } from "./selectors";

export const initialState = {
  authData: {
    AccessToken: "",
    RefreshToken: "",
    IdToken: "",
    user_id: "",
    new_user: false,
  },
  onboardingCompleted: false,
  preferences: {
    browser_notification_frequency: null,
    formattedInterests: {
      locations: [],
      roles: [],
      industries: [],
    },
    interests: null,
  },
  notifications: {
    count: 0,
    loading: false,
    searches: [],
  },
  jobseekerDetails: { formattedChips: {} },
  errors: {
    userExists: false,
    passwordInvalid: false,
    unidentified: null,
    notifications: null,
    freeTrial: null,
  },
  error_message: null,
  prefer_login: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.UPDATE_USER:
      return {
        ...state,
        authData: { ...action.data },
        error_message: initialState.error_message,
        prefer_login: true,
      };
    case ACTION.UPDATE_USER_METADATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.data,
          picture: action.data.picture || getPicture({ auth: state }),
        },
        metadataLoaded: true,
      };
    case ACTION.LOGIN_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = "";
      action.data.code !== 200 && (message = "Invalid email or password");
      return {
        ...state,
        error_message: message,
        errors: { password: message },
      };
    case ACTION.CLEAN_USER:
      return {
        ...state,
        authData: { ...initialState.authData },
        preferences: { ...initialState.preferences },
      };
    case ACTION.LOGIN_WITH_SESSION_SUCCESS:
      return {
        ...state,
        authData: { ...action.data },
        error_message: initialState.error_message,
      };
    case ACTION.SIGNUP_REQUEST:
      return {
        ...state,
        authData: { ...action.payload },
        errors: { ...initialState.errors },
      };
    case ACTION.SIGNUP_SUCCESS:
      return {
        ...state,
        authData: { ...action.payload },
      };
    case ACTION.SIGNUP_FAILED:
      return action.payload === "InvalidPasswordException"
        ? {
            ...state,
            errors: { password: "Password was not in a valid format" },
          }
        : {
            ...state,
            errors: {
              userExists: action.payload === "UsernameExistsException",
            },
          };
    case ACTION.UPDATE_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.data,
        },
      };
    case ACTION.UPDATE_ONBOARDING_STATUS:
      return {
        ...state,
        onboardingCompleted: action.data,
      };
    case ACTION.RAISE_ERROR:
      return {
        ...state,
        errors: { ...state.errors, error: action.error },
      };
    case ACTION.UPDATE_JS_CHIPS:
      return {
        ...state,
        jobseekerDetails: {
          ...state.jobseekerDetails,
          formattedChips: {
            ...state.jobseekerDetails.formattedChips,
            ...action.data,
          },
        },
      };
    case ACTION.GET_FREE_TRIAL_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          freeTrial: null,
        },
      };
    case ACTION.GET_FREE_TRIAL_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          freeTrial: action.error,
        },
      };
    case ACTION.GET_JS_DETAILS:
      return {
        ...state,
        jobseekerDetails: {
          ...state.jobseekerDetails,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
