import { ofType } from "redux-observable";
import { ACTION, SEARCHES } from "./constants";
import { getAccessToken } from "lib/auth";
import { requestUrl } from "utils/api-request";
import { map, mergeMap, takeUntil, catchError } from "rxjs/operators";
import { of } from "rxjs";
// import { getCompanies } from "store/ticker/selectors";
import {
  fetchCarouselSuccess,
  fetchLatestVacanciesSuccess,
  fetchBreakingNewsSuccess,
  fetchLatestNewsSuccess,
  fetchHiringTrendsSuccess,
  fetchBlogPostsSuccess,
  fetchSavedSearchSuccess,
} from "./actions";

const getCustomOptions = (userId, searchOptions) => {
  if (userId && userId !== "guest") {
    return {
      ...searchOptions,
      landingpage: true,
      user_id: userId,
    };
  }

  return searchOptions;
};

export const getCarouselEpic = (action$, state$, { post }) => {
  return action$.pipe(
    ofType(ACTION.GET_CAROUSEL),
    mergeMap((action) => {
      const token = getAccessToken();
      let searchOptions = { ...SEARCHES.latestLeads };
      searchOptions = getCustomOptions(action.payload.userId, searchOptions);

      return post(
        requestUrl({
          service: "v5-powersearch",
          action: "search",
        }),
        JSON.stringify(searchOptions),
        token && { Authorization: "Bearer " + token }
      ).pipe(
        map((json) => fetchCarouselSuccess(json.response.data)),
        takeUntil(action$.pipe(ofType(ACTION.CANCEL_GET_CAROUSEL))),
        catchError((error) =>
          of({
            type: ACTION.GET_CAROUSEL_ERROR,
            payload: error.xhr.response,
          })
        )
      );
    })
  );
};

export const getLatestVacanciesEpic = (action$, state$, { post }) =>
  action$.pipe(
    ofType(ACTION.GET_LATEST_VACANCIES),
    mergeMap((action) => {
      const token = getAccessToken();
      let searchOptions = { ...SEARCHES.latestVacancies };
      searchOptions = getCustomOptions(action.payload.userId, searchOptions);
      return post(
        requestUrl({
          service: "v5-powersearch",
          action: "search",
        }),
        JSON.stringify(searchOptions),
        token && { Authorization: "Bearer " + token }
      ).pipe(
        map((json) => fetchLatestVacanciesSuccess(json.response.data)),
        takeUntil(action$.pipe(ofType(ACTION.CANCEL_GET_VACANCIES))),
        catchError((error) =>
          of({
            type: ACTION.GET_VACANCIES_ERROR,
            payload: error.xhr.response,
          })
        )
      );
    })
  );

export const getBreakingNewsEpic = (action$, state$, { post }) =>
  action$.pipe(
    ofType(ACTION.GET_BREAKING_NEWS),
    mergeMap(() => {
      const token = getAccessToken();
      return post(
        requestUrl({
          service: "v5-powersearch",
          action: "search",
        }),
        JSON.stringify(SEARCHES.breakingNews),
        token && { Authorization: "Bearer " + token }
      ).pipe(
        map((json) => fetchBreakingNewsSuccess(json.response.data)),
        takeUntil(action$.pipe(ofType(ACTION.CANCEL_GET_BREAKING_NEWS))),
        catchError((error) =>
          of({
            type: ACTION.GET_BREAKING_NEWS_ERROR,
            payload: error.xhr.response,
          })
        )
      );
    })
  );

export const getLatestNewsEpic = (action$, state$, { post }) =>
  action$.pipe(
    ofType(ACTION.GET_LATEST_NEWS),
    mergeMap((action) => {
      const token = getAccessToken();
      let searchOptions = { ...SEARCHES.newestLeads };
      searchOptions = getCustomOptions(action.payload.userId, searchOptions);
      return post(
        requestUrl({
          service: "v5-powersearch",
          action: "search",
        }),
        JSON.stringify(searchOptions),
        token && { Authorization: "Bearer " + token }
      ).pipe(
        map((json) =>
          fetchLatestNewsSuccess({
            data: json.response.data,
            recommended: token,
          })
        ),
        takeUntil(action$.pipe(ofType(ACTION.CANCEL_GET_LATEST_NEWS))),
        catchError((error) =>
          of({
            type: ACTION.GET_LATEST_NEWS_ERROR,
            payload: error.xhr.response,
          })
        )
      );
    })
  );

export const getHiringTrendsEpic = (action$, state$, { post }) =>
  action$.pipe(
    ofType(ACTION.GET_HIRING_TRENDS),
    mergeMap(() => {
      return post(
        requestUrl({
          service: "v2-company",
          action: "top-hiring-trends",
          query: "window_size=4w",
        })
      ).pipe(
        map((json) => fetchHiringTrendsSuccess(json.response.data)),
        takeUntil(action$.pipe(ofType(ACTION.CANCEL_GET_TRENDS))),
        catchError((error) =>
          of({
            type: ACTION.GET_TRENDS_ERROR,
            payload: error.xhr.response,
          })
        )
      );
    })
  );

export const getBlogPostsEpic = (action$, state$, { getJSON }) =>
  action$.pipe(
    ofType(ACTION.GET_BLOG_POSTS),
    mergeMap(() =>
      getJSON(
        requestUrl({
          service: "v2-events",
          action: "marketing/posts",
          query: "limit=4",
        })
      ).pipe(
        map((json) => fetchBlogPostsSuccess(json.data)),
        takeUntil(action$.pipe(ofType(ACTION.CANCEL_GET_BLOG_POSTS))),
        catchError((error) =>
          of({
            type: ACTION.GET_BLOG_POSTS_ERROR,
            payload: error.xhr.response,
            error: true,
          })
        )
      )
    )
  );

export const getSavedSearchesEpic = (action$, state$, { post }) =>
  action$.pipe(
    ofType(ACTION.GET_SAVED_SEARCH),
    mergeMap((action) => {
      return post(
        requestUrl({
          service: "v5-powersearch",
          action: "search",
        }),
        JSON.stringify(action.payload.search),
        { Authorization: "Bearer " + getAccessToken() }
      ).pipe(
        map((json) =>
          fetchSavedSearchSuccess(json.response.data, action.payload.id)
        ),
        takeUntil(action$.pipe(ofType(ACTION.CANCEL_GET_SAVED_SEARCH))),
        catchError((error) =>
          of({
            type: ACTION.GET_SAVED_SEARCH_ERROR,
            payload: error.xhr.response,
          })
        )
      );
    })
  );
