import * as Sentry from "@sentry/nextjs";
import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_RELEASE } from "env";

Sentry.init({
  release: `webapp@${SENTRY_RELEASE}`,
  environment: SENTRY_ENVIRONMENT,
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  ignoreErrors: ["AbortError"],
  beforeSend(event, hint) {
    const error = hint.originalException;
    const errorMessage = error
      ? typeof error === "string"
        ? error
        : error.message
      : "";
    if (errorMessage && errorMessage.match(/chunk/i)) {
      return null;
    }
    return event;
  },
});
