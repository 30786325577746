import { ACTION } from "./constants";

export function fetchCarousel(userId) {
  return {
    type: ACTION.GET_CAROUSEL,
    payload: {
      userId,
    },
  };
}

export function fetchCarouselSuccess(payload) {
  return {
    type: ACTION.GET_CAROUSEL_SUCCESS,
    payload,
  };
}

export function cancelFetchCarousel(payload) {
  return {
    type: ACTION.CANCEL_GET_CAROUSEL,
    payload,
  };
}

export function fetchHiringTrends() {
  return {
    type: ACTION.GET_HIRING_TRENDS,
  };
}

export function fetchHiringTrendsSuccess(payload) {
  return {
    type: ACTION.GET_TRENDS_SUCCESS,
    payload,
  };
}

export function cancelHiringTrends() {
  return {
    type: ACTION.CANCEL_GET_TRENDS,
  };
}

export function fetchSavedSearch(search, id) {
  return {
    type: ACTION.GET_SAVED_SEARCH,
    payload: {
      search,
      id,
    },
  };
}

export function fetchSavedSearchSuccess(data, id) {
  return {
    type: ACTION.GET_SAVED_SEARCH_SUCCESS,
    payload: {
      data,
      id,
    },
  };
}

export function cancelFetchSavedSearch(payload) {
  return {
    type: ACTION.CANCEL_GET_SAVED_SEARCH,
    payload,
  };
}

export function fetchLatestVacancies(userId) {
  return {
    type: ACTION.GET_LATEST_VACANCIES,
    payload: {
      userId,
    },
  };
}

export function fetchLatestVacanciesSuccess(payload) {
  return {
    type: ACTION.GET_LATEST_VACANCIES_SUCCESS,
    payload,
  };
}

export function cancelFetchVacancies(payload) {
  return {
    type: ACTION.CANCEL_GET_VACANCIES,
    payload,
  };
}

export function fetchBreakingNews() {
  return {
    type: ACTION.GET_BREAKING_NEWS,
  };
}

export function fetchBreakingNewsSuccess(payload) {
  return {
    type: ACTION.GET_BREAKING_NEWS_SUCCESS,
    payload,
  };
}

export function cancelBreakingNews(payload) {
  return {
    type: ACTION.CANCEL_GET_BREAKING_NEWS,
    payload,
  };
}

export function fetchLatestNews(userId) {
  return {
    type: ACTION.GET_LATEST_NEWS,
    payload: {
      userId,
    },
  };
}

export function fetchLatestNewsSuccess(payload) {
  return {
    type: ACTION.GET_LATEST_NEWS_SUCCESS,
    payload,
  };
}

export function cancelLatestNews(payload) {
  return {
    type: ACTION.CANCEL_GET_LATEST_NEWS,
    payload,
  };
}

export function fetchBlogPosts() {
  return {
    type: ACTION.GET_BLOG_POSTS,
  };
}

export function fetchBlogPostsSuccess(payload) {
  return {
    type: ACTION.GET_BLOG_POSTS_SUCCESS,
    payload,
  };
}

export function cancelBlogPosts(payload) {
  return {
    type: ACTION.CANCEL_GET_BLOG_POSTS,
    payload,
  };
}
