import { ACTION } from "./constants";

export function acknowledgeCookies() {
  return { type: ACTION.ACKNOWLEDGE_COOKIES };
}

export function acknowledgeSaveSearchTooltip() {
  return { type: ACTION.ACKNOWLEDGE_SAVE_SEARCH_TOOLTIP };
}

export function addSnackbarMessage(payload) {
  return { type: ACTION.ADD_SNACKBAR_MESSAGE, payload };
}

export function removeSnackbarMessage(message) {
  return { type: ACTION.REMOVE_SNACKBAR_MESSAGE, message };
}

export function setDismissedCountdown() {
  return { type: ACTION.DISMISSED_COUNTDOWN };
}

export function clearDismissedCountdown() {
  return { type: ACTION.RESET_COUNTDOWN };
}
