export const ACTION = {
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_METADATA: "UPDATE_USER_METADATA",
  CLEAN_USER: "CLEAN_USER",
  LOGIN_ERROR: "LOGIN_ERROR",
  CLEAN_ERROR: "CLEAN_ERROR",
  LOGIN_WITH_SESSION_SUCCESS: "LOGIN_WITH_SESSION_SUCCESS",
  LOGIN_WITH_SESSION_FAILURE: "LOGIN_WITH_SESSION_FAILURE",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  RAISE_ERROR: "RAISE_ERROR",
  UPDATE_PREFERENCES: "UPDATE_PREFERENCES",
  UPDATE_ONBOARDING_STATUS: "UPDATE_ONBOARDING_STATUS",
  DELETE_ACCOUNT_REQUEST: "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_FAILED: "DELETE_ACCOUNT_FAILED",
  SESSION_START: "SESSION_START",
  GET_FREE_TRIAL_REQUEST: "GET_FREE_TRIAL_REQUEST",
  GET_FREE_TRIAL_SUCCESS: "GET_FREE_TRIAL_SUCCESS",
  GET_FREE_TRIAL_FAILED: "GET_FREE_TRIAL_FAILED",
  UPDATE_JS_DETAILS: "UPDATE_JS_DETAILS",
  UPDATE_JS_CHIPS: "UPDATE_JS_CHIPS",
  GET_JS_DETAILS: "GET_JS_DETAILS",
};
