import { PermissionKey } from "lib/features";
import { createContext } from "react";

export type UserContextType = {
  permissionsObj: Record<PermissionKey, boolean> | null;
  permissions: PermissionKey[];
};
const initialContext = {
  permissionsObj: null,
  permissions: [],
  loading: false,
};
export const UserContext = createContext<UserContextType>(initialContext);
