export const ACTION = {
  GET_BH_ACCOUNT_REQUEST: "GET_BH_ACCOUNT_REQUEST",
  GET_BH_ACCOUNT_SUCCESS: "GET_BH_ACCOUNT_SUCCESS",
  GET_BH_ACCOUNT_FAILURE: "GET_BH_ACCOUNT_FAILURE",
  SET_BH_ACCOUNT_REQUEST: "SET_BH_ACCOUNT_REQUEST",
  SET_BH_ACCOUNT_SUCCESS: "SET_BH_ACCOUNT_SUCCESS",
  SET_BH_ACCOUNT_FAILURE: "SET_BH_ACCOUNT_FAILURE",
  GET_VINCERE_ACCOUNT_REQUEST: "GET_VINCERE_ACCOUNT_REQUEST",
  GET_VINCERE_ACCOUNT_SUCCESS: "GET_VINCERE_ACCOUNT_SUCCESS",
  GET_VINCERE_ACCOUNT_FAILURE: "GET_VINCERE_ACCOUNT_FAILURE",
  SET_VINCERE_ACCOUNT_REQUEST: "SET_VINCERE_ACCOUNT_REQUEST",
  SET_VINCERE_ACCOUNT_SUCCESS: "SET_VINCERE_ACCOUNT_SUCCESS",
  SET_VINCERE_ACCOUNT_FAILURE: "SET_VINCERE_ACCOUNT_FAILURE",
  AUTH_BH_ACCOUNT_REQUEST: "AUTH_BH_ACCOUNT_REQUEST",
  AUTH_BH_ACCOUNT_SUCCESS: "AUTH_BH_ACCOUNT_SUCCESS",
  AUTH_BH_ACCOUNT_FAILURE: "AUTH_BH_ACCOUNT_FAILURE",
  AUTH_VINCERE_ACCOUNT_REQUEST: "AUTH_VINCERE_ACCOUNT_REQUEST",
  AUTH_VINCERE_ACCOUNT_SUCCESS: "AUTH_VINCERE_ACCOUNT_SUCCESS",
  AUTH_VINCERE_ACCOUNT_FAILURE: "AUTH_VINCERE_ACCOUNT_FAILURE",
  ADD_VINCERE_EVENT_REQUEST: "ADD_VINCERE_EVENT_REQUEST",
  ADD_VINCERE_EVENT_SUCCESS: "ADD_VINCERE_EVENT_SUCCESS",
  ADD_VINCERE_EVENT_FAILURE: "ADD_VINCERE_EVENT_FAILURE",
  ADD_VINCERE_CONTACT_REQUEST: "ADD_VINCERE_CONTACT_REQUEST",
  GET_VINCERE_COMPANIES_REQUEST: "GET_VINCERE_COMPANIES_REQUEST",
  GET_VINCERE_COMPANIES_SUCCESS: "GET_VINCERE_COMPANIES_SUCCESS",
  GET_VINCERE_COMPANIES_FAILURE: "GET_VINCERE_COMPANIES_FAILURE",
  SHOW_BULLHORN_MODAL: "SHOW_BULLHORN_MODAL",
  HIDE_BULLHORN_MODAL: "HIDE_BULLHORN_MODAL",
  SHOW_VINCERE_MODAL: "SHOW_VINCERE_MODAL",
  HIDE_VINCERE_MODAL: "HIDE_VINCERE_MODAL",
};
