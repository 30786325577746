import { createContext, useContext, ReactNode } from "react";
import { IConfigCatClient, User } from "configcat-common";
import { ActiveFlags, ActiveFlag } from "./flags";

const ConfigCatContext = createContext<IConfigCatClient>(null);

type ConfigCatProviderProps = {
  client: IConfigCatClient;
  children: ReactNode;
};

export const ConfigCatProvider = ({
  children,
  client,
}: ConfigCatProviderProps): JSX.Element => (
  <ConfigCatContext.Provider value={client}>
    {children}
  </ConfigCatContext.Provider>
);

export const useConfigCatClient = (): IConfigCatClient =>
  useContext(ConfigCatContext);

export type FlagMap = Record<ActiveFlag, boolean>;

const emptyFlagMap = {} as FlagMap;

export const defaultFlags: FlagMap = ActiveFlags.reduce(
  (acc, flag) => ({
    ...acc,
    [flag]: false,
  }),
  emptyFlagMap
);

export async function checkFlags(
  client: IConfigCatClient,
  user: User
): Promise<FlagMap> {
  const flags = await ActiveFlags.reduce(async (previous, flag) => {
    const acc = await previous;
    const value = await client.getValueAsync(flag, false, user);
    return {
      ...acc,
      [flag]: value,
    };
  }, Promise.resolve(emptyFlagMap));
  return flags;
}

export { ConfigCatProvider as default };
