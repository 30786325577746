import { Organisation } from "organisation-context";
import { ACTION } from "./constants";

export function fetchSeats(userId, organisation?: Organisation) {
  return { type: ACTION.FETCH_SEATS, userId, organisation };
}

export function fetchSeatsSuccess(payload) {
  return { type: ACTION.FETCH_SEATS_SUCCESS, payload };
}

export function fetchSeatsFailed(error) {
  return { type: ACTION.FETCH_SEATS_FAILED, error };
}

export function finishUpgrading() {
  return { type: ACTION.FINISH_UPGRADING };
}

export function vacateSeat(payload) {
  return { type: ACTION.VACATE_SEAT, payload };
}

export function cancelVacateSeat(payload) {
  return { type: ACTION.CANCEL_VACATE_SEAT, payload };
}

export function fetchPaymentSource(userId) {
  return { type: ACTION.FETCH_PAYMENT_SOURCE, userId };
}

export function fetchPaymentSourceSuccess(payload) {
  return { type: ACTION.FETCH_PAYMENT_SOURCE_SUCCESS, payload };
}

export function fetchPaymentSourceFailed(error) {
  return { type: ACTION.FETCH_PAYMENT_SOURCE_FAILED, error };
}

export function deleteUserFromSeat(userId) {
  return { type: ACTION.DELETE_USER_FROM_SEAT, userId };
}

export function deleteUserFromSeatFailed(error) {
  return { type: ACTION.DELETE_USER_FROM_SEAT_FAILED, error };
}

export function setOrgName(payload) {
  return { type: ACTION.SET_ORG_NAME, payload };
}

export function setOrgNameSuccess(payload) {
  return { type: ACTION.SET_ORG_NAME_SUCCESS, payload };
}

export function setOrgNameFailed(error) {
  return { type: ACTION.SET_ORG_NAME_FAILED, error };
}

export function cleanSeatsData() {
  return { type: ACTION.CLEAN_SEATS_DATA };
}
