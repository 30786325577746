import times from "lodash/times";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import { parseISO } from "date-fns";
import request from "utils/request";
import { requestConfig } from "config/apiResource";
import { requestUrl } from "utils/api-request";
import { getPowerSearchResults } from "api/powersearch";
import { requestV2 } from "api/request";

function getDate(index: number, dateObj: any): string {
  const startDate = dateObj.key || global.Date.now();

  return format(subDays(startDate, index * 7), "dd/MM");
}

export async function hiringTrendsFetcher(...args: string[]) {
  const response = await request(requestConfig.getCompanyHiringTrends(args[1]));
  const mapData = response.data.map_data;
  const formatted = response.data.histogram.map((trend) => {
    return {
      x: format(parseISO(trend.key_as_string), "dd/MM"),
      y: trend.doc_count,
    };
  });
  if (formatted && formatted.length === 4) {
    return formatted;
  }

  times(4 - formatted.length, (i) => {
    const trendObj = response.data.histogram.length
      ? response.data.histogram[0]
      : {};
    return formatted.unshift({
      x: getDate(i + 1, trendObj),
      y: 0,
    });
  });

  return { formatted, mapData };
}

export const buildCompanyChip = (
  companyId: number | undefined,
  displayName: string
): Chip | undefined => {
  if (!companyId) {
    return;
  }
  return {
    id: companyId,
    name: displayName,
    es_id: `com${companyId}`,
    type: "company",
  };
};

export async function eventsAndVacanciesFetcher(
  companyId: number,
  displayName: string,
  guest?: boolean
) {
  const companyChip = buildCompanyChip(companyId, displayName);
  const eventSearchObject: SearchCriteria = {
    userId: "",
    limit: 10,
    search: companyChip ? [companyChip] : [],
    tab: "news",
    queryDuplicateNames: false,
  };

  const jobSearchObject: SearchCriteria = {
    ...eventSearchObject,
    tab: "vacancies",
    queryDuplicateNames: false,
  };

  const eventResponse = await getPowerSearchResults(eventSearchObject, guest);
  const jobResponse = await getPowerSearchResults(jobSearchObject, guest);

  return { events: eventResponse.results, jobs: jobResponse.results };
}

export async function latestNewsFetcher(url: string, auth = true) {
  const latestNewsResponse = await requestV2<{ page: any }>({
    url,
    method: "GET",
    withAuth: auth,
  });
  return latestNewsResponse.data.page;
}

export async function deleteRawEventFetcher(rawEventUuid: string) {
  const response = await request({
    url: requestUrl({
      service: "v1-raw-events",
      action: `raw_event/${rawEventUuid}`,
      query: "",
    }),
    options: { method: "DELETE" },
    auth: true,
  });
  return response.data;
}
