//views
import AuthReducer from "./auth/reducer";
import NoticesReducer from "./notices/reducer";
import SearchesReducer from "./searches/reducer";
import SeatsReducer from "./seats/reducer";
import LandingReducer from "./landing/reducer";
import StatsReducer from "./stats/reducer";
import CompanyReducer from "./companies/reducer";
import PipelineReducer from "./pipeline/reducer";
import TickerReducer from "./ticker/reducer";
import TeamsReducer from "./teams/reducer";
import IntegrationsReducer from "./integrations/reducer";

export default {
  ticker: TickerReducer,
  auth: AuthReducer,
  notices: NoticesReducer,
  searches: SearchesReducer,
  landing: LandingReducer,
  seats: SeatsReducer,
  stats: StatsReducer,
  company: CompanyReducer,
  pipeline: PipelineReducer,
  teams: TeamsReducer,
  integrations: IntegrationsReducer,
  storageToken: (state = {}) => state,
};
