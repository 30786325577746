import { ACTION } from "./constants";
import { reduceCompanySpotlight } from "utils/event-helpers";

export const initialState = {
  info: {
    loading: true,
  },
  jobs: {
    loading: true,
  },
  events: {
    loading: true,
  },
  hiringTrends: {
    loading: true,
    histogram: [],
    mapData: [],
  },
  spotlight: {
    loading: true,
    company: null,
  },
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.GET_COMPANY_SL:
      return {
        ...state,
        spotlight: initialState.spotlight,
      };
    case ACTION.GET_COMPANY_SL_SUCCESS: {
      const events = action.payload.results.documents;
      const company =
        events && events.length ? reduceCompanySpotlight(events) : null;

      return {
        ...state,
        spotlight: {
          loading: false,
          company,
        },
      };
    }
    case ACTION.GET_COMPANY_FAILED:
      return {
        ...state,
        info: {
          loading: false,
          err: action.err,
        },
      };
    default:
      return { ...state };
  }
}
