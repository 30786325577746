import { setCookie } from "nookies";
import { ACTION } from "./constants";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

export function updatePreferences(prefs) {
  return { type: ACTION.UPDATE_PREFERENCES, data: prefs };
}

export function signupRequest(payload) {
  return {
    type: ACTION.SIGNUP_REQUEST,
    payload: payload.message ? payload.message : payload,
  };
}

export function signupSuccess(payload) {
  return {
    type: ACTION.SIGNUP_SUCCESS,
    payload,
  };
}

export function signupFailed(payload) {
  return {
    type: ACTION.SIGNUP_FAILED,
    payload,
  };
}

function timeToExpiry(token: string): number {
  const decodedToken = jwtDecode<{ exp: number }>(token);
  const expiry = decodedToken.exp;

  return expiry - Math.floor(Date.now() / 1000);
}

/**
 * Update user is an actionCreator but also has a side effect
 *
 * This sets a cookie for the ttUser so that they can automatically access
 * the correct version of the homepage
 *
 * TODO: Long term we should refactor auth so that we use Context instead
 * of redux. Storing the access token in a cookie is MORE secure than
 * local storage.
 */
export function updateUser(payload) {
  return { type: ACTION.UPDATE_USER, data: payload };
}

export function updateUserMetadata(payload) {
  return { type: ACTION.UPDATE_USER_METADATA, data: payload };
}

export function cleanUserContent() {
  return { type: ACTION.CLEAN_USER };
}

export function updateOnboardingStatus(completed) {
  return {
    type: ACTION.UPDATE_ONBOARDING_STATUS,
    data: completed,
  };
}

export function raiseError(error) {
  return {
    type: ACTION.RAISE_ERROR,
    error,
  };
}

export function deleteAccountRequest() {
  return {
    type: ACTION.DELETE_ACCOUNT_REQUEST,
  };
}

export function deleteAccountSuccess(payload) {
  return {
    type: ACTION.DELETE_ACCOUNT_SUCCESS,
    payload,
  };
}

export function deleteAccountFailed(error) {
  return {
    type: ACTION.DELETE_ACCOUNT_FAILED,
    error,
  };
}

export function startSession() {
  return {
    type: ACTION.SESSION_START,
  };
}

export function getFreeTrialRequest() {
  return {
    type: ACTION.GET_FREE_TRIAL_REQUEST,
  };
}

export function getFreeTrialSuccess(payload) {
  return {
    type: ACTION.GET_FREE_TRIAL_SUCCESS,
    payload,
  };
}

export function getFreeTrialFailed(error) {
  return {
    type: ACTION.GET_FREE_TRIAL_FAILED,
    error,
  };
}

export function updateJSDetails(payload) {
  return {
    type: ACTION.UPDATE_JS_DETAILS,
    payload,
  };
}

export function updateJSChips(data) {
  return {
    type: ACTION.UPDATE_JS_CHIPS,
    data,
  };
}

export function getJSDetails(payload) {
  return {
    type: ACTION.GET_JS_DETAILS,
    payload,
  };
}
